import React from 'react';
import { Header, Card, Image } from 'semantic-ui-react';

import classNames from 'classnames';
import { TitleBar } from '@/components/Common';
import { CLOSED_REASON } from './../../../../constants';
import { BidInfoRow } from './BidInfoRow';
import enhance from './enhancer';

// UploadModal
import UploadModal from './UploadModal';
import Modal from '@/migration/modal';

function BidHistory(props) {
  const {
    id,
    sales: {
      itemBids,
      item: { fixed },
    },
    userId,
    sellerUserId,
    setUploadModal,
    uploadModal,
    images,
    setImages,
  } = props;

  return (
    <>
      <div className="a__fish__additional">
        <Header as="h2" className="a__fish__additional__title" />
        <TitleBar title="追加画像" />

        <div className="a__fish__additional__container">
          <div className="a__fish__additional__inner">
            <div className="a__fish__additional__images">
              {images.length ? (
                <div className="a__fish__additional__images-list">
                  {/* TODO コンポーネント分けする */}
                  {images.map((image) => (
                    <Card
                      className="a__fish__additional__images-card"
                      key={image.id}
                    >
                      <Image src={image.Image} />
                      <Card.Content extra>
                        <div className="comment">
                          {image.Comment === '' ? (
                            'コメントはありません'
                          ) : (
                            <div className="comment_detail">
                              {image.Comment}
                            </div>
                          )}
                        </div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={image.Image}
                        >
                          プレビュー
                        </a>
                      </Card.Content>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="a__fish__additional__images-empty">
                  <p>追加画像はありません</p>
                </div>
              )}
            </div>
            {userId === sellerUserId && images.length !== 5 ? (
              <div className="a__fish__additional__add-btn">
                <button onClick={() => setUploadModal(true)}>
                  画像を追加する
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <Modal
          id={id}
          className="a__upload-modal"
          isModal={uploadModal}
          handleCloseModal={() => setUploadModal(false)}
          ModalBody={UploadModal}
          setImages={setImages}
        />
      </div>

      {props.sales.item.closedReason !== CLOSED_REASON.FAILED_CHARGING && (
        <div className="a__bid_history">
          <Header as="h2" className="bid_history_header" />
          <TitleBar title="入札履歴" />
          <div
            className={classNames('b__history_area', {
              'no-bids': itemBids.length === 0,
            })}
          >
            {itemBids.length !== 0 ? (
              <div className="w__purchase-notice">
                <BidInfoRow itemBids={itemBids} fixed={fixed} />
              </div>
            ) : (
              <div className="no_history">まだ入札されていません</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default enhance((props) => <BidHistory {...props} />);
