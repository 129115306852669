import { connect } from 'react-redux';
import { salesAction, usersAction } from '@/actions';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => ({
  router: state.router,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, ...salesAction, ...usersAction }, dispatch);

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance;
