import * as React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { paths } from '@/config';
import enhance from './enhance';
import {
  AuctionItems,
  BidOfBidNow,
  BidOfSoldout,
  CreditCard,
  RegistCredit,
  BankAccount,
  MyPageEdit,
  ExhibitOfExhibitNow,
  ExhibitOfSeriNow,
  ExhibitOfSoldout,
  Logout,
  Notification,
  NavBar,
  Contact,
  Leave,
  Review,
  Follow,
  Follower,
  Unrated,
  Admin,
} from '@/components/Pages';

function MyPageRoutes(props) {
  return (
    <div className="app_mypage">
      <div className="f__mypage">
        <div className="b__navbar">
          <NavBar />
        </div>
        <div className="b__main">
          <section>
            <Switch location={props.router.location}>
              <Route exact path={paths.mypage.root} component={AuctionItems} />
              <Route exact path={paths.mypage.admin} component={Admin} />
              <Route exact path={paths.mypage.all} component={AuctionItems} />
              <Route strict path={paths.mypage.contact} component={Contact} />
              <Route
                exact
                path={paths.mypage.bid_of_bidnow}
                component={BidOfBidNow}
              />
              <Route
                exact
                path={paths.mypage.bid_of_soldout}
                component={BidOfSoldout}
              />
              <Route exact path={paths.mypage.credit} component={CreditCard} />
              <Route
                strict
                path={paths.mypage.credit_register}
                component={RegistCredit}
              />
              <Route
                exact
                path={paths.mypage.bank_account}
                component={BankAccount}
              />
              <Route exact path={paths.mypage.edit} component={MyPageEdit} />
              <Route
                exact
                path={paths.mypage.exhibit_of_exhibitnow}
                component={ExhibitOfExhibitNow}
              />
              <Route
                exact
                path={paths.mypage.exhibit_of_serinow}
                component={ExhibitOfSeriNow}
              />
              <Route
                exact
                path={paths.mypage.exhibit_of_soldout}
                component={ExhibitOfSoldout}
              />
              <Route exact path={paths.mypage.logout} component={Logout} />
              <Route
                exact
                path={paths.mypage.notification}
                component={Notification}
              />
              <Route exact path={paths.mypage.leave} component={Leave} />
              <Route exact path={paths.mypage.review} component={Review} />
              <Route exact path={paths.mypage.unrated} component={Unrated} />
              <Route exact path={paths.mypage.follow} component={Follow} />
              <Route exact path={paths.mypage.follower} component={Follower} />
            </Switch>
          </section>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <MyPageRoutes {...props} />);
