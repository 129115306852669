import * as React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form/immutable';
import { Button, Image } from 'semantic-ui-react';

import { TitleBar } from '@/components/Common';
import { FieldInput } from '../../Form';
import enhance from './enhancer';
import { images } from '@/config';

function ConfirmPassword(props) {
  const { isLoading, resetPassword, handleSubmit } = props;

  return (
    <>
      {/* TODO: β版終了後、headerを削除する */}
      <header className="app_header flex-beta">
        <Image src={images.logo_header} className="logo" />
      </header>
      <div
        className="app_confirm_password flex"
        onSubmit={handleSubmit(resetPassword)}
      >
        <TitleBar title="パスワードの再設定" />

        <form className="flex">
          <span className="confirm_message">
            認証コードと新しいパスワードを入力してください。
            <br />
            認証コードはお送りしたメールに記載されています。
          </span>
          <div className="amplify-container">
            <div
              className={classNames({
                login_error_message: true,
              })}
            >
              メールアドレスが違います
            </div>
            <Field
              name="code"
              className="inp_code"
              placeholder="認証コードを入力してください"
              component={FieldInput}
              disabled={isLoading}
            />
            <Field
              name="password"
              className="inp_code"
              type="password"
              placeholder="新しいパスワードを入力してください"
              component={FieldInput}
              disabled={isLoading}
            />
            <Field
              name="confirmPassword"
              className="inp_code"
              type="password"
              placeholder="新しいパスワードを入力してください(確認用)"
              component={FieldInput}
              disabled={isLoading}
            />
            <Button
              loading={isLoading}
              disabled={isLoading}
              content="新しいパスワードを設定する"
              className="btn_submit"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default enhance((props) => <ConfirmPassword {...props} />);
