import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import moment from 'moment';

import { app } from '@/config';
import { date } from '@/utils';

function FieldSaleDate(props) {
  const year = moment().format('YYYY');

  const range = (() => {
    const arr = [];

    const start =
      props.range && Array.isArray(props.range) ? props.range[0] : -5;
    const end = props.range && Array.isArray(props.range) ? props.range[1] : 0;

    if (start >= end) {
      for (let i = end; i <= start; i++) {
        arr.push(i);
      }
    } else {
      for (let i = start; i <= end; i++) {
        arr.push(i);
      }
    }

    return arr;
  })();

  return (
    <div className="c__field-sale-date">
      <div className="w__date">
        {props.names.map((name, i) => {
          if (i === 0) return <React.Fragment key={i} />;

          return (
            <div
              className={`w__field ${
                i === 1 ? 'year' : i === 2 ? 'month' : 'day'
              }`}
              key={i}
            >
              <Dropdown
                fluid
                selection
                options={(() => {
                  if (i === 1) {
                    return [
                      {
                        key: '0',
                        value: '',
                        text: '',
                      },
                      ...range.map((r) => {
                        return {
                          key: `${r}`,
                          value: `${year - r * -1}`,
                          text: `${year - r * -1}`,
                        };
                      }),
                    ];
                  }
                  if (i === 2) {
                    return app.fishingMonth;
                  }
                  if (i === 3) {
                    return app.fishingDay;
                  }
                  return [];
                })()}
                placeholder={(() => {
                  if (i === 1) {
                    return date.getFishingYear();
                  }
                  if (i === 2) {
                    return date.getFishingMonth();
                  }
                  if (i === 3) {
                    return date.getFishingDay();
                  }
                  return undefined;
                })()}
                onChange={(event, field) =>
                  props[name].input.onChange(field.value)
                }
                disabled={props.disabled}
                defaultValue={props[name].input.value}
              />
              <span>{i === 1 ? '年' : i === 2 ? '月' : '日'}</span>
            </div>
          );
        })}
      </div>

      {props[props.names[0]].meta.touched && props[props.names[0]].meta.error && (
        <a id="error">
          <div className="redux_form error">
            {props[props.names[0]].meta.error}
          </div>
        </a>
      )}
    </div>
  );
}

export default FieldSaleDate;
