import { connect } from 'react-redux';
import { compose, setDisplayName, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { salesAction } from '@/actions';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction }, dispatch);

const enhance = compose(
  setDisplayName('BidHistory'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('uploadModal', 'setUploadModal', false)
);

export default enhance;
