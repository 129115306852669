import { compose, withState, lifecycle } from 'recompose';
import { getShippingRelayCosts } from '@/apis';

const enhance = compose(
  withState('shippingRelayCosts', 'setShippingRelayCosts', {}),
  lifecycle({
    async componentDidMount() {
      const { setShippingRelayCosts } = this.props;
      const shippingRelayCosts = await getShippingRelayCosts();

      setShippingRelayCosts(shippingRelayCosts);
    },
  })
);

export default enhance;
