import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { parseCard } from './utils';

import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function getUserCards(userId) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/users/${userId}/cards`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      const result = responseJson.map((response) => {
        return parseCard(response);
      });

      return result;
    }

    throw new Error(parseFailed(responseJson));
  } catch (error) {
    return error;
  }
}

export default getUserCards;
