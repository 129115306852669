// totalCountに応じてドロップダウンの値を作成する

export const create = (totalPages) => {
  const selections = [];
  for (let i = 1; i <= totalPages; i++) {
    selections.push(i);
  }
  const _selections = selections.map((count) => {
    return { key: count, value: count, text: count };
  });

  return _selections;
};
