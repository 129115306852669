import React from 'react';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';

import AllSearch from './AllSearch';
import CompanySearch from './CompanySearch';
import enhance from './enhancer';

const SideNav = (props) => {
  const { submit, isCompany, isOpen, handleToggle } = props;

  return (
    <div
      className={classNames('b__sidenav', {
        'is-open': isOpen,
      })}
    >
      <div
        className={classNames('b__sidenav__closed', {
          'is-open': isOpen,
        })}
      >
        <Button
          icon="close"
          onClick={() => {
            handleToggle(false);
          }}
        />
      </div>

      <form className="b__sidenav__inner GA_SEARCH_EVENT">
        {isCompany ? (
          <CompanySearch submit={submit} />
        ) : (
          <AllSearch submit={submit} />
        )}
      </form>
    </div>
  );
};

export default enhance((props) => <SideNav {...props} />);
