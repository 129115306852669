import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { ConfirmPassword } from '@/components/Pages';
import enhance from './enhancer';

function ConfirmResetPasswordRoutes() {
  return (
    <Route
      strict
      path={paths.before.confirmPassword}
      component={ConfirmPassword}
    />
  );
}

export default enhance((props) => <ConfirmResetPasswordRoutes {...props} />);
