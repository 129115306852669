import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({ users: state.users.toJS() });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('ReviewItem'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isReplyModal', 'setIsReplyModal', false),
  withHandlers({
    onClick: (props) => async (content, current) => {
      await props.getUserById(props.sourceUserID);
      await props.getUserReviewPagination(
        { limit: 5, current: current, id: props.sourceUserID },
        ['-created_at']
      );
    },
  })
);

export default enhance;
