const jas = [
  {
    key: '1',
    value: 1,
    name: 'jas1',
    text: '有機',
  },
  {
    key: '2',
    value: 2,
    name: 'jas2',
    text: '転換期中有機',
  },
  {
    key: '3',
    value: 3,
    name: 'jas3',
    text: '無農薬栽培',
  },
  {
    key: '4',
    value: 4,
    name: 'jas4',
    text: '無化学肥料栽培',
  },
  {
    key: '5',
    value: 5,
    name: 'jas5',
    text: '減化学肥料',
  },
];

export const jasNames = jas.map(({ name }) => name);

export default jas;
