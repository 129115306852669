export default function sell(values) {
  const errors = {};

  const min_size = values.get('min_size');
  const max_size = values.get('max_size');
  const min_weight = values.get('min_weight');
  const max_weight = values.get('max_weight');
  const min_price = values.get('min_price');
  const max_price = values.get('max_price');
  const min_fix_price = values.get('min_fix_price');
  const max_fix_price = values.get('max_fix_price');

  if (min_size) {
    if (!checkNumber(min_size)) {
      errors.min_size = '半角数字を入力して下さい';
    }
  }
  if (max_size) {
    if (!checkNumber(max_size)) {
      errors.max_size = '半角数字を入力して下さい';
    }
  }
  if (min_weight) {
    if (!checkNumber(min_weight)) {
      errors.min_weight = '半角数字を入力して下さい';
    }
  }
  if (max_weight) {
    if (!checkNumber(max_weight)) {
      errors.max_weight = '半角数字を入力して下さい';
    }
  }
  if (min_price) {
    if (!checkNumber(min_price)) {
      errors.min_price = '半角数字を入力して下さい';
    }
  }
  if (max_price) {
    if (!checkNumber(max_price)) {
      errors.max_price = '半角数字を入力して下さい';
    }
  }
  if (min_fix_price) {
    if (!checkNumber(min_fix_price)) {
      errors.min_fix_price = '半角数字を入力して下さい';
    }
  }
  if (max_fix_price) {
    if (!checkNumber(max_fix_price)) {
      errors.max_fix_price = '半角数字を入力して下さい';
    }
  }
  return errors;
}

function checkNumber(target) {
  if (!/^[0-9]+$/.test(target)) {
    return false;
  }
  return true;
}
