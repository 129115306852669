import React from 'react';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import enhance from './enhancer';
import { FieldInput } from '../../../../Form';

function ResetEmailModal(props) {
  const { resetEmail, loading } = props;

  return (
    <form className="flex">
      <div className="a__reset-email-modal">
        <div className="title">
          <h1>メールアドレスリセット</h1>
        </div>
        <div className="b__field">
          <Field
            type="email"
            name="oldEmail"
            fluid
            component={FieldInput}
            placeholder="現在のメールアドレス"
            disabled={loading}
          />
        </div>

        <div className="b__field">
          <Field
            type="email"
            name="newEmail"
            fluid
            component={FieldInput}
            placeholder="新しいメールアドレス"
            disabled={loading}
          />
        </div>

        <div className="b__field">
          <Field
            type="email"
            name="confirmEmail"
            fluid
            component={FieldInput}
            placeholder="新しいメールアドレス(確認用)"
            disabled={loading}
          />
        </div>

        <div className="w__actions">
          <Button
            positive
            type="button"
            fluid
            disabled={loading}
            loading={loading}
            onClick={() => resetEmail()}
          >
            メールアドレスを変更する
          </Button>
        </div>
      </div>
    </form>
  );
}

export default enhance((props) => <ResetEmailModal {...props} />);
