/* @flow */
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { salesAction, usersAction } from '@/actions';
import { constants } from '../../../../constants';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('ExhibitOfExhibitNow'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'updateLoading', true),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withHandlers({
    onChangePage: (props) => async (current) => {
      const {
        updateMyPagePagesActive,
        getSalesForMyPage,
        updatePaginationDisabled,
        users: { mydata },
      } = props;

      updateMyPagePagesActive(current);
      updatePaginationDisabled(true);
      getSalesForMyPage(
        { limit: 15, page: current },
        { bid: false, fixed: false, seller: [`${mydata.id}`] },
        ['-created_at']
      );
      updatePaginationDisabled(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getMe,
        getSalesForMyPage,
        updateLoading,
        updateMyPagePagesActive,
      } = this.props;

      const me = await getMe();
      await getSalesForMyPage(
        { limit: 15, page: constants.PAGINATION.INITIAL },
        { bid: false, fixed: false, seller: [`${me.value.id}`] },
        ['-created_at']
      );
      updateMyPagePagesActive(constants.PAGINATION.INITIAL);
      updateLoading(false);
    },
    componentWillUnmount() {
      const { initializeSalesForMyPage } = this.props;
      initializeSalesForMyPage();
    },
  })
);

export default enhance;
