import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';

function FieldDropdown(props) {
  const { meta, className } = props;
  return (
    <div className="field-group">
      <Dropdown
        {...props}
        defaultValue={props.defaultValue || props.input.value}
        onChange={(event, field) => props.input.onChange(field.value)}
        className={`input-field ${className}`}
      />
      {meta.touched && meta.error && (
        <a id="error">
          <div className="redux_form error">{meta.error}</div>
        </a>
      )}
    </div>
  );
}

export default FieldDropdown;
