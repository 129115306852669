import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withStateHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { salesAction, usersAction } from '@/actions';
import { getSaleId } from '@/utils';
import { sale as validate } from '@/validates';
import { onChangePage, submitComment } from './misc';
import { constants } from '../../../../constants';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, ...salesAction, ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('TradeCommentsBefore'),
  reduxForm({
    form: 'tradeCommentsBefore',
    initialValues: {
      message: '',
    },
    enabledReinitialize: true,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'updateLoading', false),
  withStateHandlers(
    {
      commentErrorMessage: false,
      isPaginationDisabled: false,
      isLoading: true,
      temporaryComment: '',
    },
    {
      updateCommentErrorMessage: () => (value) => ({
        commentErrorMessage: typeof value === 'boolean' ? value : true,
      }),
      updatePaginationDisabled: () => (value) => ({
        isPaginationDisabled: value,
      }),
      updateLoading: () => (value) => ({
        isLoading: value,
      }),
      takeTemporaryComment: () => (value) => ({
        temporaryComment: value,
      }),
    }
  ),
  withHandlers({
    onChangePage: onChangePage,
    submitComment: submitComment,
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getSaleCommentsBeforePagination,
        updateLoading,
        updateCommentsPagesActive,
      } = this.props;

      updateLoading(true);

      await getSaleCommentsBeforePagination(getSaleId(), {
        limit: 5,
        page: constants.PAGINATION.INITIAL,
      });

      updateCommentsPagesActive(constants.PAGINATION.INITIAL);
      updateLoading(false);
    },
  })
);

export default enhance;
