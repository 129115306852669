import { MEAT_CATEGORY, MEAT_CATEGORY_TYPE, MeatItemDataType } from '../config';

/**
 * 表示するか判断する関数
 * @param category 現在の種類
 * @param values 全ての値
 * @param current 判断する値
 */
export const IsShow = (
  category: MEAT_CATEGORY_TYPE,
  values: Record<string, any>,
  current: MeatItemDataType
) => {
  if (!current) return false;

  // 表示拒否されているまたは、条件付き表示が設定されている場合
  if ((current.show && !current.show.includes(category)) || current.linkShow) {
    const isShow = (() => {
      // 条件付きで表示できるかチェック
      if (current.linkShow) {
        // linkShow が特定のカテゴリー限定な場合
        if (
          current.linkShow.category &&
          category !== current.linkShow.category
        ) {
          return false;
        }

        // 条件先の値を取得
        const targetValue = values[current.linkShow.name];

        if (
          // 条件が配列の場合
          (Array.isArray(current.linkShow.value) &&
            current.linkShow.value.includes(targetValue)) ||
          // 条件がTRUEの場合
          (current.linkShow.value === true && Boolean(targetValue)) ||
          // 条件が一致するか
          current.linkShow.value === targetValue
        ) {
          return true;
        }
      }

      return false;
    })();

    if (!isShow) return false;
  }

  return true;
};
