import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { appAction, usersAction } from '@/actions';
import { paths } from '@/config';

import { Auth } from 'aws-amplify';
import storage from 'store';

const mapStateToProps = (state) => ({
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, ...appAction }, dispatch);

const enhance = compose(
  setDisplayName('Logout'),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleSignOut: () => async () => {
      await Auth.signOut();
      storage.clearAll();
      window.location.href = paths.landing;
    },
  })
);

export default enhance;
