import React from 'react';
import enhance from './enhancer';
import { Field } from 'redux-form/immutable';
import { Button } from 'semantic-ui-react';
import FieldTextArea from '../../../Form/FieldTextArea';

const ReplyModal = (props) => {
  const {
    handleSubmit,
    submit,
    rateId,
    current,
    isReply,
    isLoading,
    handleCloseModal,
  } = props;

  return (
    <form
      className="a__reply-modal"
      onSubmit={handleSubmit(async () => {
        await submit(rateId, current);
        handleCloseModal();
      })}
    >
      <div className="a__reply-modal__inner">
        <h3 className="a__reply-modal__title" />

        <div className="a__reply-modal__field">
          <div className="comment">
            <label>コメント</label>
          </div>
          <Field name="reply" maxLength={200} component={FieldTextArea} />
          <div className="comment-length">(最大200文字)</div>
        </div>

        <Button fluid positive disabled={isLoading} loading={isLoading}>
          {isReply ? '返信を更新する' : '返信する'}
        </Button>
      </div>
    </form>
  );
};

export default enhance((props) => <ReplyModal {...props} />);
