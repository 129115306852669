import { cookieConfig } from './cookieConfig';

export type TrafficParamType = {
  campaign_source: string;
  campaign_medium: string;
  campaign_name: string;
};

export const getTrafficCookie = () => {
  try {
    const value =
      document.cookie
        .split('; ')
        .find((row) => row.startsWith(cookieConfig.cookie_name)) ?? 'null';
    const cookie: TrafficParamType = JSON.parse(value.split('=')[1]);

    const result: TrafficParamType = {
      campaign_medium: cookie.campaign_medium ?? '',
      campaign_name: cookie.campaign_name ?? '',
      campaign_source: cookie.campaign_source ?? '',
    };
    return result;
  } catch {
    const result: TrafficParamType = {
      campaign_medium: '',
      campaign_name: '',
      campaign_source: '',
    };
    return result;
  }
};
