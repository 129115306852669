import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { appAction, salesAction, usersAction } from '@/actions';
import { configureStripe } from '@/config';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...salesAction, ...appAction, ...usersAction },
    dispatch
  );

const enhance = compose(
  setDisplayName('BankAccount'),
  withState('pageOfItems', 'pageOfItemsUpdater', []),
  withState('isLoading', 'isLoadingUpdater', false),
  withState('open', 'toggleModal', false),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onChangePage: (props) => (pageOfItems) => {
      const { pageOfItemsUpdater } = props;

      pageOfItemsUpdater(pageOfItems);
    },
    handleConnectStripe: () => () => {
      const StripeConfig = configureStripe();
      window.location.href = StripeConfig.ConnectUrl;
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.isLoadingUpdater(true);
      await this.props.getMe();
      await this.props.getUserShop(this.props.users.mydata.id);
      this.props.isLoadingUpdater(false);
    },
  })
);

export default enhance;
