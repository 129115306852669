import moment from 'moment';
import 'moment/locale/ja';

export default function formatDate(rawDate) {
  if (rawDate === undefined || rawDate === null) {
    return rawDate;
  }

  return moment(rawDate).locale('ja').format('YYYY. MM. DD (ddd) HH:mm');
}
