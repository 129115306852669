export const flexField = {
  display: 'flex',
  alignItems: 'center',
};

export const allcheckboxLabel = {
  borderBottom: 'double',
  display: 'flex',
  flexWrap: 'nowrap',
  paddingBottom: '6px',
};

export const checkboxLabel = {
  margin: '6px',
};
