import { push, goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, goBack }, dispatch);

const enhance = compose(
  setDisplayName('NotFound'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
