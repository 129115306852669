/* @flow */
/**
 * buildFishSearchConditions
 * 競り条件を渡すことで、getSale()の検索条件を生成します。
 * @param {string} bidConditionsType
 * @param {string} area
 * @param {string} keyword
 */
export default function buildFishSearchConditions(
  bidConditionsType,
  area,
  keyword
) {
  bidConditionsType =
    typeof bidConditionsType === 'string' ? bidConditionsType : 'canBidding';
  area = typeof area === 'string' ? { area } : {};
  keyword =
    typeof keyword === 'string' && keyword.length !== 0 ? { keyword } : {};

  const bidConditionsTemplate = {
    all: {},
    canBidding: {
      fixed: false,
      closed: [0],
    },
    onDisplay: {
      bid: false,
      fixed: false,
      closed: [0],
    },
    bidding: {
      bid: true,
      fixed: false,
      closed: [0],
    },
    alreadyCompletedBidding: {
      fixed: true,
    },
    biddingComplete: {
      closed: [2],
    },
  };

  try {
    // プロパティの正確な定義を確認する。
    const bidCondition = Object.getOwnPropertyDescriptor(
      bidConditionsTemplate,
      bidConditionsType
    );
    return Object.assign({}, bidCondition.value, area, keyword);
  } catch (error) {
    return {};
  }
}
