import * as React from 'react';
import { Button } from 'semantic-ui-react';

import { TitleBar } from '@/components/Common';
import enhance from './enhancer';

const ChangeEmail = (props) => {
  return (
    <div className="a__confirm-email">
      {!props.isLoading && (
        <div className="w__confirm">
          <TitleBar title="メールアドレス変更確認" />

          <span className="confirm_message">
            ボタンをクリックしてメールアドレスの変更を完了してください。
          </span>

          <div className="w__confirm-button">
            <Button
              loading={props.isSubmitting}
              disabled={props.isSubmitting}
              content="メールアドレスを変更する"
              className="btn_submit"
              onClick={() => props.submitEmail()}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default enhance((props) => <ChangeEmail {...props} />);
