import { useLayoutEffect } from 'react';

export const useModal = () => {
  const offset = window.pageYOffset;
  useLayoutEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${offset}px`;

    return () => {
      document.body.style.top = '';
      document.body.style.position = 'static';
      window.scrollTo(0, offset);
    };
  }, []);
};
