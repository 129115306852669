import { HowToSalesAccountTemplate } from '@/modules/static-page';
import React from 'react';

const HowtoSalesAccount = () => {
  return (
    <>
      <HowToSalesAccountTemplate />
    </>
  );
};

export default HowtoSalesAccount;
