import { connect } from 'react-redux';
import notify from '@/migration/notification';
import { compose, setDisplayName, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { mailAction } from '@/actions';
import { contact as validate } from '@/validates';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...mailAction, notify }, dispatch);

const enhance = compose(
  setDisplayName('Contact'),
  reduxForm({
    form: 'contact',
    destroyOnUnmount: false,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    submitSendMail: (props) => async (immutableValues) => {
      const values = immutableValues.toJS();
      const { postSendMail, reset } = props;

      try {
        await postSendMail(values.name, values.email, values.content);
        reset();
        notify(
          'お問い合わせありがとうございます。運営元にメールを送信しました。',
          'info',
          undefined,
          5
        );
      } catch (error) {
        console.error(error);
        notify(
          'メールの送信に失敗しました。しばらく時間をおいて再度お試しください。',
          'error',
          undefined,
          5
        );
      }
    },
  })
);

export default enhance;
