const genetic = [
  {
    key: '1',
    value: 1,
    name: 'genetic1',
    text: '無',
  },
  {
    key: '2',
    value: 2,
    name: 'genetic2',
    text: '有',
  },
];

export const geneticNames = genetic.map(({ name }) => name);

export default genetic;
