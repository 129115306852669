import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

const mapStateToProps = (state) => ({
  router: state.router,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ push }, dispatch);

const enhance = compose(
  setDisplayName('NotFoundRoutes'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
