import { connect } from 'react-redux';
import {
  compose,
  setDisplayName,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

import { constants } from '../../../../constants';

const mapStateToProps = (state) => ({ users: state.users.toJS() });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('UserFollower'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withState('isLoading', 'updateLoading', false),
  withHandlers({
    onChangePage: (props) => async (current) => {
      window.scrollTo({ left: 0, top: 75, behavior: 'instant' });

      const {
        getFollowerPagination,
        updateFollowerPagesActive,
        updatePaginationDisabled,
        users: {
          mydata: { id },
        },
      } = props;

      updatePaginationDisabled(true);
      updateFollowerPagesActive(current);

      await getFollowerPagination({ limit: 5, current: current, userId: id }, [
        '-created_at',
      ]);
      updatePaginationDisabled(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getFollowerPagination,
        updateFollowerPagesActive,
        getMe,
        updateLoading,
        getMyFollowers,
      } = this.props;

      updateLoading(true);

      const me = await getMe();
      const userId = me.value.id;

      await getFollowerPagination(
        { limit: 5, current: constants.PAGINATION.INITIAL, userId },
        ['-created_at']
      );

      updateFollowerPagesActive(constants.PAGINATION.INITIAL);
      getMyFollowers(userId);
      updateLoading(false);
    },
  })
);

export default enhance;
