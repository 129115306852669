import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  setDisplayName,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { usersAction, salesAction } from '@/actions';
import { queryParams } from '@/utils';
import { paths } from '@/config';

const mapStateToProps = (state) => {
  const formValues = getFormValues('emailIsReadForm')(state);

  return {
    form: state.form.toJS(),
    sales: state.sales.toJS(),
    users: state.users.toJS(),
    formValues: formValues ? formValues.toJS() : {},
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, ...usersAction, notify }, dispatch);

const enhance = compose(
  setDisplayName('NotificationList'),
  reduxForm({
    form: 'emailIsReadForm',
  }),
  connect(mapStateToProps, mapDispatchToProps),

  withState('showModal', 'updateShowModal', false),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withState('loading', 'setLoading', false),
  withHandlers({
    // ページ番号更新
    onChangePage: (props) => async (current) => {
      const { history } = props;

      props.change('AllIsReadForm', false);
      props.users.activities.pages.active.map((row) => {
        props.change(row.id, false);
      });

      const str = queryParams.parse(location.search);
      const objectUrl = {
        ...str,
        page: current,
      };

      const queryUrl = queryParams.toString(objectUrl);
      history.push(`${paths.mypage.notification}?${queryUrl}`);
    },

    // 通知の既読
    readNotification: (props) => async (id) => {
      try {
        const me = await props.getMe();

        const activities = await props.getActivities(me.value.id, {
          limit: 8,
          page: props.users.activities.current,
          notice: props.activeIndex,
        });
        const unReads = activities.value.pages.active.filter((item) => {
          return item.id === id;
        });
        const unReadIds = unReads.map((item) => {
          return item.id;
        });
        // 通知を既読にする
        await props.putActivitiesToRead(props.users.mydata.id, unReadIds);
      } catch {
        // 通知の既読にするAPIエラーのため、何もしない
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getMe,
        setLoading,
        getActivities,
        location,
        updateActivitiesPageActive,
        updateLoading,
      } = this.props;

      try {
        updateLoading(true);
        setLoading(true);
        const params = queryParams.parse(location.search);
        const me = await getMe();

        const page = params?.page ? Number(params.page) : 1;
        updateActivitiesPageActive(page);
        await getActivities(me.value.id, {
          limit: 8,
          page: page,
          notice: params.category === 'g' ? 0 : 1,
        });

        window.scrollTo({ left: 0, top: 0 });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        updateLoading(false);
      }
    },

    async componentDidUpdate(prevProps) {
      const {
        location,
        setLoading,
        updateLoading,
        getActivities,
        updateActivitiesPageActive,
        users: { mydata },
      } = this.props;
      const { location: prevLocation } = prevProps;

      if (location.search === prevLocation.search) return;

      const params = queryParams.parse(location.search);
      try {
        setLoading(true);
        updateLoading(true);

        const page = Number(params.page);

        updateActivitiesPageActive(page);

        await getActivities(mydata.id, {
          limit: 8,
          page,
          notice: params.category === 'g' ? 0 : 1,
        });

        window.scrollTo({ left: 0, top: 0 });
      } catch (error) {
        console.error(error);
      } finally {
        updateLoading(false);
        setLoading(false);
      }
    },
  })
);

export default enhance;
