export default function socialSignup(immutableValues) {
  const errors = {};
  const { displayName, isAgreement, prefecture } = immutableValues.toJS();

  if (!displayName || !displayName.trim()) {
    errors.displayName = 'ニックネームを入力してください';
  }

  if (!isAgreement) {
    errors.isAgreement = '利用規約同意にチェックしてください';
  }

  if (!prefecture) {
    errors.prefecture = '都道府県を選択してください';
  }

  return errors;
}
