import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { CognitoCallback } from '@/components/Pages';
import enhance from './enhancer';

function CognitoCallbackRoutes() {
  return (
    <Route strict path={paths.cognitocallback} component={CognitoCallback} />
  );
}

export default enhance((props) => <CognitoCallbackRoutes {...props} />);
