import React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { FishAll, FishCompany, FishPersonal } from '@/components/Pages';
import enhance from './enhancer';

function FishesRoutes() {
  return (
    <>
      <Route path={paths.fishes.all} component={FishAll} />
      <Route path={paths.fishes.company} component={FishCompany} />
      <Route path={paths.fishes.personal} component={FishPersonal} />
    </>
  );
}

export default enhance((props) => <FishesRoutes {...props} />);
