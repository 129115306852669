export default [
  {
    key: '0',
    value: 0,
    text: '選択なし',
  },
  {
    key: '1',
    value: 1,
    text: 'オゾン水',
  },
  {
    key: '2',
    value: 2,
    text: '電解水',
  },
];
