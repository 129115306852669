const savingMethods = [
  {
    key: '1',
    name: 'savingMethods1',
    value: 1,
    text: 'クーラーボックス（保冷）',
  },
  {
    key: '2',
    name: 'savingMethods2',
    value: 2,
    text: '冷蔵庫',
  },
  {
    key: '3',
    name: 'savingMethods3',
    value: 3,
    text: '冷蔵庫（チルド室）',
  },
  {
    key: '4',
    name: 'savingMethods4',
    value: 4,
    text: '冷凍庫',
  },
  {
    key: '5',
    name: 'savingMethods5',
    value: 5,
    text: '冷凍庫（家庭用・急速冷凍）',
  },
  {
    key: '6',
    name: 'savingMethods6',
    value: 6,
    text: '冷凍庫（家庭用・液体急速凍結・真空）',
  },
  {
    key: '7',
    name: 'savingMethods7',
    value: 7,
    text: '冷凍庫（業務用・急速冷凍）',
  },
  {
    key: '8',
    name: 'savingMethods8',
    value: 8,
    text: '冷凍庫（業務用・液体急速凍結・真空）',
  },
];

export const savingMethodNames = savingMethods.map(({ name }) => name);

export default savingMethods;
