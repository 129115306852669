export default [
  {
    key: '1',
    value: 1,
    text: '北海道地方',
  },
  {
    key: '2',
    value: 2,
    text: '東北地方',
  },
  {
    key: '3',
    value: 3,
    text: '関東地方',
  },
  {
    key: '4',
    value: 4,
    text: '中部地方',
  },
  {
    key: '5',
    value: 5,
    text: '近畿地方',
  },
  {
    key: '6',
    value: 6,
    text: '中国・四国地方',
  },
  {
    key: '7',
    value: 7,
    text: '九州地方',
  },
  {
    key: '8',
    value: 8,
    text: '沖縄地方',
  },
];
