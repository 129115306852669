import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';

import { bindActionCreators } from 'redux';
import { salesAction, usersAction, appAction } from '@/actions';
import { queryParams } from '@/utils';
import { paths } from '@/config';
import { unzipURL } from '../../../utils/sales';

// export const defaultSort = '-biddable-fixed-created_at';
// 入札件数の多い順
export const defaultSort = '-biddable-fixed-bid_count-created_at';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  form: state.form.toJS(),
  users: state.users.toJS(),
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...salesAction, ...usersAction, ...appAction, notify },
    dispatch
  );

const enhance = compose(
  setDisplayName('Fishes'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withState('isLoading', 'updateLoading', false),
  withState('showModal', 'updateShowModal', false),
  withState('isOpen', 'setOpen', false),

  withHandlers({
    /** ページネーション */
    handleChangePage: (props) => async (page) => {
      const { history, location } = props;

      const str = queryParams.parse(location.search);
      const objectURL = Object.assign(str, {
        page,
      });

      const queryUrl = queryParams.toString(objectURL);
      history.push(`${paths.fishes.all}?${queryUrl}`);
    },

    /** 検索を行う */
    handleSearch: (props) => async (query) => {
      const { history, setOpen } = props;

      const str = queryParams.toString(query);

      let queryUrl = 'page=1';
      if (str) {
        queryUrl = `page=1&${str}`;
      }

      setOpen(false);
      history.push(`${paths.fishes.all}?${queryUrl}`);
    },

    /** サイドメニューの開閉関数 */
    handleToggle: (props) => (open) => {
      const { setOpen } = props;

      setOpen(open);
    },

    // 並び替えを行う
    handleSort: (props) => async (sortKey) => {
      const { history, setOpen } = props;

      const { params } = unzipURL(location.search, defaultSort);

      if (sortKey) params.sort = sortKey;
      // 1ページ目にする
      params.page = 1;

      const queryUrl = queryParams.toString(params);

      setOpen(false);
      history.push(`${paths.fishes.all}?${queryUrl}`);
    },
  }),

  lifecycle({
    async componentDidMount() {
      const { updateLoading, getSalesPagination, location } = this.props;

      try {
        updateLoading(true);
        const { params } = unzipURL(location.search, defaultSort);

        await getSalesPagination(params.page, {
          ...params,
          limit: 20,
        });
      } catch (error) {
        console.error(error);
      } finally {
        updateLoading(false);
      }
    },

    async componentDidUpdate(prevProps) {
      const { location, updateLoading, getSalesPagination } = this.props;
      const { location: prevLocation } = prevProps;

      if (location.search !== prevLocation.search) {
        const { params } = unzipURL(location.search, defaultSort);

        try {
          updateLoading(true);

          await getSalesPagination(params.page, {
            ...params,
            limit: 20,
          });

          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        } catch (error) {
          console.error(error);
        } finally {
          updateLoading(false);
        }
      }
    },
  })
);

export default enhance;
