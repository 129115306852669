import React from 'react';
import { Field } from 'redux-form/immutable';
import Dropdown from '../../../Common/Dropdown/Dropdown';

const SideNavDropDown = ({ label, name, placeholder, options }) => {
  return (
    <li className="b__sidenav__listitem">
      <h3 className="b__sidenav__listitem-title">{label}</h3>
      <Field
        name={name}
        selection
        fluid
        options={options}
        placeholder={placeholder}
        component={Dropdown}
      />
    </li>
  );
};

export default SideNavDropDown;
