const ikejimeTimings = [
  {
    key: '0',
    value: 0,
    text: '未選択',
  },
  {
    key: '1',
    value: 1,
    text: '直後',
  },
  {
    key: '2',
    value: 2,
    text: '～3時間',
  },
  {
    key: '3',
    value: 3,
    text: '～6時間',
  },
  {
    key: '4',
    value: 4,
    text: '～12時間',
  },
  {
    key: '5',
    value: 5,
    text: '～24時間',
  },
  {
    key: '6',
    value: 6,
    text: '24時間～',
  },
];

export default ikejimeTimings;
