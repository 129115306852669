import React from 'react';
import { Field } from 'redux-form/immutable';
import Input from '../../../Form/FieldInput';

/** 最小値と最大値を入力できる */
const BetweenInput = ({ label, smallName, largeName, maxLength, isPrice }) => {
  return (
    <li className="b__sidenav__listitem">
      <h3 className="b__sidenav__listitem-title">{label}</h3>
      <div className="b__sidenav__column-fields">
        <div
          className={
            isPrice ? 'b__sidenav__field is-price' : 'b__sidenav__field'
          }
        >
          <Field name={smallName} component={Input} maxLength={maxLength} />
        </div>

        <div className="b__sidenav__column__unit">〜</div>

        <div
          className={
            isPrice ? 'b__sidenav__field is-price' : 'b__sidenav__field'
          }
        >
          <Field name={largeName} component={Input} maxLength={maxLength} />
        </div>
      </div>
    </li>
  );
};

export default BetweenInput;
