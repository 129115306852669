import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { appAction, usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  router: state.router,
  users: state.users.toJS(),
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, ...appAction, push }, dispatch);

const enhance = compose(
  setDisplayName('BeforeRoutes'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
