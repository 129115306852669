import { handleActions } from 'redux-actions';
import { ActionType } from 'redux-promise-middleware';
import { usersAction } from '../actions';
import { Users } from '../models';

export default handleActions(
  {
    [`${usersAction.getMe}_${ActionType.Fulfilled}`](state, action) {
      return state.getMe(state, action);
    },

    [`${usersAction.getUserReviewPagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getUserReviewPagination(state, action);
    },
    [`${usersAction.getNotReviewedItem}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getNotReviewedItem(state, action);
    },
    [usersAction.updateReviewPagesActive](state, action) {
      return state.updateReviewPagesActive(state, action);
    },
    [usersAction.updateActivitiesPageActive](state, action) {
      return state.updateActivitiesPageActive(state, action);
    },
    [`${usersAction.getUserById}_${ActionType.Fulfilled}`](state, action) {
      return state.getUserById(state, action);
    },
    [`${usersAction.postUser}_${ActionType.Fulfilled}`](state, action) {
      return state.postUser(state, action);
    },
    [`${usersAction.getUserCards}_${ActionType.Fulfilled}`](state, action) {
      return state.getUserCards(state, action);
    },
    [`${usersAction.getUserShop}_${ActionType.Fulfilled}`](state, action) {
      return state.getUserShop(state, action);
    },
    [`${usersAction.postUserCard}_${ActionType.Fulfilled}`](state, action) {
      return state.postUserCard(state, action);
    },
    [`${usersAction.deleteUserCards}_${ActionType.Fulfilled}`](state, action) {
      return state.deleteUserCards(state, action);
    },
    [`${usersAction.getActivities}_${ActionType.Fulfilled}`](state, action) {
      return state.getActivities(state, action);
    },
    [`${usersAction.getUserShipping}_${ActionType.Fulfilled}`](state, action) {
      return state.getUserShipping(state, action);
    },
    [`${usersAction.getBuyersShipping}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getBuyersShipping(state, action);
    },
    [`${usersAction.putUser}_${ActionType.Fulfilled}`](state, action) {
      return state.putUser(state, action);
    },
    [`${usersAction.putUserShop}_${ActionType.Fulfilled}`](state, action) {
      return state.putUserShop(state, action);
    },
    [`${usersAction.putUserBlame}_${ActionType.Fulfilled}`](state, action) {
      return state.putUserBlame(state, action);
    },
    [`${usersAction.getActivitiesUnreadCounts}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getActivitiesUnreadCounts(state, action);
    },
    [`${usersAction.putActivities}_${ActionType.Fulfilled}`](state, action) {
      return state.putActivities(state, action);
    },
    [`${usersAction.getMyFollowers}_${ActionType.Fulfilled}`](state, action) {
      return state.getMyFollowers(state, action);
    },
    [`${usersAction.getMyFollows}_${ActionType.Fulfilled}`](state, action) {
      return state.getMyFollows(state, action);
    },
    [`${usersAction.getUserFollowers}_${ActionType.Fulfilled}`](state, action) {
      return state.getUserFollowers(state, action);
    },
    [`${usersAction.getUserFollows}_${ActionType.Fulfilled}`](state, action) {
      return state.getUserFollows(state, action);
    },
    [`${usersAction.getFollowerPagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getFollowerPagination(state, action);
    },
    [usersAction.updateFollowerPagesActive](state, action) {
      return state.updateFollowerPagesActive(state, action);
    },
    [`${usersAction.getFollowPagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getFollowPagination(state, action);
    },
    [usersAction.updateFollowPagesActive](state, action) {
      return state.updateFollowPagesActive(state, action);
    },
    [`${usersAction.postFollow}_${ActionType.Fulfilled}`](state, action) {
      return state.postFollow(state, action);
    },
    [`${usersAction.deleteFollow}_${ActionType.Fulfilled}`](state, action) {
      return state.deleteFollow(state, action);
    },
  },
  new Users()
);
