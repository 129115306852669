import React from 'react';
import { Field } from 'redux-form/immutable';

import Accordion from '../Accordion';
import BetweenInput from '../BetweenInput';
import SideNavDropDown from '../DropDown';
import SearchInput from '../SearchInput';
import Checkbox from '../../../Form/FieldCheckbox';

import shippingExpenses from '../../../../config/app/shippingExpense';
import deliveryMethods from '../../../../config/app/deliveryMethod';
import shippingTimings from '../../../../config/app/shippingTiming';
import { MEAT_CATEGORY, CreateArrayData, MEAT_ITEMS } from '@/modules/meat';

import { statusCategory, areas, sorts } from '../data';

// TODO:絞り込み検索
/** すべてと個人出品の検索 */

const AllSearch = ({ submit }) => (
  <>
    <Field name="keyword" component={SearchInput} submit={submit} />

    <ul className="b__sidenav__list">
      <SideNavDropDown
        name="status"
        label="対象商品"
        placeholder="カテゴリ"
        options={statusCategory}
      />
      {/*
      <SideNavDropDown
        name="area"
        label="地域"
        placeholder="地域"
        options={areas}
      /> */}
      {/*
      <BetweenInput
        label="商品サイズ"
        smallName="min_size"
        largeName="max_size"
        maxLength="6"
      /> */}

      <BetweenInput
        label="商品重量"
        smallName="min_weight"
        largeName="max_weight"
        maxLength="6"
      />

      <BetweenInput
        label="商品価格"
        smallName="min_price"
        largeName="max_price"
        maxLength="7"
        isPrice
      />

      <BetweenInput
        label="即決価格"
        smallName="min_fix_price"
        largeName="max_fix_price"
        maxLength="7"
        isPrice
      />

      {/* ================== ↓ 追加 ↓ ================== */}

      {/* 種類 */}
      <Accordion
        id="category"
        title="種類"
        data={MEAT_CATEGORY}
        component={Checkbox}
      />

      {Object.keys(MEAT_ITEMS).map((key) => {
        const d = MEAT_ITEMS[key];

        if (!d.isSideNav) return <React.Fragment {...{ key }} />;
        return (
          <Accordion
            id={key}
            title={d.label}
            data={CreateArrayData(key)}
            component={Checkbox}
            key={key}
          />
        );
      })}

      {/* ================== ↑ 追加 ↑ ================== */}

      {/* 配送料の負担 */}
      <Accordion
        id="shipping_expense"
        title="配送料の負担"
        data={shippingExpenses}
        component={Checkbox}
      />

      {/* 配送料の負担 */}
      <Accordion
        id="delivery_method"
        title="配送方法"
        data={deliveryMethods}
        component={Checkbox}
      />

      {/* 発送日数 */}
      <Accordion
        id="shipping_timing"
        title="発送日数"
        data={shippingTimings}
        component={Checkbox}
      />

      <div className="border" />

      <SideNavDropDown
        name="sort"
        label="並び替え"
        placeholder="入札件数の多い順"
        options={sorts}
      />
    </ul>
  </>
);

export default AllSearch;
