import { Auth } from '@/utils';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import store from 'store';
import { resetPassword as validate } from '@/validates';
import { appAction, usersAction } from '@/actions';
import { paths } from '@/config';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, ...appAction, push, notify }, dispatch);

const enhance = compose(
  setDisplayName('ForgotPassword'),
  reduxForm({
    form: 'ForgotPasswordForm',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),

  withHandlers({
    resetPassword: (props) => async (immutableValues) => {
      const values = immutableValues.toJS();
      const { push, setTempEmail, setIsLoading } = props;

      try {
        setIsLoading(true);
        await Auth.forgotPassword(values.username);
        setTempEmail(values.username);
        store.set('tempEmail', values.username);
        push(paths.before.confirmPassword);
        notify(
          'パスワードリセットの為のメールをお送りしました。',
          'info',
          undefined,
          5
        );
      } catch (error) {
        setIsLoading(false);
        notify('存在しないメールアドレスです。', 'error', undefined, 5);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      try {
        await this.props.getMe();
      } catch (err) {
        // no current user のエラーが重複するので、catchの上に何もしない
      }
    },
  })
);

export default enhance;
