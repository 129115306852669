import { compose, setDisplayName, withState, withHandlers } from 'recompose';

const enhance = compose(
  setDisplayName('BuyItNowModal'),
  withState('redListCheck', 'updateRedListCheck', false),
  withState('rareSpeciesCheck', 'updateRareSpeciesCheck', false),
  withState('islandCheck', 'updateIslandCheck', false),

  withHandlers({
    toggleRedList: (props) => () => {
      const { updateRedListCheck, redListCheck } = props;
      updateRedListCheck(!redListCheck);
    },
    toggleRareSpecies: (props) => () => {
      const { updateRareSpeciesCheck, rareSpeciesCheck } = props;
      updateRareSpeciesCheck(!rareSpeciesCheck);
    },
    toggleIslandCheck: (props) => () => {
      const { islandCheck, updateIslandCheck } = props;
      updateIslandCheck(!islandCheck);
    },
  })
);

export default enhance;
