import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers } from 'recompose';
import notify from '@/migration/notification';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { salesAction } from '@/actions';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, ...salesAction }, dispatch);

const enhance = compose(
  setDisplayName('SaleCloseModal'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    cancelSale: (props) => async () => {
      const {
        push,
        match,
        deleteSale,
        modalProps: { handleModal, lastUpdatedAt },
      } = props;
      try {
        const reqResult = await deleteSale(match.params.sale_id, lastUpdatedAt);
        push('/mypage');
        if (reqResult.value.message == 'success') {
          notify('出品を取り下げました。', 'info', undefined, 5);
        } else {
          throw new Error('error');
        }
      } catch (error) {
        notify(
          '更新があったため、処理を中止しました。画面をリロードして再度実行してください。',
          'error',
          undefined,
          5
        );
      }

      handleModal(false);
    },
  })
);

export default enhance;
