import { connect } from 'react-redux';
import { compose, setDisplayName, withState, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { postUserReview } from '../../../../../apis';
import notify from '@/migration/notification';
import { review as validate } from '@/validates';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  form: state.form.toJS(),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const enhance = compose(
  setDisplayName('ModalBody'),
  reduxForm({
    form: 'review',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('submitComplete', 'submitUpdate', false),
  withState('reputation', 'reputationUpdate', 0),
  withHandlers({
    onSubmit: (props) => async (content) => {
      const values = content.toJS();
      const { getNotReviewedItem } = props.modalProps;

      try {
        const params = {
          userId: props.modalProps.mydata.id,
          saleId: props.modalProps.id,
          distUserId: props.modalProps.sellerUserId,
          point: props.reputation,
          comment: values.reviewContent,
        };
        if (params.point === 0) {
          notify('星1~5で採点してください。', 'error', undefined, 5);
          return;
        }
        //コメントが空欄の時にundefinedではなく空白で値を渡す。
        if (!params.comment) {
          params.comment = ' ';
        }
        await postUserReview(params);

        //レビューが終わった直後にボタンの文言を変えたいため
        await props.modalProps.getSaleById(props.modalProps.id);
        props.submitUpdate(!props.submitComplete);
        await getNotReviewedItem();
      } catch (err) {
        notify(
          'レビューの投稿に失敗しました。時間をおいて再度お試しください。',
          'error',
          undefined,
          5
        );
      }
    },
    closedModal: (props) => () => {
      props.isModal = false;
    },
    handleRate: (props) => (rate) => {
      props.reputationUpdate(rate);
    },
  })
);

export default enhance;
