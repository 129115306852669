import * as React from 'react';

import Thumb from './../Thumb/Thumb';

function ThumbContainer(props) {
  return (
    <>
      {props.droppedImages.length === 0 ? null : (
        <div className="thumb_wapper_aside">
          {props.droppedImages.map((f, index) => (
            <Thumb
              firstImage={index === 0}
              key={f.original}
              {...props}
              file={f}
              onClick={() => {
                props?.onClick(f);
              }}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default ThumbContainer;
