import axios from 'axios';

const searchByPostal = async ({ postal }) =>
  axios.get('https://geoapi.heartrails.com/api/json', {
    params: {
      postal,
      method: 'searchByPostal',
    },
  });

export default searchByPostal;
