// eslint-disable @typescript-eslint/camelcase
export default function parseRate({ target_user_id, from_user_id, body }) {
  return Object.assign(
    {},
    {
      targetUserId: target_user_id,
      fromUserId: from_user_id,
      body,
    }
  );
}
