import React from 'react';
import enhance from './enhancer';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import { FieldLabelLinkCheckbox } from '../../../../Form';

import * as Styles from './styles';

const EmailConfig = (props) => {
  const { handleSubmit, submit } = props;
  return (
    <form style={Styles.wrapper} onSubmit={handleSubmit(submit)}>
      <div style={Styles.wrapperInner}>
        <h3 style={Styles.title}>メール通知設定</h3>
        <div style={Styles.paragraph}>
          <p style={Styles.subTitle}>
            通知メールを受信するかどうかを設定します。
          </p>
        </div>

        <div style={Styles.flexField}>
          <Field
            name="anotherUserBidded"
            id="anotherUserBidded"
            component={FieldLabelLinkCheckbox}
            children={
              <div style={Styles.checkboxLabel}>
                最高額入札者の時に入札額が更新されたことを受信する。
              </div>
            }
          />
        </div>

        <div style={Styles.flexField}>
          <Field
            name="anotherUserFixedSale"
            id="anotherUserFixedSale"
            component={FieldLabelLinkCheckbox}
            children={
              <div style={Styles.checkboxLabel}>
                入札していた競りが落札されたことを受信する。
              </div>
            }
          />
        </div>

        <div style={Styles.flexField}>
          <Field
            name="sallerWithDrawSale"
            id="sallerWithDrawSale"
            component={FieldLabelLinkCheckbox}
            children={
              <div style={Styles.checkboxLabel}>
                入札していた競りが取り下げられたことを受信する。
              </div>
            }
          />
        </div>

        <div style={Styles.flexField}>
          <Field
            name="followUserSell"
            id="followUserSell"
            component={FieldLabelLinkCheckbox}
            children={
              <div style={Styles.checkboxLabel}>
                フォローしているユーザーの競りが出品されたことを受信する。
              </div>
            }
          />
        </div>

        <div style={Styles.flexField}>
          <Field
            name="reviewReceived"
            id="reviewReceived"
            component={FieldLabelLinkCheckbox}
            children={
              <div style={Styles.checkboxLabel}>
                取引している競りに相手が評価したことを受信する。
              </div>
            }
          />
        </div>

        <div style={Styles.flexField}>
          <Field
            name="reviewReplyCommentReceived"
            id="reviewReplyCommentReceived"
            component={FieldLabelLinkCheckbox}
            children={
              <div style={Styles.checkboxLabel}>
                評価に対してコメントが返されたことを受信する。
              </div>
            }
          />
        </div>

        <Button positive fluid style={Styles.submitBtn}>
          設定を保存する
        </Button>
      </div>
    </form>
  );
};

export default enhance((props) => <EmailConfig {...props} />);
