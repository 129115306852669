import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { parseUser } from './utils';
import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function getUserByID(userId) {
  try {
    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/users/${userId}`,
      {
        method: 'GET',
      }
    );

    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      return parseUser(responseJson);
    }

    throw new Error(parseFailed(responseJson.errors[0]));
  } catch (error) {
    return error;
  }
}

export default getUserByID;
