// eslint-disable @typescript-eslint/camelcase
import axios from 'axios';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

const resouces = '/users';

/**
 * @param userId
 * @param params {saleId, code}
 */
export const getActivityPageNumber = async (userId, params) => {
  try {
    const token = await Auth.getIdToken();

    const { data } = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .get(
        `${GO_ENDPOINT}/v1/${SITE_TYPE}${resouces}/${userId}/activities/page_number`,
        {
          params,
        }
      );

    return { data };
  } catch (error) {
    throw new Error(error);
  }
};
