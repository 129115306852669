import { isImmutable } from 'immutable';

export default function sale(values) {
  const errors = {};

  if (isImmutable(values)) {
    if (values) {
      const message = values.get('message');
      const editmessage = values.get('editmessage');

      if (!message) {
        errors.message = 'コメントを入力してください';
      }

      if (editmessage) {
        if (editmessage.length > 1000) {
          errors.editmessage = '1000以下の文字数を入力してください';
        }
      }
    }
  }

  return errors;
}
