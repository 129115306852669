import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { salesAction, usersAction } from '@/actions';
import { constants } from '../../../constants';

const USER_REVIEW_PARAMS = {
  // 評価のページ番号
  EVP: 'evp',
  // 通常のページ番号
  PAGE: 'page',
};

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('UserProfile'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withState('isLoading', 'updateLoading', false),
  withState('itemLimit', 'updateItemLimit', 4),
  withHandlers({
    // 出品した商品のページ切り替え時
    onChangePage: (props) => async (current) => {
      const {
        updatePaginationDisabled,
        updateUserProfilePageActive,
        updateReviewPagesActive,
        history,
        match,
      } = props;

      updatePaginationDisabled(true);
      updateUserProfilePageActive(current);

      const userId = match.params.user_id;
      const searchParams = new URLSearchParams(history.location.search);
      const evp = searchParams.get(USER_REVIEW_PARAMS.EVP);
      const numberEvp = Number(evp);

      // 出品した商品のページ番号が1の時
      if (current === constants.PAGINATION.INITIAL) {
        if (evp) {
          history.push(`/users/${userId}?evp=${evp}`);
          updateReviewPagesActive(numberEvp);
        } else {
          history.push(`/users/${userId}`);
        }
      }
      // 出品した商品のページ番号が2以上の時
      else {
        if (evp) {
          history.push(`/users/${userId}?page=${current}&evp=${evp}`);
          updateReviewPagesActive(numberEvp);
        } else {
          history.push(`/users/${userId}?page=${current}`);
        }
      }

      updatePaginationDisabled(false);
    },

    // 評価のページネーション切り替え時
    onChangeReview: (props) => async (current) => {
      const {
        match,
        updateReviewPagesActive,
        updatePaginationDisabled,
        updateUserProfilePageActive,
        history,
      } = props;

      updatePaginationDisabled(true);
      updateReviewPagesActive(current);

      const userId = match.params.user_id;
      const searchParams = new URLSearchParams(history.location.search);
      const page = searchParams.get(USER_REVIEW_PARAMS.PAGE);
      const numberPage = Number(page);

      // 評価のページ番号が1の時
      if (current === constants.PAGINATION.INITIAL) {
        if (page) {
          history.push(`/users/${userId}?page=${page}`);
          updateUserProfilePageActive(numberPage);
        } else {
          history.push(`/users/${userId}`);
        }
      }
      // 評価のページ番号が2以上の時
      else {
        if (page) {
          history.push(`/users/${userId}?page=${page}&evp=${current}`);
          updateUserProfilePageActive(numberPage);
        } else {
          history.push(`/users/${userId}?evp=${current}`);
        }
      }

      updatePaginationDisabled(false);
    },
    handleClickItems: (props) => () => {
      const { updateItemLimit, itemLimit } = props;
      updateItemLimit(itemLimit + 4);
    },
  }),
  lifecycle({
    // マウント時
    async componentDidMount() {
      const {
        updateLoading,
        match,
        getUserReviewPagination,
        updateReviewPagesActive,
        getUserById,
        updateUserProfilePageActive,
        getSalesForUserProfile,
        getUserFollows,
        getUserFollowers,
        getMe,
        getMyFollows,
      } = this.props;

      // 評価の取得
      try {
        const userId = match.params.user_id;

        updateLoading(true);
        // ページ番号を取得する
        const searchParams = new URLSearchParams(window.location.search);
        const evp =
          searchParams.get(USER_REVIEW_PARAMS.EVP) ||
          constants.PAGINATION.INITIAL;
        const numberEvp = Number(evp);

        await getUserReviewPagination(
          {
            limit: 5,
            current: evp,
            id: userId,
          },
          ['-created_at']
        );

        updateReviewPagesActive(numberEvp);
      } catch {
        // 0件メッセージを表示するため、何もしない
      } finally {
        updateLoading(false);
      }

      // 出品した商品の取得
      try {
        const userId = match.params.user_id;
        const searchParams = new URLSearchParams(window.location.search);
        const page =
          searchParams.get(USER_REVIEW_PARAMS.PAGE) ||
          constants.PAGINATION.INITIAL;
        const numberPage = Number(page);

        updateLoading(true);

        Promise.all([
          getUserById(userId),
          getSalesForUserProfile(
            {},
            { limit: 6, page },
            { seller: [userId], closed: [0, 1, 2, 3] },
            ['-created_at']
          ),
          getUserFollows(userId),
          getUserFollowers(userId),
        ]).then(() => {
          updateUserProfilePageActive(numberPage);
        });
      } catch (error) {
        // 0件メッセージを表示するため、何もしない
      } finally {
        updateLoading(false);
      }

      try {
        const me = await getMe({ loose: true });
        getMyFollows(me.value.id);
      } catch (err) {
        // no current user のエラーが重複するので、catchの上に何もしない
      }

      window.setTimeout(() => {
        // ハッシュがある時は、指定の位置まで移動する
        const hash = window.location.hash;
        if (hash) {
          // eslint-disable-next-line
          window.location.href = window.location.href;
        }
      }, 500);
    },

    async componentDidUpdate(prevProps) {
      const {
        location,
        match,
        updateLoading,
        getUserReviewPagination,
        updateReviewPagesActive,
        getUserById,
        getSalesForUserProfile,
        getUserFollows,
        getUserFollowers,
        updateUserProfilePageActive,
      } = this.props;
      const { location: prevLocation } = prevProps;

      // URLパラメータでページ番号を管理、前回と同じ場合(変化がない場合)は,return
      if (location.search === prevLocation.search) return;

      const searchParams = new URLSearchParams(window.location.search);
      const evp =
        searchParams.get(USER_REVIEW_PARAMS.EVP) ||
        constants.PAGINATION.INITIAL;
      const page =
        searchParams.get(USER_REVIEW_PARAMS.PAGE) ||
        constants.PAGINATION.INITIAL;

      const numberEvp = Number(evp);
      const numberPage = Number(page);

      // 評価の取得
      try {
        const userId = match.params.user_id;

        updateLoading(true);
        await getUserReviewPagination(
          {
            limit: 5,
            current: evp,
            id: userId,
          },
          ['-created_at']
        );

        updateReviewPagesActive(numberEvp);
      } catch {
        //
      } finally {
        updateLoading(false);
      }

      // 出品した商品の取得
      try {
        const userId = match.params.user_id;

        updateLoading(true);

        Promise.all([
          getUserById(userId),
          getSalesForUserProfile(
            {},
            { limit: 6, page },
            { seller: [userId], closed: [0, 1, 2, 3] },
            ['-created_at']
          ),
          getUserFollows(userId),
          getUserFollowers(userId),
        ]).then(() => {
          updateUserProfilePageActive(numberPage);
        });

        window.scrollTo({ left: 0, top: 75, behavior: 'smooth' });
      } catch {
        //
      } finally {
        updateLoading(false);
      }
    },
  })
);

export default enhance;
