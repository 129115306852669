import makeQueryParam from './makeQueryParam';
import { MYPAGE } from '../../constants';

export default function paginationQueryBuilder(pagination) {
  const query = [];

  if (
    pagination.notice === MYPAGE.MESSAGE_TAB ||
    pagination.notice === MYPAGE.NOTIFICATION_TAB
  ) {
    query.push(makeQueryParam('notice', pagination.notice.toString()));
  }

  if (pagination.limit) {
    query.push(makeQueryParam('limit', pagination.limit.toString()));
  }

  if (pagination.page) {
    query.push(makeQueryParam('page', pagination.page.toString()));
  }

  return query;
}
