import * as React from 'react';
import enhance from './enhancer';
import { UserFollowerItem } from '../UserFollowerItem/';
import { Item } from 'semantic-ui-react';
import { replaceTofu } from '@/utils/string';

function UserFollowerList(props) {
  const { data, followUsers } = props;

  return (
    <div className="user-follow-list">
      <Item.Group divided>
        {data.map((item) => (
          <UserFollowerItem
            key={item.id}
            name={item.isCompany ? item.company : replaceTofu(item.displayName)}
            icon={item.avatarUrl}
            selfIntroduction={item.about}
            followed={followUsers.indexOf(item.userId) >= 0}
            followerId={item.userId}
          />
        ))}
      </Item.Group>
    </div>
  );
}

export default enhance((props) => <UserFollowerList {...props} />);
