import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Image } from 'semantic-ui-react';
import { assets } from '@/config';

function Page1() {
  return (
    <div className="app__introduction_sell">
      <div className="b__how_to">
        <div className="w__header_label">
          <h3>出品方法について</h3>
        </div>
        <div className="w__row w__first_row">
          <div className="w__image">
            <Image src={assets.sell_1} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">1.</h3>
              <h3 className="w__title">新規会員登録をしてください。</h3>
            </div>
            <span>
              Meat
              Saleをご利用いただくため、まずは会員登録（無料）をしてください。
            </span>
          </div>
        </div>
        <Icon color="yellow" name="caret down" size="huge" />
        <div className="w__row">
          <div className="w__image">
            <Image src={assets.sell_2} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">2.</h3>
              <h3 className="w__title">出品に必要な情報を登録してください。</h3>
            </div>
            <span>
              商品の出品には、売上げ受取口座およびクレジットカード・デビットカード情報の登録が必要です。
              売上げ受取口座の登録方法については
              <Link to="/introduction/account">&nbsp;こちら&nbsp;</Link>
              から。
            </span>
          </div>
        </div>
        <Icon color="yellow" name="caret down" size="huge" />
        <div className="w__row">
          <div className="w__image">
            <Image src={assets.sell_3} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">3.</h3>
              <h3 className="w__title">商品の写真を撮影してください。</h3>
            </div>
            <span>
              出品する商品を撮影してください。商品が写っていない写真はご使用いただけないので注意してください。
            </span>
          </div>
        </div>
        <Icon color="yellow" name="caret down" size="huge" />
        <div className="w__row">
          <div className="w__image">
            <Image src={assets.sell_4} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">4.</h3>
              <h3 className="w__title">商品の情報を入力してください。</h3>
            </div>
            <span>
              「出品する」メニューまたは出品ボタンから入力フォームを呼び出し、撮影した写真や商品をアピールする情報を入力していきます。
            </span>
          </div>
        </div>
        <Icon color="yellow" name="caret down" size="huge" />
        <div className="w__row bottom">
          <div className="w__image">
            <Image src={assets.sell_5} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">5.</h3>
              <h3 className="w__title">いよいよ出品完了です！</h3>
            </div>
            <span>
              フォームが完成したら、ページ下部の出品するボタンを押して出品完了です。
              <br />
              どんどんオークションに参加しましょう！
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page1;
