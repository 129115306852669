export default [
  {
    key: '0',
    value: '',
    text: '',
  },
  {
    key: '1',
    value: '1',
    text: '1',
  },
  {
    key: '2',
    value: '2',
    text: '2',
  },
  {
    key: '3',
    value: '3',
    text: '3',
  },
  {
    key: '4',
    value: '4',
    text: '4',
  },
  {
    key: '5',
    value: '5',
    text: '5',
  },
  {
    key: '6',
    value: '6',
    text: '6',
  },
  {
    key: '7',
    value: '7',
    text: '7',
  },
  {
    key: '8',
    value: '8',
    text: '8',
  },
  {
    key: '9',
    value: '9',
    text: '9',
  },
  {
    key: '10',
    value: '10',
    text: '10',
  },
  {
    key: '11',
    value: '11',
    text: '11',
  },
  {
    key: '12',
    value: '12',
    text: '12',
  },
];
