import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Item } from 'semantic-ui-react';
import enhance from './enhancer';
import { paths } from '@/config';
import { replaceTofu } from '@/utils/string';

function UserFollowItem(props) {
  const {
    icon,
    name,
    selfIntroduction,
    followed,
    followUserId,
    handleUpdateFollow,
    handleDeleteFollow,
    users: { mydata },
  } = props;

  return (
    <React.Fragment>
      <Item>
        <Item.Image size="tiny" src={icon} />
        <Item.Content className="self-introduction">
          <Item.Header>
            {mydata.id === followUserId ? (
              <Link to={paths.mypage.root}>{replaceTofu(name)}</Link>
            ) : (
              <Link to={`/users/${followUserId}`}>{replaceTofu(name)}</Link>
            )}
          </Item.Header>
          <Item.Description>
            <p>{selfIntroduction}</p>
          </Item.Description>
          {/* 画面サイズsm以下の時のボタン */}
          {window.isAuthed ? (
            mydata.id === followUserId ? null : followed ? (
              <Item.Content className="unfollow-button sm">
                <Button floated="right" onClick={handleDeleteFollow}>
                  フォロー中
                </Button>
              </Item.Content>
            ) : (
              <Item.Content className="follow-button sm">
                <Button floated="right" onClick={handleUpdateFollow}>
                  フォローする
                </Button>
              </Item.Content>
            )
          ) : null}
        </Item.Content>
        {/* 画面サイズsmより大きい時のボタン */}
        {window.isAuthed ? (
          mydata.id === followUserId ? null : followed ? (
            <Item.Content className="unfollow-button">
              <Button floated="right" onClick={handleDeleteFollow}>
                フォロー中
              </Button>
            </Item.Content>
          ) : (
            <Item.Content className="follow-button">
              <Button floated="right" onClick={handleUpdateFollow}>
                フォローする
              </Button>
            </Item.Content>
          )
        ) : null}
      </Item>
    </React.Fragment>
  );
}

export default enhance((props) => <UserFollowItem {...props} />);
