import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';

import paths from '../../../config/paths';

export const TabType = {
  ALL: 0,
  COMPANY: 1,
  PERSONAL: 2,
};

export const TAB_ITEMS = [
  { key: 0, name: 'すべて' },
  { key: 1, name: '業者出品を表示' },
  { key: 2, name: '個人出品を表示' },
];

const mapStateToProps = (state) => ({
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleTabClick: (props) => (targetKey) => {
      const { history } = props;

      switch (targetKey) {
        case TabType.ALL: {
          history.push(paths.fishes.all);
          break;
        }
        case TabType.COMPANY: {
          history.push(paths.fishes.company);
          break;
        }
        case TabType.PERSONAL: {
          history.push(paths.fishes.personal);
          break;
        }
      }
    },
  })
);

export default enhance;
