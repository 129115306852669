export default function buildCommentWithAddress(oldComment, myshippingdata) {
  const comment = oldComment ? `${oldComment}\n` : '';

  return (
    comment +
    `送り先名: ${myshippingdata.full_name || '-'}\n` +
    `郵便番号: ${myshippingdata.post_number || '-'}\n` +
    `住所: ${myshippingdata.address || '-'}\n` +
    `携帯電話番号: ${myshippingdata.phone_number || '-'}\n` +
    `配達指定時間: ${getDeliveryTime(myshippingdata.delivery_time || '-')}\n`
  );
}

const getDeliveryTime = (option) => {
  switch (option) {
    case 1: {
      return '指定しない';
    }
    case 2: {
      return '午前中';
    }
    case 3: {
      return '14～16時';
    }
    case 4: {
      return '16～18時';
    }
    case 5: {
      return '18～20時';
    }
    case 6: {
      return '19～21時';
    }
    default: {
      return '-';
    }
  }
};
