import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import notify from '@/migration/notification';
import { compose, setDisplayName, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  app: state.app.toJS(),
  users: state.users.toJS(),
  sales: state.sales.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, ...usersAction, notify }, dispatch);

const enhance = compose(
  setDisplayName('ProfileHeader'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidMount() {
      const { getMe, getMyFollows, getMyFollowers } = this.props;

      try {
        const me = await getMe();
        await getMyFollows(me.value.id);
        await getMyFollowers(me.value.id);
      } catch (error) {
        //
      }
    },
  })
);

export default enhance;
