import { Base64 } from 'js-base64';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import store from 'store';
import { confirmPassword as validate } from '@/validates';
import { usersAction } from '@/actions';
import { paths } from '@/config';
import { Auth } from '@/utils';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  app: state.app.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, push, notify }, dispatch);

const enhance = compose(
  setDisplayName('ConfirmPassword'),
  reduxForm({
    form: 'confirmPassword',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    resetPassword: (props) => async (immutableValues) => {
      const values = immutableValues.toJS();
      const { location, app, push, setIsLoading } = props;

      const urlSearchParams = new URLSearchParams(location.search);
      const username64based = urlSearchParams.getAll('username');
      const username =
        Base64.decode(username64based) ||
        app.tempEmail ||
        store.get('tempEmail');

      try {
        setIsLoading(true);

        await Auth.confirmForgotPassword(
          username,
          values.code,
          values.password
        );
        store.remove('tempEmail');
        push(paths.before.signin);
        notify('パスワードが更新されました。', 'info', undefined, 5);
      } catch (error) {
        setIsLoading(false);
        notify('認証コードを見直してください。', 'error', undefined, 5);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { getMe } = this.props;

      try {
        getMe();
      } catch (error) {
        console.error(error);
      }
    },
  })
);

export default enhance;
