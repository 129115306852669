import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';

import { bindActionCreators } from 'redux';
import { salesAction, usersAction, appAction } from '@/actions';
import { queryParams } from '@/utils';
import { paths } from '@/config';
import { unzipURL } from '../../../utils/sales';

const defaultSort = '-total_evaluation_count';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  form: state.form.toJS(),
  users: state.users.toJS(),
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...salesAction, ...usersAction, ...appAction, notify },
    dispatch
  );

const enhance = compose(
  setDisplayName('Fishes'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withState('isLoading', 'updateLoading', false),
  withState('showModal', 'updateShowModal', false),
  withState('isOpen', 'setOpen', false),

  withHandlers({
    /** ページネーション */
    handleChangePage: (props) => async (page) => {
      const { history, location } = props;
      const queryParam = queryParams.parse(location.search);
      const sort = queryParam.sort || defaultSort;
      const query = `page=${page}&sort=${sort}`;

      history.push(`${paths.fishes.company}?${query}`);
    },

    // 検索
    handleSearch: (props) => async (query) => {
      const { history, setOpen } = props;

      const str = queryParams.toString(query);

      let queryUrl = 'page=1';
      if (str) {
        queryUrl = `page=1&${str}`;
      }

      setOpen(false);
      history.push(`${paths.fishes.company}?${queryUrl}`);
    },

    // ソート
    handleSort: (props) => async (sortKey) => {
      const { history, setOpen } = props;

      const { params } = unzipURL(location.search, defaultSort);

      if (sortKey) params.sort = sortKey;
      // 1ページ目にする
      params.page = 1;

      const queryUrl = queryParams.toString(params);

      setOpen(false);
      history.push(`${paths.fishes.company}?${queryUrl}`);
    },

    // サイドメニューopen
    handleToggle: (props) => (open) => {
      const { setOpen } = props;

      setOpen(open);
    },
  }),

  lifecycle({
    async componentDidMount() {
      const { getSalesCompany, updateLoading, location } = this.props;

      try {
        updateLoading(true);
        const queryParam = queryParams.parse(location.search);

        const page = queryParam.page || '1';
        const sort = queryParam.sort || defaultSort;

        await getSalesCompany(page, sort);
      } catch (error) {
        console.error(error);
      } finally {
        updateLoading(false);
      }
    },

    async componentDidUpdate(prevProps) {
      const { getSalesCompany, updateLoading, location } = this.props;

      const { location: prevLocation } = prevProps;

      if (location.search !== prevLocation.search) {
        const queryParam = queryParams.parse(location.search);

        const page = queryParam.page || '1';
        const sort = queryParam.sort || defaultSort;

        try {
          updateLoading(true);
          await getSalesCompany(page, sort);

          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        } catch (error) {
          console.error(error);
        } finally {
          updateLoading(false);
        }
      }
    },
  })
);

export default enhance;
