import * as React from 'react';
import Slider from 'react-slick';
import { Image } from 'semantic-ui-react';

import ArrowButton from '../SliderArrowButton/SliderArrowButton';
import { assets } from '@/config';

function ImageSlider(props) {
  return (
    <div className="a__image-slider">
      {props.images.length !== 0 ? (
        <Slider
          dots={true}
          nextArrow={<ArrowButton image={assets.images.icon.arrow_right} />}
          prevArrow={<ArrowButton image={assets.images.icon.arrow_left} />}
        >
          {props.images.map((imageUrl) => {
            return (
              <div key={imageUrl}>
                <Image src={imageUrl} />
              </div>
            );
          })}
        </Slider>
      ) : null}
    </div>
  );
}

export default ImageSlider;
