import { base, getEnv } from '../../utils';

export const getShippingRelayCosts = async () => {
  const { SITE_TYPE } = getEnv();
  try {
    const url = `/v1/${SITE_TYPE}/shipping_relay_costs`;

    const response = await base.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
