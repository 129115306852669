import { createActions } from 'redux-actions';
import {
  postUser,
  getUserByID,
  putUserShop,
  getMe,
  getActivities,
  getUserShipping,
  getBuyersShipping,
  putUser,
  getUserCards,
  getUserShop,
  putUserCards,
  putUserBlame,
  postUserCard,
  getActivitiesUnreadCounts,
  putActivitiesToRead,
  putActivitiesToUnread,
  getUserReviewPagination,
  deleteUserCards,
  getFollowers,
  getFollowerPagination,
  getFollows,
  getFollowPagination,
  postFollow,
  deleteFollow,
  getNotReviewedItem,
} from '../../apis';

export default createActions({
  getMe: async (options) => getMe(options),

  getUserShipping: async (userId) => {
    const res = await getUserShipping(userId);

    return res;
  },

  getBuyersShipping: async (saleId) => getBuyersShipping(saleId),

  getUserReviewPagination: async (pagination, sort) => {
    const result = await getUserReviewPagination(pagination, sort);
    return Object.assign({}, result, pagination);
  },

  getNotReviewedItem: async () => {
    const result = await getNotReviewedItem();
    return result;
  },

  updateReviewPagesActive: (current) => ({ current }),

  putUser: async (userId, data) => putUser(userId, data),

  putUserCards: async (userId, stripeTokenId) =>
    putUserCards(userId, stripeTokenId),

  getUserCards: async (userId) => getUserCards(userId),

  getUserShop: async (userId) => getUserShop(userId),

  getUserById: async (userId) => getUserByID(userId),

  putUserShop: async (userId, oauth_token) => putUserShop(userId, oauth_token),

  postUserCard: async (userId, stripeTokenId) => {
    return postUserCard(userId, stripeTokenId);
  },

  deleteUserCards: async (userId) => {
    return deleteUserCards(userId);
  },

  postUser: async (data) => postUser(data),

  getActivities: async (userId, pagination) => {
    const result = await getActivities(userId, pagination);
    return Object.assign({}, result, pagination);
  },

  updateActivitiesPageActive: (current) => ({ current }),

  putUserBlame: async (userId, data) => putUserBlame(userId, data),

  getActivitiesUnreadCounts: async (userId) =>
    getActivitiesUnreadCounts(userId),

  putActivitiesToRead: async (userId, data) => {
    const updatedCount = await putActivitiesToRead(userId, data);
    const unReadCount = await getActivitiesUnreadCounts(userId);
    const notificationCount = { ...updatedCount, ...unReadCount };

    return { notificationCount };
  },

  putActivitiesToUnread: async (userId, data) => {
    const updatedCount = await putActivitiesToUnread(userId, data);
    const unReadCount = await getActivitiesUnreadCounts(userId);
    const notificationCount = { ...updatedCount, ...unReadCount };

    return { notificationCount };
  },

  getMyFollowers: async (userId) => getFollowers(userId),

  getMyFollows: async (userId) => getFollows(userId),

  getUserFollowers: async (userId) => getFollowers(userId),

  getUserFollows: async (userId) => getFollows(userId),

  getFollowerPagination: async (pagination, sort) => {
    const result = await getFollowerPagination(pagination, sort);
    return Object.assign({}, result, pagination);
  },

  updateFollowerPagesActive: (current) => ({ current }),

  getFollowPagination: async (pagination, sort) => {
    const result = await getFollowPagination(pagination, sort);
    return Object.assign({}, result, pagination);
  },

  updateFollowPagesActive: (current) => ({ current }),

  postFollow: async (userId, followId) => {
    await postFollow(userId, followId);
    return getFollows(userId);
  },

  deleteFollow: async (userId, followId) => {
    deleteFollow(userId, followId);
    return followId;
  },
});
