import storage from 'store';

const key = 'reviewNotification';

export const getYetReviewItem = (notReviewedItems) => {
  const store = storage.get(key);

  if (!store) {
    return notReviewedItems[0];
  }

  return notReviewedItems.find((item) => {
    const id = String(item.id);
    if (!store.includes(id)) {
      return item;
    }
    return undefined;
  });
};

export const getNotReviewItemFindById = (notReviewedId) => {
  const store = storage.get(key);

  if (!store) {
    return true;
  }

  const getId = store.find((id) => {
    const parseId = Number(id);
    return parseId === notReviewedId;
  });

  return !getId;
};

/** レビューしていないものを保存する */
export const setNotReviewItem = (notReviewedId) => {
  if (!notReviewedId) {
    return;
  }

  const store = storage.get(key);
  if (store) {
    store.push(notReviewedId);
    storage.set(key, store);
    return;
  }

  const newStorage = [notReviewedId];
  storage.set(key, newStorage);
};
