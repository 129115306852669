export default {
  background: {
    signin_bg_image: 'https://meatsale.jp/assets/signin-bg-image.jpg',
    top_hero_pc: 'https://meatsale.jp/assets/hero-top-pc.jpg',
    top_hero_mobile: 'https://meatsale.jp/assets/hero_top_mobile.jpg',
  },
  logo: {
    twitter:
      'https://meatsale.jp/assets/Twitter_Social_Icon_Rounded_Square_Color.png',
    line: 'https://meatsale.jp/assets/LINE_Icon.png',
    facebook: 'https://meatsale.jp/assets/FB-f-Logo__blue_50.png',
  },
  icon: {
    social: {},
    mail: 'https://meatsale.jp/assets/mail.png',
    arrow_left: 'https://meatsale.jp/assets/arrow-left.svg',
    arrow_right: 'https://meatsale.jp/assets/arrow-right.svg',
  },
  creditCard: {
    security:
      'https://meatsale.jp/assets/b8017c7ee623fb4ad63f2b35d1855dee1.png',
  },
};
