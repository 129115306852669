export const ConversionMeatCategory = (
  value: any,
  lang: 'ja' | 'en' = 'en'
) => {
  if (typeof value !== 'string') return;

  if (lang === 'en') {
    switch (value) {
      case '牛':
        return 'beef';
      case '豚':
        return 'pork';
      case '鶏':
        return 'chicken';
      case '馬':
        return 'horsemeat';
      case '羊':
        return 'lamb';
      case 'ジビエ':
        return 'game';
      default:
        return;
    }
  } else if (lang === 'ja') {
    switch (value) {
      case 'beef':
        return '牛';
      case 'pork':
        return '豚';
      case 'chicken':
        return '鶏';
      case 'horsemeat':
        return '馬';
      case 'lamb':
        return '羊';
      case 'game':
        return 'ジビエ';
      default:
        return;
    }
  } else {
    return;
  }
};
