import React from 'react';
import { Route } from 'react-router-dom';

import { paths } from '@/config';
import { Fee } from '@/components/Pages';

const FeeRoutes = () => {
  return (
    <>
      <Route component={Fee} path={paths.common.fee} exact />
    </>
  );
};

export default FeeRoutes;
