import { Auth, getEnv } from '@/utils';
import { base } from '../../utils';

export const deleteFollow = async (userId, followId) => {
  const token = await Auth.getIdToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const { SITE_TYPE } = getEnv();
    const url = `/v1/${SITE_TYPE}/users/${userId}/follows/${followId}`;

    await base.delete(url, { headers });

    return;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
