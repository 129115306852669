const cultivationMethod = [
  {
    key: '1',
    value: 1,
    name: 'cultivation_method1',
    text: '露地',
  },
  {
    key: '2',
    value: 2,
    name: 'cultivation_method2',
    text: 'ハウス(促成)',
  },
  {
    key: '3',
    value: 3,
    name: 'cultivation_method3',
    text: '抑制',
  },
  {
    key: '4',
    value: 4,
    name: 'cultivation_method4',
    text: '水耕',
  },
  {
    key: '5',
    value: 5,
    name: 'cultivation_method5',
    text: '半',
  },
  {
    key: '6',
    value: 6,
    name: 'cultivation_method6',
    text: '根域制限',
  },
];

export const cultivationMethodNames = cultivationMethod.map(({ name }) => name);

export default cultivationMethod;
