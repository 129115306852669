/**
 * Deprecated
 * Google Tag Managerを使用して測定を行うため、不要になるかも
 */
const IS_PRD = process.env.REACT_APP_ENV_CODE === 'prd';

/**
 * page_viewイベントをGoogle Analyticsに送信する
 * https://developers.google.com/gtagjs/reference/event?hl=ja#page_view
 * */
export const trackingPageView = (pagePath: string) => {
  if (!window.gtag || !IS_PRD) return;

  window.gtag('event', 'page_view', {
    page_path: pagePath,
  });
};

/**
 * searchイベントをGoogle Analyticsに送信する
 * https://developers.google.com/gtagjs/reference/event?hl=ja#search
 */
export const trackingSearchWord = (searchWord: string) => {
  if (!window.dataLayer || !IS_PRD) return;

  window.dataLayer.push({
    event: 'search',
    value: searchWord,
  });
};

/**
 * sign_upイベントをGoogle Analyticsに送信する
 * 何で登録されたかを送信する
 * https://developers.google.com/gtagjs/reference/event?hl=ja#sign_up
 */
export const trackingSignUp = (method: string) => {
  if (!window.dataLayer || !IS_PRD) return;

  window.dataLayer.push({
    event: 'sign_up',
    value: method,
  });
};
