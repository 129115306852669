export default function resetEmailModal(values) {
  const errors = {};

  // const {oldEmail, newEmail, confirmEmail} = values.toJS();
  const oldEmail = values.get('oldEmail');
  const newEmail = values.get('newEmail');
  const confirmEmail = values.get('confirmEmail');

  // 現在のメールアドレス
  if (!oldEmail) {
    errors.oldEmail = 'メールアドレスを入力してください';
  } else if (oldEmail) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(oldEmail.trim())) {
      errors.oldEmail = 'メールアドレスが正しい形式ではありません';
    }
  }

  // 新メールアドレス
  if (!newEmail) {
    errors.newEmail = 'メールアドレスを入力してください';
  } else if (newEmail) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail.trim())) {
      errors.newEmail = 'メールアドレスが正しい形式ではありません';
    }
  }

  // 新メールアドレス確認用
  if (!confirmEmail) {
    errors.confirmEmail = '確認用のメールアドレスを入力してください';
  } else if (newEmail) {
    if (newEmail != confirmEmail) {
      errors.confirmEmail = 'メールアドレスと一致していません。';
    }
  }

  return errors;
}
