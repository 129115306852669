// eslint-disable @typescript-eslint/camelcase

import isEmpty from 'lodash/isEmpty';

import * as indexedDB from './indexedDb';
import { paths } from '@/config';
import { getNews } from '@/apis';

const tableName = 'notification';

/** 通知を取得する */
export const getLocalNews = async () => {
  try {
    const db = await indexedDB.open();

    return await db[tableName].toArray();
  } catch (error) {
    throw new Error(error);
  }
};

/** 通知を初めて取得する */
export const initialNews = async (notifications) => {
  try {
    if (!isEmpty(notifications)) {
      const params = notifications.map(
        (n) =>
          (n = {
            ...n,
            is_disclosed: isTrue(n.is_disclosed),
            is_readable: isTrue(false),
          })
      );

      await indexedDB.bundleUpdate(tableName, params);
    }
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * APIから取得した通知とローカルに保存してある通知を比較し、
 * APIに含まれていないローカルの通知を削除する
 */
export const deleteLocal = async (news) => {
  try {
    if (isEmpty(news)) {
      await indexedDB.bundleRemove(tableName);
      return;
    }

    const localNews = await getLocalNews();
    localNews.forEach(async (n) => {
      const found = news.find((f) => f.id === n.id);
      if (!found) {
        await indexedDB.remove(tableName, n);
      }
    });
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * 差分更新
 */
export const diffUpdate = async (news) => {
  try {
    if (isEmpty(news)) {
      return;
    }

    const local = await getLocalNews();
    const _news = news.map(
      (n) =>
        (n = {
          ...n,
          is_disclosed: isTrue(n.is_disclosed),
          is_readable: isTrue(false),
        })
    );

    _news.forEach(async (n) => {
      const found = local.find(
        (f) => f.id === n.id && f.body_text !== n.body_text
      );

      if (found) {
        const params = {
          ...n,
          is_disclosed: isTrue(found.is_disclosed),
          is_readable: isTrue(false),
        };
        await indexedDB.update(tableName, params);
      }
    });
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * 差分追加
 */
export const diffInsert = async (news) => {
  try {
    if (isEmpty(news)) {
      return;
    }

    const local = await getLocalNews();
    const _news = news.map(
      (n) =>
        (n = {
          ...n,
          is_disclosed: isTrue(n.is_disclosed),
          is_readable: isTrue(false),
        })
    );

    _news.forEach(async (n) => {
      const found = await local.find((f) => f.id === n.id);
      if (!found) {
        const params = {
          ...n,
          is_disclosed: isTrue(n.is_disclosed),
          is_readable: isTrue(n.is_readable),
        };
        await indexedDB.update(tableName, params);
      }
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** 通知を既読済みにする */
export const readNews = async (id) => {
  const localNews = await getNews();

  const changeNews = localNews.find((n) => n.id === id);

  if (changeNews) {
    changeNews.is_readable = 1;
    const noChangeNews = localNews.filter((n) => n.id !== id);
    noChangeNews.push(changeNews);

    noChangeNews.forEach(async (n) => {
      await indexedDB.update(tableName, n);
    });
  }
};

/** 表示する通知を取得 */
export const getDisplayNews = async () => {
  try {
    const db = await indexedDB.open();

    return await db[tableName].where('is_readable').equals(0).toArray();
  } catch (error) {
    throw new Error(error);
  }
};

export const allowNotifyPath = (path) => {
  const forbidPath = [
    paths.before.signup,
    paths.before.signUpThank,
    paths.before.signup_confirm,
    paths.before.signin,
    paths.before.resetPassword,
    paths.before.confirmPassword,
  ];

  if (forbidPath.includes(path)) {
    return false;
  }
  return true;
};

const isTrue = (bool) => {
  if (bool) {
    return 1;
  } else {
    return 0;
  }
};
