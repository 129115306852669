import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { TitleBar } from '@/components/Common';
import enhance from './enhance';
import { paths } from '@/config';
import { Link } from 'react-router-dom';

import './styles.scss';

function BankAccount(props) {
  const { handleConnectStripe, isLoading, users } = props;
  const { isSeller } = users.mydata;

  return (
    <div>
      <TitleBar title="売上げ受取方法" />
      <section className="app__bank-account">
        <span className="payments flex">
          <section className="payments_container">
            <div className="sell_fish">
              <div className="payment_wrapper">
                {isSeller ? (
                  <div className="already_bank">
                    <p>
                      売上げ受取口座は下記からご確認頂けます
                      <span className="bank_account_explain_link">
                        <br />
                        <span>【重要】</span>
                        売上げ受取口座と入金スケジュールの変更方法については
                        <Link
                          to={paths.introduction.change_sales_account}
                          target="_blank"
                        >
                          こちら
                        </Link>
                      </span>
                    </p>
                    <div className="already_bank_link">
                      <a
                        rel="noopener noreferrer"
                        target="_Blank"
                        href="https://dashboard.stripe.com/account/payouts"
                      >
                        <Button className="payment_button">
                          <span>売上げ受取口座を確認する</span>
                          <br />
                          <span>（ Stripe に移動します ）</span>
                        </Button>
                      </a>
                    </div>
                    <div className="bank_account_explain_link">
                      <Link
                        to={paths.introduction.sales_account}
                        target="_blank"
                      >
                        Stripe について
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="b__unregistered">
                    <p className="description">
                      <div className="bank_account_explain_link">
                        <span>【重要】</span>
                        売上げ受取口座登録の詳しい説明は
                        <Link
                          to={paths.introduction.sales_account}
                          target="_blank"
                        >
                          こちら
                        </Link>
                      </div>
                      <span className="important">
                        正しく登録ができていないため、売上金が入金されない事例が多発しています。
                        <br />
                        必ず
                        <Link
                          to={paths.introduction.howto_sales_account}
                          target="_blank"
                        >
                          ガイド
                        </Link>
                        に従って登録を行ってください。
                      </span>
                      売上げ受取口座が登録されていません
                    </p>

                    <Button
                      className="payment_button"
                      onClick={handleConnectStripe}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      <span>売上げ受取口座を登録する</span>
                      <br />
                      <span>（外部サイトに移動します）</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </span>
      </section>
    </div>
  );
}

export default enhance((props) => <BankAccount {...props} />);
