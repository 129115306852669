import { connect } from 'react-redux';
import { salesAction, usersAction } from '@/actions';
import { compose, setDisplayName, lifecycle } from 'recompose';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => ({
  router: state.router,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, ...salesAction, ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('MyPageRoutes'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidMount() {
      const { getOwnExhibitsCounts, getMe } = this.props;

      const me = await getMe();
      getOwnExhibitsCounts(
        { limit: 1, page: 1 },
        { seller: [`${me.value.id}`] },
        ['-created_at']
      );
    },
  })
);

export default enhance;
