import moment from 'moment';

import { constants } from '../../constants';

const { FISHING_YEAR } = constants;
const year = moment().format('YYYY');

// 今年分と過去2年分を表示
export default [
  {
    key: '0',
    value: '',
    text: '',
  },
  {
    key: '1',
    value: year,
    text: year,
  },
  {
    key: '2',
    value: `${year - FISHING_YEAR.ONE_YEAR_BEFORE}`,
    text: `${year - FISHING_YEAR.ONE_YEAR_BEFORE}`,
  },
  {
    key: '3',
    value: `${year - FISHING_YEAR.TWO_YEAR_BEFORE}`,
    text: `${year - FISHING_YEAR.TWO_YEAR_BEFORE}`,
  },
];
