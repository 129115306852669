import * as React from 'react';
import enhance from './enhance';
import { Button } from 'semantic-ui-react';

function ModalBody(props) {
  const {
    isModal,
    updateShowModal,
    handleSubmit,
    leaveAccount,
    isLoading,
  } = props;

  return (
    <div className="w__leave-modal">
      <div className="w__main">
        <p className="w__caption">退会しますか？</p>
        <div className="w__form">
          <div className="w__button">
            <Button
              className="cancel"
              disabled={isLoading}
              onClick={() => {
                updateShowModal(!isModal);
              }}
            >
              キャンセル
            </Button>
            <Button className="leave" onClick={handleSubmit(leaveAccount)}>
              退会する
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <ModalBody {...props} />);
