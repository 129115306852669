import React, { useEffect } from 'react';
import { cookieConfig } from './cookieConfig';

const TrafficProvider: React.FC = ({ children }) => {
  useEffect(() => {
    if (
      !document.cookie
        .split(';')
        .some((item) => item.trim().startsWith(`${cookieConfig.cookie_name}=`))
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const campaign_source = searchParams.get(`utm_source`);
      const campaign_medium = searchParams.get(`utm_medium`);
      const campaign_name = searchParams.get(`utm_campaign`);

      const cookieData = JSON.stringify({
        campaign_source: campaign_source,
        campaign_medium: campaign_medium,
        campaign_name: campaign_name,
      });

      const expire = new Date();

      if (cookieConfig.cookie_expires > 0) {
        expire.setDate(expire.getDate() + cookieConfig.cookie_expires);
      }

      document.cookie = `${cookieConfig.cookie_name}=${cookieData}${
        cookieConfig.cookie_domain ? `;expires=${expire.toUTCString()}` : ''
      }${
        cookieConfig.cookie_domain
          ? `;domain=${cookieConfig.cookie_domain};path=${cookieConfig.cookie_path}`
          : ''
      }`;
    }
  }, []);

  return <>{children}</>;
};

export default TrafficProvider;
