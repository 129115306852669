export default function myPageEdit(values) {
  const errors = {};

  const introduction = values.get('introduction');
  const displayName = values.get('displayName');
  const companyName = values.get('companyName');
  const companyAddress = values.get('companyAddress');
  const fishingBoat = values.get('fishingBoat');
  const isCompany = values.get('isCompany');
  const shippingProvider = values.get('shippingProvider');
  const confirm_address = values.get('confirm_address');

  if (introduction && introduction.length > 1000) {
    // 自己紹介
    errors.introduction = '自己紹介は1,000文字以内で入力してください';
  }

  if (!displayName) {
    // ニックネーム
    errors.displayName = 'ニックネームを入力してください';
  } else if (displayName && displayName.length > 20) {
    errors.displayName = 'ニックネームは20文字以内で入力してください';
  }

  if (!companyName) {
    // 会社名
    errors.companyName = '正しい事業者名を入力してください';
  } else if (companyName && companyName.length > 20) {
    errors.companyName = '正しい事業者名は20文字以内で入力してください';
  }

  if (!companyAddress) {
    // 会社住所詳細
    errors.companyAddress = '住所を入力してください';
  } else if (companyAddress && companyAddress.length > 50) {
    errors.companyAddress = '住所は50文字以内で入力してください';
  }

  if (fishingBoat && fishingBoat.length > 20) {
    // よく利用する釣船
    errors.fishingBoat = 'よく利用する釣船は20文字以内で入力してください';
  }

  if (isCompany && !shippingProvider) {
    // 使用配送業者
    errors.shippingProvider = '使用配送業者を入力してください';
  }

  const myshippingdata = values.get('myshippingdata');

  if (myshippingdata) {
    const myshippingdataArrayErrors = [];

    myshippingdata.forEach((v, index) => {
      const myshippingdataErrors = {};
      const fullName = v.get('full_name');
      const postNumber = v.get('post_number');
      const addressAfter = v.get('addressAfter');
      const phoneNumber = v.get('phone_number');
      const deliveryTime = v.get('delivery_time');
      const topPostNumber = v.get('top_post_number');
      const bottomPostNumber = v.get('bottom_post_number');

      if (!fullName) {
        myshippingdataErrors.full_name = '送付先名を入力してください';
        myshippingdataArrayErrors[index] = myshippingdataErrors;
      }

      if (postNumber !== topPostNumber + '-' + bottomPostNumber) {
        myshippingdataErrors.post_number =
          '郵便番号は、数字3桁 -(ハイフン) 数字4桁の形式で入力し、住所検索ボタンを押してください';
        myshippingdataArrayErrors[index] = myshippingdataErrors;
      } else if (
        (!postNumber && topPostNumber) ||
        (!postNumber && bottomPostNumber)
      ) {
        myshippingdataErrors.post_number =
          '郵便番号は、数字3桁 -(ハイフン) 数字4桁の形式で入力してください。';
      } else if (!postNumber) {
        myshippingdataErrors.post_number = '郵便番号を入力してください';
        myshippingdataArrayErrors[index] = myshippingdataErrors;
      }

      if (!addressAfter) {
        myshippingdataErrors.addressAfter = '住所を入力してください';
        myshippingdataArrayErrors[index] = myshippingdataErrors;
      }
      if (!/^\d{8,11}$/.test(phoneNumber)) {
        myshippingdataErrors.phone_number = '携帯電話番号を入力してください';
        myshippingdataArrayErrors[index] = myshippingdataErrors;
      }

      if (!phoneNumber) {
        myshippingdataErrors.phone_number = '携帯電話番号を入力してください';
        myshippingdataArrayErrors[index] = myshippingdataErrors;
      }

      if (!deliveryTime) {
        myshippingdataErrors.delivery_time = '配達指定時間を入力してください';
        myshippingdataArrayErrors[index] = myshippingdataErrors;
      }
    });

    if (myshippingdataArrayErrors.length) {
      errors.myshippingdata = myshippingdataArrayErrors;
    }

    if (!confirm_address) {
      errors.confirm_address = 'チェックを入れてください';
    }
  }

  return errors;
}
