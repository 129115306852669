import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { ResetPassword } from '@/components/Pages';
import enhance from './enhancer';

function ResetPasswordRoutes() {
  return (
    <Route strict path={paths.before.resetPassword} component={ResetPassword} />
  );
}

export default enhance((props) => <ResetPasswordRoutes {...props} />);
