import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import promiseMiddleware from 'redux-promise-middleware';
import reducers from '../reducers';
import routingMiddleware from './routingMiddleware';
import { history } from '../config';

const middleware = [
  thunkMiddleware,
  promiseMiddleware,
  routerMiddleware(history),
  routingMiddleware,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  connectRouter(history)(reducers),
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
