import React from 'react';
import {
  MEAT_CATEGORY_TYPE,
  MEAT_ITEMS,
  RAW_MESSAGE,
  RAW_MESSAGE_GAME,
  RAW_MESSAGE_PORK,
} from '../config';
import { FormLabelIcon } from '@/components/Common';
import { Field, Fields } from 'redux-form/immutable';
import {
  FieldCheckbox,
  FieldDropdown,
  FieldInput,
  FieldSaleDate,
} from '@/components/Form';
import { IsShow } from '../utils';

interface Props {
  disabled?: boolean;
  category?: MEAT_CATEGORY_TYPE;
  formValues: Record<string, any>;
}

const inputs = ({ disabled: disabled, category, formValues }: Props) => {
  return (
    <>
      {Object.keys(MEAT_ITEMS).map((key, i) => {
        const d = MEAT_ITEMS[key];
        const name = key;

        // 表示するかどうか
        if (!category || !IsShow(category, formValues, d)) {
          return <React.Fragment key={i}></React.Fragment>;
        }

        // 必須かどうか
        const isRequired = (() => {
          if (d.linkRequired) {
            const data = formValues[d.linkRequired.name];

            if (
              data &&
              (d.linkRequired.value === true || d.linkRequired.value === data)
            ) {
              return true;
            }
          }

          return d.required;
        })();

        // disabledかどうか
        const isDisabled = (() => {
          if (disabled) return true;

          if (d.linkDisabled) {
            const data = formValues[d.linkDisabled.name];

            if (
              data &&
              (d.linkDisabled.value === true || d.linkDisabled.value === data)
            ) {
              return true;
            }
          }

          return disabled;
        })();

        const disableHelper = (() => {
          if (d.linkDisabled) {
            const data = formValues[d.linkDisabled.name];

            if (
              data &&
              (d.linkDisabled.value === true || d.linkDisabled.value === data)
            ) {
              return d.linkDisabled.helper ?? '';
            }
          }
        })();

        // セレクト・チェックボックス用のデータ配列
        const values = d.select
          ? d.select
              .filter((f) =>
                f.show && category ? f.show.includes(category) : true
              )
              .flatMap((item) =>
                item.values.map((e) => {
                  const value = typeof e === 'string' ? e : e.value;
                  const text =
                    typeof e === 'string' ? e : e.label ? e.label : e.value;

                  return {
                    ...{
                      value,
                      text,
                    },
                  };
                })
              )
          : [];

        // ラベル
        const label = d.unit ? { basic: true, content: d.unit } : undefined;

        const placeholder = d.placeholder;

        return (
          <div
            className="field_wrapper"
            key={i}
            style={{ marginTop: d.label ? undefined : -14 }}
          >
            {/* 真偽はラベル非表示 */}
            {d.label && (
              <label className="flex">
                {/* ラベル */}
                {(() => {
                  if (category && d.labelPattern) {
                    const l = d.labelPattern[category];
                    if (l) return l;
                  }

                  return d.label;
                })()}

                {/* 文字数制限 - 文字列 */}
                {((d.validate &&
                  d.validate.max &&
                  (d.type === 'string' || !d.type)) ||
                  ((d.type === 'string' || !d.type) && !d.select)) && (
                  <span className="word_count">
                    {`(最大${d.validate?.max ?? `50`}文字)`}
                  </span>
                )}

                {/* 文字数制限 - 数値 */}
                {((d.validate && d.validate.max && d.type === 'int') ||
                  d.type === 'int') && (
                  <span className="word_count">
                    {`(最大${(d.validate?.max ?? 10_000_000).toLocaleString()}${
                      d.unit ?? 'g'
                    })`}
                  </span>
                )}

                {/* Required */}
                {isRequired ? (
                  <FormLabelIcon value="必須" />
                ) : (
                  <FormLabelIcon value="任意" optional />
                )}
              </label>
            )}
            {d.type === 'string' || !d.type ? (
              // セレクト
              d.select ? (
                (() => {
                  const options = values;

                  if (!isRequired) {
                    options.unshift({
                      value: '',
                      text: '未選択',
                    });
                  }

                  return (
                    <Field
                      component={FieldDropdown}
                      selection
                      fluid
                      placeholder="選択してください"
                      labelPosition={label ? 'right' : undefined}
                      {...{ name, disabled: isDisabled, options, label }}
                    />
                  );
                })()
              ) : (
                // テキストボックス
                <>
                  <Field
                    type="text"
                    component={FieldInput}
                    maxLength={50}
                    labelPosition={label ? 'right' : undefined}
                    {...{ name, disabled: isDisabled, label, placeholder }}
                  />
                  {!d.label && (
                    <span className="word_count_under">(最大50文字)</span>
                  )}
                </>
              )
            ) : d.type === 'bool' ? (
              // チェック
              <Field
                component={FieldCheckbox}
                label={d.checkboxLabel ?? d.label}
                {...{ name }}
              />
            ) : // チェックボックス
            d.type === 'checkbox' ? (
              (() => {
                if (values.length === 0) return null;
                return (
                  <div className="stack_checkbox">
                    {values.map(({ text }, i) => (
                      <Field
                        name={`${name}[${i}]`}
                        component={FieldCheckbox}
                        label={text}
                        key={i}
                      />
                    ))}
                  </div>
                );
              })()
            ) : d.type === 'date' ? (
              <div className="w__date-wrapper">
                <Fields
                  names={[name, `${name}_year`, `${name}_month`, `${name}_day`]}
                  component={FieldSaleDate}
                  range={d.dateRange}
                  {...{ disabled: isDisabled }}
                />
              </div>
            ) : (
              // その他
              <>
                <Field
                  type="text"
                  component={FieldInput}
                  maxLength={50}
                  labelPosition={label ? 'right' : undefined}
                  {...{ name, disabled: isDisabled, label, placeholder }}
                />
                {!d.label && (
                  <span className="word_count_under">(最大50文字)</span>
                )}
              </>
            )}
            {disableHelper && (
              <label className="flex attention" style={{ color: '#f55' }}>
                {disableHelper}
              </label>
            )}
            {d.helperText && (
              <label className="flex attention">{d.helperText}</label>
            )}
          </div>
        );
      })}
      {/* 生食の場合は自動挿入 */}
      {formValues['purpose'] === '生食用' &&
        category !== 'pork' &&
        category !== 'game' && (
          <div className="field_wrapper" style={{ marginTop: -14 }}>
            <div className="field-group">
              <textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  border: 'solid 1px #ccc',
                  borderRadius: '5px',
                  padding: '11px 16px',
                }}
                disabled
              >
                {RAW_MESSAGE}
              </textarea>
            </div>
          </div>
        )}

      {/* 豚肉の注意事項 */}
      {category === 'pork' && (
        <div className="field_wrapper" style={{ marginTop: -14 }}>
          <div className="field-group">
            <textarea
              style={{
                width: '100%',
                resize: 'none',
                border: 'solid 1px #ccc',
                borderRadius: '5px',
                padding: '11px 16px',
              }}
              disabled
            >
              {RAW_MESSAGE_PORK}
            </textarea>
          </div>
        </div>
      )}

      {/* ジビエの注意事項 */}
      {category === 'game' && (
        <div className="field_wrapper" style={{ marginTop: -14 }}>
          <div className="field-group">
            <textarea
              style={{
                width: '100%',
                resize: 'none',
                border: 'solid 1px #ccc',
                borderRadius: '5px',
                padding: '11px 16px',
              }}
              disabled
            >
              {RAW_MESSAGE_GAME}
            </textarea>
          </div>
        </div>
      )}
    </>
  );
};

export default inputs;
