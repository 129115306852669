import $ from 'jquery';
import { compose, lifecycle, setDisplayName } from 'recompose';

import { paths } from '@/config';
import { connect } from 'react-redux';
import { appAction } from '@/actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => ({
  router: state.router,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...appAction }, dispatch);

const enhance = compose(
  setDisplayName('Routes'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      $(window.document).on('scroll', (element) => {
        const config = {
          left: $(element.target).scrollLeft(),
          top: $(element.target).scrollTop(),
          behavior: 'instant',
        };

        const isFishPage = window.location.pathname === paths.fishes.root;
        isFishPage && window.handleScrollForPageFishes.next(config);
      });
    },
  })
);

export default enhance;
