import { connect } from 'react-redux';
import { replace, push } from 'connected-react-router';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { usersAction } from '@/actions';
import { paths } from '@/config';
import { socialSignup as validate } from '@/validates';
import { findImageUrl } from '@/utils';
import { errorNotification, parseJwt } from './utils';

import { Auth } from '@/utils';

const mapStateToProps = (state) => ({
  users: state.app.toJS(),
  form: state.form.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, replace, push }, dispatch);

const enhance = compose(
  setDisplayName('CognitoCallback'),
  reduxForm({
    form: 'SocialSignupForm',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isCallBack', 'updateCallBack', true),
  withState('isLoading', 'updateLoading', false),
  withState('recapchaResult', 'updateRecapchaResult', undefined),
  withState('displayRecapchaError', 'updateDisplayRecapchaError', false),
  withHandlers({
    submitSocialSignUp: (props) => async (immutableValues) => {
      const {
        email,
        displayName,
        thumnailImageUrl,
        prefecture,
      } = immutableValues.toJS();
      const {
        updateLoading,
        updateDisplayRecapchaError,
        postUser,
        push,
        recapchaResult,
        getMe,
      } = props;

      updateLoading(true);

      if (!recapchaResult) {
        // recapchaで不正解のとき処理を辞める。
        updateLoading(false);
        updateDisplayRecapchaError(true);
        return;
      }

      try {
        await postUser({ email, displayName, thumnailImageUrl, prefecture });
        await getMe();
        // 遷移前にisAuthedを変更するとAfterRoutesに変わってしまう。
        // BeforeRoutesで動作させるための順序です。

        window.isAuthed = false;
        push(paths.before.signUpThank);
      } catch (error) {
        updateLoading(false);
      }
    },
    submitRecapcha: (props) => (token) => {
      const { updateDisplayRecapchaError, updateRecapchaResult } = props;

      updateRecapchaResult(token);
      updateDisplayRecapchaError(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        location,
        notify,
        initialize,
        updateCallBack,
        getMe,
        replace,
      } = this.props;

      const urlSearchParams = new URLSearchParams(location.search);
      const isError = urlSearchParams.has('error_description');

      if (isError) {
        notify(errorNotification);
        replace(paths.landing);
        return;
      }

      try {
        await Auth.checkSession();
        await Auth.signInSns();
        await getMe();

        replace(paths.landing);
      } catch (error) {
        const jwt = await Auth.getIdToken();
        const jwtParsed = parseJwt(jwt);
        initialize({
          displayName: jwtParsed.name,
          email: jwtParsed.email,
          thumnailImageUrl: findImageUrl(jwtParsed.picture),
        });
        updateCallBack(false);
      }
    },
  })
);

export default enhance;
