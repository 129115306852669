import { sls } from '../../utils';

const leaveSendMail = async (params) => {
  try {
    const { data } = await sls.post('/leavemail', params);

    return data;
  } catch (error) {
    return error;
  }
};

export default leaveSendMail;
