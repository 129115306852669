import React from 'react';
import { Route, Switch } from 'react-router';

import { SellButton } from '@/components/Common';
import {
  CognitoCallbackRoutes,
  ConfirmResetPasswordRoutes,
  ContactRoutes,
  FishesRoutes,
  IntroductionRoutes,
  LandingRoutes,
  MyPageRoutes,
  NotFoundRoutes,
  ResetPasswordRoutes,
  SaleRoutes,
  SellRoutes,
  StripeCallbackRoutes,
  UserProfileRoutes,
  UserFollowRoutes,
  SignUpThankRoutes,
  ChangeEmailRoutes,
  FeeRoutes,
  MediaPublicationRoutes,
  RedirectRoutes,
} from '@/components/Routes/Children';
import { BeforeRoutes } from '../BeforeRoutes';
import { paths } from '@/config';
import { MatchPathRedirect } from '../MatchPathRedirect';
import enhance from './enhancer';
import { Footer, Header } from '@/components/Layouts';

function Routes(props) {
  const { router } = props;

  const location = router.location;

  if (!window.isAuthed) {
    return <BeforeRoutes />;
  }

  return (
    <div className="a__after_routes">
      {window.location.pathname !== paths.common.apology ? <Header /> : null}
      <Switch location={location}>
        <Route
          strict
          path={paths.cognitocallback}
          component={CognitoCallbackRoutes}
        />

        <Route exact path={paths.fishes.all} component={FishesRoutes} />
        <Route exact path={paths.fishes.company} component={FishesRoutes} />
        <Route exact path={paths.fishes.personal} component={FishesRoutes} />

        <Route path={paths.common.confirmEmail} component={ChangeEmailRoutes} />

        <Route
          exact
          path={paths.before.confirmPassword}
          component={ConfirmResetPasswordRoutes}
        />

        <Route strict path={paths.common.contact} component={ContactRoutes} />

        <Route exact path={paths.fishes.root} redirectUrl={paths.fishes.all} />

        <Route
          strict
          path={paths.introduction.root}
          component={IntroductionRoutes}
        />

        <Route exact path={paths.landing} component={LandingRoutes} />

        <Route strict path={paths.mypage.root} component={MyPageRoutes} />

        <Route
          exact
          path={paths.before.resetPassword}
          component={ResetPasswordRoutes}
        />

        <Route strict path={paths.fishes.saleItem} component={SaleRoutes} />

        <Route strict path={paths.sell} component={SellRoutes} />

        <Route
          strict
          path={paths.before.signin}
          render={(props) => {
            return <MatchPathRedirect {...props} redirectUrl={paths.landing} />;
          }}
        />

        <Route
          exact
          path={paths.before.signup}
          render={(props) => {
            return <MatchPathRedirect {...props} redirectUrl={paths.landing} />;
          }}
        />

        <Route
          exact
          path={paths.before.signup_confirm}
          render={(props) => {
            return <MatchPathRedirect {...props} redirectUrl={paths.landing} />;
          }}
        />

        <Route
          exact
          path={paths.before.signUpThank}
          component={SignUpThankRoutes}
        />

        <Route
          strict
          path={paths.stripecallback}
          component={StripeCallbackRoutes}
        />

        <Route exact path={paths.users.profile} component={UserProfileRoutes} />
        <Route
          exact
          path={paths.notificationRedirect}
          component={RedirectRoutes}
        />

        <Route strict path={paths.users.follow} component={UserFollowRoutes} />

        <Route component={FeeRoutes} exact={true} path={paths.common.fee} />
        <Route
          strict
          path={paths.common.mediaPublication}
          component={MediaPublicationRoutes}
        />
        <Route component={NotFoundRoutes} />
      </Switch>

      {window.location.pathname === paths.landing && (
        <div className="app_sticky flex">
          <SellButton className="app_sale_button" />
        </div>
      )}

      {window.location.pathname !== paths.common.apology ? <Footer /> : null}
    </div>
  );
}

export default enhance((props) => <Routes {...props} />);
