/* @flow */
import { getPasswordValidateMessage } from './utils';

export default function signIn(values) {
  const errors = {};
  const username = values.get('username');
  const password = values.get('password');

  // メールアドレス
  if (!username) {
    errors.username = 'メールアドレスを入力してください';
  } else if (username) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username.trim())) {
      errors.username = 'メールアドレスが正しい形式ではありません';
    } else if (username.length > 255) {
      errors.username = 'メールアドレスは255文字以内で入力してください';
    }
  }

  // パスワード
  if (!password) {
    errors.password = 'パスワードを入力してください';
  } else if (password) {
    errors.password = getPasswordValidateMessage(password);
  }
  return errors;
}
