import React from 'react';
import { Header } from 'semantic-ui-react';

import { useSection } from './useSection';
import { ImageSlider } from '@/components/Common';
import { getLocaleStringLocales } from '@/utils';

const View = ({ fishKind, images, price, closeBidAt }) => {
  const { isLessHour, diff, restTime } = useSection({ closeBidAt });

  return (
    <div className="modal__sell-confirm__main-view">
      <Header as="h2">{fishKind}</Header>

      <div className="modal__sell-confirm__main-img">
        <ImageSlider images={images || []} />
      </div>

      <div className="modal__sell-confirm__main-sell-time">
        <div className="modal__sell-confirm__main-closedat">
          {restTime}まで
          <br />
          {isLessHour ? <div className="">残り{diff}分未満</div> : null}
        </div>
        <div className="modal__sell-confirm__main-caution">
          <p>
            ※残り時間は目安であり通信環境やシステム状況などから
            <br />
            誤差が生じる場合があります。
          </p>
        </div>
      </div>

      <div className="modal__sell-confirm__main-price">
        {price ? <h3>¥{getLocaleStringLocales(price)}</h3> : null}
      </div>
    </div>
  );
};

export default View;
