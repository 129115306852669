import axios from 'axios';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

import { Auth } from '@/utils';

const resouces = '/users';

/** メールアドレス変更 */
export const changeUserEmail = async (userId, uuid) => {
  try {
    const token = await Auth.getIdToken();

    const { data } = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .put(
        `${GO_ENDPOINT}/v1/${SITE_TYPE}${resouces}/${userId}/email?uuid=${uuid}`
      );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
