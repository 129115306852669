import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { getShippingDetail, getSaleImages, getShippingCosts } from '@/apis';

import { salesAction, usersAction } from '@/actions';
import prefectures from '../SignUp/utils/prefecture';

const mapStateToProps = (state) => ({
  app: state.app.toJS(),
  form: state.form.toJS(),
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, ...usersAction, push }, dispatch);

const enhance = compose(
  setDisplayName('Sale'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('displayBidsError', 'updateDisplayBidsError', false),
  withState('displayBidsErrorMessage', 'updateDisplayBidsErrorMessage', ''),
  withState('openBidModal', 'updateOpenBidModal', false),
  withState('openFixModal', 'updateOpenFixModal', false),
  withState('isMountLoading', 'updateMountLoading', true),
  withState('isApplyedAddressComment', 'setApplyedAddressComment', false),
  withState('oldCommentData', 'setOldCommentData', ''),
  withState('openReportModal', 'updateOpenReportModal', false),
  withState('isErrorModal', 'setIsErrorModal', false),
  withState('shippingCosts', 'setShippingCosts', {}),
  withState('prefecture', 'setPrefecture', ''),
  withState('shippingCost', 'setShippingCost', 0),
  withState('shippingInfo', 'setShippingInfo', {}),
  withState('images', 'setImages', []),

  withHandlers({
    toggleApplyAddresAtComment: (props) => () => {
      const { setApplyedAddressComment, isApplyedAddressComment } = props;
      setApplyedAddressComment(!isApplyedAddressComment);
    },
    setOldComment: (props) => (values) => {
      const { setOldCommentData } = props;
      setOldCommentData(values);
    },

    openModal: (props) => (event) => {
      event.preventDefault();

      const {
        form,
        sales,
        updateDisplayBidsError,
        updateDisplayBidsErrorMessage,
        updateOpenFixModal,
        updateOpenBidModal,
      } = props;
      const { values } = form.sale;
      updateDisplayBidsError(false);

      // 入札バリデーション;
      if (!values || values.amount) {
        updateDisplayBidsError(true);
        updateDisplayBidsErrorMessage('数字を入力してください。');
        return;
      }

      if (values.amount <= sales.item.price) {
        updateDisplayBidsError(true);
        updateDisplayBidsErrorMessage(
          '現在価格よりも高い金額を入力してください。'
        );
        return;
      }

      if (values.amount > Number(sales.item.fixPrice)) {
        updateDisplayBidsError(true);
        updateDisplayBidsErrorMessage('即決価格以下の金額を入力してください。');
        return;
      }

      // 確認モーダルの表示
      if (Number(values.amount) === Number(sales.item.fixPrice)) {
        // 落札確認モーダル
        updateOpenFixModal(true);
      } else {
        // 入札確認モーダル
        updateOpenBidModal(true);
      }
    },
    setReportModal: (props) => () => {
      props.updateOpenReportModal(!props.openReportModal);
    },
  }),
  lifecycle({
    //
    async componentDidMount() {
      const {
        getSaleById,
        match,
        getSaleBids,
        getUserShipping,
        getBuyersShipping,
        getMe,
        updateMountLoading,
        cleanSaleComments,
        setImages,
      } = this.props;

      cleanSaleComments();

      try {
        await getSaleById(match.params.sale_id);

        window.isFishData = true;
      } catch (error) {
        window.isFishData = false;
      }

      await getBuyersShipping(match.params.sale_id);

      await getSaleBids(match.params.sale_id);

      const addImages = await getSaleImages(match.params.sale_id);
      setImages(addImages);

      if (window.isAuthed) {
        try {
          const shippingInfo = await getShippingDetail(
            this.props.sales.item.boughtUserId,
            this.props.sales.item.id
          );
          this.props.setShippingInfo(shippingInfo);
        } catch (error) {
          console.error(error);
        }
      }

      updateMountLoading(false);

      try {
        const shippingCosts = await getShippingCosts(
          this.props.sales.item.sellerUserId
        );
        this.props.setShippingCosts(shippingCosts);
      } catch (error) {
        console.error(error);
      }

      // ログインしていないときは、getMeしない
      if (window.isAuthed) {
        try {
          const me = await getMe();
          await getUserShipping(me.value.id);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async componentDidUpdate({
      users: {
        mydata: { prefecture: prevPrefecture },
      },
      ...prevProps
    }) {
      const {
        prefecture: prefectureState,
        sales: {
          item: { shippingSize },
        },
        setPrefecture,
        setShippingCost,
        shippingCost,
        shippingCosts,
        users: {
          mydata: { prefecture },
        },
      } = this.props;
      const nextPrefecture = prefecture || 13;

      if (
        this.props.users.mydata.id === this.props.sales.item.boughtUserId &&
        this.props.sales.item.boughtUserId !== prevProps.sales.item.boughtUserId
      ) {
        const shippingInfo = await getShippingDetail(
          this.props.sales.item.boughtUserId,
          this.props.sales.item.id
        );
        this.props.setShippingInfo(shippingInfo);
      }

      if (prevPrefecture !== prefecture || !prefectureState) {
        const { text } = prefectures.find(
          ({ value }) => nextPrefecture === value
        );

        setPrefecture(text);
      }

      if (shippingSize && !shippingCost) {
        let region = '';

        switch (nextPrefecture) {
          case 1: {
            region = 'hokkaido';

            break;
          }
          case 2:
          case 3:
          case 5: {
            region = 'kita_tohoku';

            break;
          }
          case 4:
          case 6:
          case 7: {
            region = 'minami_tohoku';

            break;
          }
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 19: {
            region = 'kanto';

            break;
          }
          case 15:
          case 20: {
            region = 'shinetsu_tohoku';

            break;
          }
          case 16:
          case 17:
          case 18: {
            region = 'hokuriku';

            break;
          }
          case 21:
          case 22:
          case 23:
          case 24: {
            region = 'chubu';

            break;
          }
          case 25:
          case 26:
          case 27:
          case 28:
          case 29:
          case 30: {
            region = 'kansai';

            break;
          }
          case 31:
          case 32:
          case 33:
          case 34:
          case 35: {
            region = 'chugoku';

            break;
          }
          case 36:
          case 37:
          case 38:
          case 39: {
            region = 'shikoku';

            break;
          }
          case 40:
          case 41:
          case 42:
          case 43:
          case 44:
          case 45:
          case 46: {
            region = 'kyushu';

            break;
          }
          case 47: {
            region = 'okinawa';

            break;
          }
        }

        const shippingCostValue = shippingCosts[`${region}${shippingSize}`];

        if (!shippingCostValue) {
          return;
        }

        setShippingCost(shippingCostValue);
      }
    },
  })
);

export default enhance;
