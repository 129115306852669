import { Record } from 'immutable';
import { makeRemoteActiveWithImmutable } from '@/migration/pagination/makeRemoteActiveWithImmutable';
import {
  parseActivities,
  parseReviews,
  parseFollows,
  parseFollowers,
} from './utils';
import remove from 'lodash/remove';
import drop from 'lodash/drop';

const StateRecord = Record({
  me: {
    // TODO: mydataを使わないようにする
    id: 0,
    identityVerified: false,
    displayName: '',
    company: '',
    fishingBoat: '',
    introduction: '',
    thumnailImageUrl: '',
    prefecture: 1, // Formに存在する値を指定する。
    likesCount: 0,
    ratedCount: 0,
    lastLoginAt: '',
    isSeller: false,
    isCustomer: false,
  },
  isFetching: false,
  mydata: {},
  myshippingdata: {},
  userShippingData: {},
  myCreditCards: [],
  userProfile: {},
  reviewerProfile: {},
  myFollowerId: [],
  myFollowerCounts: [],
  myFollowId: [],
  myFollowCounts: [],
  userFollowerId: [],
  userFollowerCounts: [],
  userFollowId: [],
  userFollowCounts: [],
  activities: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
    unreadCounts: 0,
  },

  // 評価
  review: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },

  follow: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
  follower: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
  notReviewedItem: [],
});

class UsersState extends StateRecord {
  getMe(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('mydata', action.payload);

      return draftState;
    });
    return nextState;
  }

  putUser(state) {
    return state;
  }

  getUserShipping(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('myshippingdata', action.payload);

      return draftState;
    });
    return nextState;
  }

  getBuyersShipping(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('userShippingData', action.payload);

      return draftState;
    });
    return nextState;
  }

  postUser(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('mydata', action.payload);
      return draftState;
    });

    return nextState;
  }

  getUserById(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('userProfile', action.payload);

      return draftState;
    });

    return nextState;
  }

  getActivities(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['activities', 'current'], payload.page);

      draftState
        .setIn(['activities', 'totalCount'], payload.totalCount)
        .setIn(['activities', 'totalPages'], payload.totalPages)
        .setIn(
          ['activities', 'pages', 'active'],
          parseActivities(payload.pages.active || [])
        )
        .setIn(
          ['activities', 'pages', 'first'],
          parseActivities(payload.pages.first || [])
        )
        .setIn(
          ['activities', 'pages', 'last'],
          parseActivities(payload.pages.last || [])
        )
        .setIn(
          ['activities', 'pages', 'before_distant'],
          parseActivities(payload.pages.before_distant || [])
        )
        .setIn(
          ['activities', 'pages', 'before_near'],
          parseActivities(payload.pages.before_near || [])
        )
        .setIn(
          ['activities', 'pages', 'after_near'],
          parseActivities(payload.pages.after_near || [])
        )
        .setIn(
          ['activities', 'pages', 'after_distant'],
          parseActivities(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  updateActivitiesPageActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['activities', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['activities', 'pages'],
            state.activities.current,
            payload.current,
            state.activities.totalPages
          )
        )
        .setIn(['activities', 'current'], payload.current);

      return draftState;
    });

    return nextState;
  }

  putUserBlame(state) {
    return state;
  }

  putUserShop(state) {
    const nextStatus = state.mydata;
    nextStatus.isSeller = true;
    const nextState = state.withMutations((draftState) => {
      draftState.set('mydata', nextStatus);
      return draftState;
    });

    return nextState;
  }

  getActivitiesUnreadCounts(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.setIn(
        ['activities', 'unreadCounts'],
        action.payload.unread_count
      );

      return draftState;
    });
    return nextState;
  }

  getUserCards(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('myCreditCards', action.payload);

      return draftState;
    });
    return nextState;
  }

  getUserShop(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('myShop', action.payload);

      return draftState;
    });
    return nextState;
  }

  postUserCard(state) {
    const nextState = state.withMutations((draftState) => {
      return draftState;
    });
    return nextState;
  }

  putActivities(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.setIn(
        ['activities', 'unreadCounts'],
        action.payload.notificationCount.unread_count
      );

      return draftState;
    });
    return nextState;
  }

  // 評価のページネーション
  getUserReviewPagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['review', 'current'], payload.page);

      draftState
        .setIn(['review', 'totalCount'], payload.totalCount)
        .setIn(['review', 'totalPages'], payload.totalPages)
        .setIn(
          ['review', 'pages', 'active'],
          parseReviews(payload.pages.active || [])
        )
        .setIn(
          ['review', 'pages', 'first'],
          parseReviews(payload.pages.first || [])
        )
        .setIn(
          ['review', 'pages', 'last'],
          parseReviews(payload.pages.last || [])
        )
        .setIn(
          ['review', 'pages', 'before_distant'],
          parseReviews(payload.pages.before_distant || [])
        )
        .setIn(
          ['review', 'pages', 'before_near'],
          parseReviews(payload.pages.before_near || [])
        )
        .setIn(
          ['review', 'pages', 'after_near'],
          parseReviews(payload.pages.after_near || [])
        )
        .setIn(
          ['review', 'pages', 'after_distant'],
          parseReviews(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  getNotReviewedItem(state, action) {
    const { payload } = action;
    const nextState = state.withMutations((draftState) => {
      draftState.set('notReviewedItem', payload);

      return draftState;
    });
    return nextState;
  }

  updateReviewPagesActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['review', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['review', 'pages'],
            state.review.current,
            payload.current,
            state.review.totalPages
          )
        )
        .setIn(['review', 'current'], payload.current);
      return draftState;
    });
    return nextState;
  }

  getMyFollowers(state, action) {
    const nextState = state.withMutations((draftState) => {
      const myFollowerId = action.payload;
      draftState.set('myFollowerId', myFollowerId);
      draftState.set('myFollowerCounts', myFollowerId.length);

      return draftState;
    });

    return nextState;
  }

  getUserFollowers(state, action) {
    const nextState = state.withMutations((draftState) => {
      const userFollowerId = action.payload;
      draftState.set('userFollowerId', userFollowerId);
      draftState.set('userFollowerCounts', userFollowerId.length);

      return draftState;
    });

    return nextState;
  }

  getFollowerPagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['follower', 'current'], payload.page);

      draftState
        .setIn(['follower', 'totalCount'], payload.totalCount)
        .setIn(['follower', 'totalPages'], payload.totalPages)
        .setIn(
          ['follower', 'pages', 'active'],
          parseFollowers(payload.pages.active || [])
        )
        .setIn(
          ['follower', 'pages', 'first'],
          parseFollowers(payload.pages.first || [])
        )
        .setIn(
          ['follower', 'pages', 'last'],
          parseFollowers(payload.pages.last || [])
        )
        .setIn(
          ['follower', 'pages', 'before_distant'],
          parseFollowers(payload.pages.before_distant || [])
        )
        .setIn(
          ['follower', 'pages', 'before_near'],
          parseFollowers(payload.pages.before_near || [])
        )
        .setIn(
          ['follower', 'pages', 'after_near'],
          parseFollowers(payload.pages.after_near || [])
        )
        .setIn(
          ['follower', 'pages', 'after_distant'],
          parseFollowers(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  updateFollowerPagesActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['follower', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['follower', 'pages'],
            state.follower.current,
            payload.current,
            state.follower.totalPages
          )
        )
        .setIn(['follower', 'current'], payload.current);
      return draftState;
    });
    return nextState;
  }

  getMyFollows(state, action) {
    const nextState = state.withMutations((draftState) => {
      const myFollowId = action.payload;
      draftState.set('myFollowId', myFollowId);
      draftState.set('myFollowCounts', myFollowId.length);

      return draftState;
    });

    return nextState;
  }

  getUserFollows(state, action) {
    const nextState = state.withMutations((draftState) => {
      const userFollowId = action.payload;
      draftState.set('userFollowId', userFollowId);
      draftState.set('userFollowCounts', userFollowId.length);

      return draftState;
    });

    return nextState;
  }

  getFollowPagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['follow', 'current'], payload.page);

      draftState
        .setIn(['follow', 'totalCount'], payload.totalCount)
        .setIn(['follow', 'totalPages'], payload.totalPages)
        .setIn(
          ['follow', 'pages', 'active'],
          parseFollows(payload.pages.active || [])
        )
        .setIn(
          ['follow', 'pages', 'first'],
          parseFollows(payload.pages.first || [])
        )
        .setIn(
          ['follow', 'pages', 'last'],
          parseFollows(payload.pages.last || [])
        )
        .setIn(
          ['follow', 'pages', 'before_distant'],
          parseFollows(payload.pages.before_distant || [])
        )
        .setIn(
          ['follow', 'pages', 'before_near'],
          parseFollows(payload.pages.before_near || [])
        )
        .setIn(
          ['follow', 'pages', 'after_near'],
          parseFollows(payload.pages.after_near || [])
        )
        .setIn(
          ['follow', 'pages', 'after_distant'],
          parseFollows(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  updateFollowPagesActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['follow', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['follow', 'pages'],
            state.follow.current,
            payload.current,
            state.follow.totalPages
          )
        )
        .setIn(['follow', 'current'], payload.current);
      return draftState;
    });
    return nextState;
  }

  postFollow(state, action) {
    const nextState = state.withMutations((draftState) => {
      const myFollowId = action.payload;
      draftState.set('myFollowId', myFollowId);
      draftState.set('myFollowCounts', myFollowId.length);

      return draftState;
    });

    return nextState;
  }

  deleteFollow(state, action) {
    const nextState = state.withMutations((draftState) => {
      const result = remove(state.get('myFollowId'), (num) => {
        return num !== action.payload;
      });

      return draftState.set('myFollowId', [...result]);
    });

    return nextState;
  }

  deleteUserCards(state) {
    const result = drop(state.get('myCreditCards'), 1);
    const nextState = state.withMutations((draftState) => {
      draftState.set('myCreditCards', result);
      return draftState;
    });
    return nextState;
  }
}

export default UsersState;
