/* @flow */
import * as React from 'react';

function IntroductionCooking() {
  return (
    <div className="app__page3">
      <div className="b__how_to">
        <div className="w__movie">
          <div className="w__header_label">
            <h3>処理方法について</h3>
          </div>
          <div className="w__iframe">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/U_x9i1PXuKA"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>

        <div className="w__movie-food-poisoning">
          <div className="w__header-food-poisoning">
            <h3>食中毒について</h3>
          </div>
          <div className="w__warning-poison">
            <div className="w__warning-links">
              <div className="w__avoiding-food-poisoning">
                <p>アニサキスによる食中毒を予防しましょう（厚生労働省）</p>
                <a
                  href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/0000042953.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/0000042953.html
                </a>
              </div>
              <div className="w__food-parasites">
                <p>食品の寄生虫（東京都福祉保健局）</p>
                <a
                  href="http://www.fukushihoken.metro.tokyo.jp/shokuhin/musi/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.fukushihoken.metro.tokyo.jp/shokuhin/musi/index.html
                </a>
              </div>
            </div>

            <div className="w__iframe-movie-food-poisoning">
              <iframe
                width="567"
                height="456"
                src="https://www.youtube.com/embed/H8hhbNK8GII"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroductionCooking;
