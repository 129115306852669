export type CoookieConfigType = {
  cookie_name: string;
  cookie_expires: number;
  cookie_domain: string | null;
  cookie_path: string | null;
};

export const cookieConfig: CoookieConfigType = {
  cookie_name: 'traffic-speed',
  cookie_expires: 365,
  cookie_domain: null,
  cookie_path: null,
};
