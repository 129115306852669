import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { SignUpThank } from '@/components/Pages';
import enhance from './enhancer';

function SignUpThankRoutes() {
  return (
    <Route strict path={paths.before.signUpThank} component={SignUpThank} />
  );
}

export default enhance((props) => <SignUpThankRoutes {...props} />);
