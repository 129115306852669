import { compose, setDisplayName, withHandlers } from 'recompose';
import { reduxForm } from 'redux-form/immutable';

const enhance = compose(
  setDisplayName('SearchForm'),
  reduxForm({
    form: 'search_form',
  }),
  withHandlers({
    changeToggle: (props) => (event, nextInput) => {
      props.input.onChange(nextInput.value);
    },
  })
);

export default enhance;
