const soilAdditionalInfo = [
  {
    key: '1',
    value: '1',
    name: 'soilAdditionalInfo1',
    text: '家畜ふん',
  },
  {
    key: '2',
    value: '2',
    name: 'soilAdditionalInfo2',
    text: '生ごみ',
  },
  {
    key: '3',
    value: '3',
    name: 'soilAdditionalInfo3',
    text: 'もみがら有機',
  },
  {
    key: '4',
    value: '4',
    name: 'soilAdditionalInfo4',
    text: 'Humanure',
  },
  {
    key: '5',
    value: '5',
    name: 'soilAdditionalInfo5',
    text: '厩肥',
  },
];

export const soilAdditionalInfoNames = soilAdditionalInfo.map(
  ({ name }) => name
);

export default soilAdditionalInfo;
