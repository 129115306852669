import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Loader } from 'semantic-ui-react';
import { TitleBar } from '@/components/Common';
import enhance from './enhance';
import { paths } from '@/config';
import { CreditCardTile } from './CreditCardTile';

function CreditCard(props) {
  const { isLoading, users, handleDeleteCreditCard } = props;

  return (
    <div>
      <TitleBar title="支払い方法変更(カード登録)" />
      <section className="app__credit">
        <span className="payments flex">
          <section className="payments_container">
            <div className="buy_fish">
              <div className="payment_wrapper">
                {isLoading ? (
                  <div>
                    <Loader
                      active={isLoading}
                      inline="centered"
                      className="fished_loading"
                    />
                  </div>
                ) : (
                  <>
                    <p className="address_register_link">
                      <span>【重要】</span>入札をする場合 送付先住所の
                      <Link to={paths.mypage.edit}>登録</Link>
                      も必要です。
                    </p>
                    {!(users.myCreditCards.length === 0) ? (
                      <div className="already_card">
                        {users.myCreditCards.map((cards, i) => (
                          <CreditCardTile
                            key={i}
                            last4={cards.last4}
                            expMonth={cards.expMonth}
                            expYear={cards.expYear}
                          />
                        ))}
                        <Link to={paths.mypage.credit_register}>
                          <Button
                            content="変更する"
                            className="payment_button"
                            disabled={isLoading}
                          />
                        </Link>
                        <div className="w__delete_button">
                          <Button
                            content="削除する"
                            className="delete_button"
                            disabled={isLoading}
                            onClick={handleDeleteCreditCard}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="b__unregistered">
                        <p className="description">
                          カードが登録されていません
                        </p>
                        <Link to={paths.mypage.credit_register}>
                          <Button
                            content="カードを登録する"
                            className="payment_button"
                            disabled={isLoading}
                          />
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </section>
        </span>
      </section>
    </div>
  );
}

export default enhance((props) => <CreditCard {...props} />);
