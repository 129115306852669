import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { bindActionCreators } from 'redux';

import { appAction, usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  router: state.router,
  users: state.users.toJS(),
  app: state.app.toJS(),
  form: state.form.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, ...appAction }, dispatch);

const enhance = compose(
  setDisplayName('AfterRoutes'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
