import { isHttpStatusOK } from '@/apis/utils';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

export default async function putActivitiesToUnread(userId, unreadCounts) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/users/${userId}/activities/to-unread`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(unreadCounts),
      }
    );

    if (isHttpStatusOK(response.status)) {
      return response.json();
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
}
