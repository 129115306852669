/* @flow */
export default function resetPassword(values) {
  const errors = {};
  const username = values.get('username');

  // メールアドレス
  if (!username || username === '') {
    errors.username = 'メールアドレスを入力してください';
  } else if (username) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username.trim())) {
      errors.username = 'メールアドレスが正しい形式ではありません';
    }
  }

  return errors;
}
