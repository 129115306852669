import React from 'react';
import { Header } from 'semantic-ui-react';

function TitleBar(props) {
  const { title, id } = props;

  return (
    <div className="app_titlebar" id={id}>
      <Header as="h3">{title}</Header>
    </div>
  );
}

export default TitleBar;
