import { Auth } from '@/utils';
import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { parseSaleBid } from './utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function getSaleBids(saleId) {
  let headers;
  try {
    const token = await Auth.getIdToken();
    headers = { Authorization: `Bearer ${token}` };
  } catch (error) {
    headers = {};
  }

  try {
    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/sales/${saleId}/bids`,
      {
        method: 'GET',
        headers,
      }
    );
    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      return responseJson.map((json) => parseSaleBid(json));
    }

    throw new Error(parseFailed(responseJson));
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default getSaleBids;
