export default [
  {
    text: '',
    value: 0,
    key: '',
  },
  {
    text: '指定しない',
    value: 1,
    key: '1',
  },
  {
    text: '午前中',
    value: 2,
    key: '2',
  },
  {
    text: '14～16時',
    value: 3,
    key: '3',
  },
  {
    text: '16～18時',
    value: 4,
    key: '4',
  },
  {
    text: '18～20時',
    value: 5,
    key: '5',
  },
  {
    text: '19～21時',
    value: 6,
    key: '6',
  },
];
