import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { ChangeEmail } from '@/components/Pages';

function ChangeEmailRoutes() {
  return (
    <Route strict path={paths.before.confirmEmail} component={ChangeEmail} />
  );
}

export default ChangeEmailRoutes;
