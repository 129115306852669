import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { TitleBar } from '@/components/Common';
import enhance from './enhance';

function Logout(props) {
  const { handleSignOut } = props;

  return (
    <>
      <TitleBar title="ログアウト" />
      <section className="app_signout">
        <Button
          className="signout_button"
          content="ログアウトする"
          onClick={handleSignOut}
        />
      </section>
    </>
  );
}

export default enhance((props) => <Logout {...props} />);
