import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

const postUserImageUpload = async ({ image, userId }) => {
  try {
    const token = await Auth.getIdToken();

    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/users/${userId}/image-upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          image,
        }),
      }
    );

    const responseJson = await response.json();

    if (responseJson.errors) {
      throw new Error(responseJson.errors[0].message);
    } else {
      return responseJson;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export default postUserImageUpload;
