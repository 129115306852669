import * as React from 'react';
import ReCAPTCHALocal from 'react-google-recaptcha';
import enhance from './enhancer';

function ReCAPTCHA(props) {
  const { grecaptcha, sitekey, onChange } = props;

  return <ReCAPTCHALocal {...{ grecaptcha, sitekey, onChange }} />;
}

export default enhance((props) => <ReCAPTCHA {...props} />);
