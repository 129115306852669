import React, { useRef } from 'react';
import { Button } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import Cropper from 'react-cropper';

import enhance from './enhancer';

const ImageEditModalBody = (props) => {
  const cropRef = useRef(null);

  return (
    <>
      {props.editFile.original ? (
        <div className="image-edit-modal-body">
          <div className="image-edit-modal-title">
            <span>写真の編集</span>
          </div>
          <Cropper
            ref={cropRef}
            src={props.editFile.original}
            // Cropper.js options
            viewMode={1}
            aspectRatio={1}
            zoomable={false}
            className="image-edit-cropper"
            autoCropArea={1}
            ready={() => {
              if (isEmpty(props.editFile.cropBoxData)) {
                cropRef.current.setCropBoxData({ top: 0, left: 0 });
              } else {
                cropRef.current.setCropBoxData(props.editFile.cropBoxData);
              }
            }}
          />
          <div className="w__edit-buttons">
            <Button
              className="cancel-button"
              disabled={props.loading}
              onClick={() => {
                props.setCropperModalOpen(false);
              }}
            >
              キャンセル
            </Button>

            <Button
              className="crop-button"
              loading={props.loading}
              disabled={props.loading}
              onClick={() => {
                props.setLoading(true);
                // 次回のcropの位置を覚える為に、stateに保存
                const cropBoxData = cropRef.current.getCropBoxData();

                cropRef.current.getCroppedCanvas().toBlob((blob) => {
                  props.updateDroppedImage(cropBoxData, blob);

                  props.setEditFile({});
                  props.setLoading(false);
                  props.setCropperModalOpen(false);
                });
              }}
            >
              切り取る
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default enhance((props) => <ImageEditModalBody {...props} />);
