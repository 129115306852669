import $ from 'jquery';
import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';

import { salesAction, usersAction, appAction } from '@/actions';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...salesAction, ...usersAction, ...appAction, notify },
    dispatch
  );

const enhance = compose(
  setDisplayName('Landing'),
  withState('isLoading', 'updateIsLoading', true),
  withState('showModal', 'updateShowModal', true),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    dispatchFetchSale: (props) => async (id) => {
      await props.fetchSale(id);
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.resetHeaderText();
      await this.props.getSalesForLanding();
      this.props.updateIsLoading(false);

      try {
        if (window.isAuthed) {
          const notReviewedItem = await this.props.getNotReviewedItem();
          if (notReviewedItem.value.id) {
            this.props.updateShowModal(true);
          }
        }
      } catch (err) {
        // no current user のエラーが重複するので、catchの上に何もしない
        console.error(err);
      } finally {
        if (typeof window.detailToSalesScrollY === 'number') {
          $(window.document).scrollTop(window.detailToSalesScrollY);
          // eslint-disable-next-line
          window.detailToSalesScrollY = 0;
        }
      }
    },
  })
);

export default enhance;
