import { sls } from '../../utils';

const reportMail = async (params) => {
  try {
    const { data } = await sls.post('/report-mail', params);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default reportMail;
