export default function parseSaleBid({ message, code, type, detail }) {
  return Object.assign(
    {},
    {
      message,
      code,
      type,
      detail,
    }
  );
}
