import axios from 'axios';
import qs from 'qs';
import { Auth } from '@/utils';
import { getEnv } from '@/utils/env';

// TODO あとでapis以下のfetchやaxiosでapiを叩いている部分を置き換える
/** httpリクエスト時の共通関数 */
export const httpClient = async () => {
  let headers: { Authorization?: string } = {};
  const { GO_ENDPOINT } = getEnv();

  try {
    // JWTトークンの取得
    const token = await Auth.getIdToken();
    headers = {
      Authorization: `Bearer ${token}`,
    };
  } catch (error) {
    headers = {};
  }

  const iAxios = axios.create({
    baseURL: GO_ENDPOINT,
    headers,
  });

  iAxios.interceptors.request.use((config) => {
    // Getメソッドの時
    if (config.method === 'get') {
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      };
      return config;
    }

    return config;
  });

  return iAxios;
};
