import * as React from 'react';

function DivLiner(props) {
  return (
    <div className={`${props.className}`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox="159.74712643678163 113.82758620689654 154 5"
        width={props.width}
        height={props.height}
      >
        <defs>
          <path
            d="M160.75 114.83L310.75 114.83L310.75 115.83L160.75 115.83L160.75 114.83Z"
            id="bd7iOhJkC"
          />
        </defs>
        <g>
          <g>
            <use
              xlinkHref="#bd7iOhJkC"
              opacity="1"
              fill="#979797"
              fillOpacity="1"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default DivLiner;
