const pesticides = [
  // {
  //   key: '1',
  //   value: '1',
  //   name: 'pesticides1',
  //   text: '通常',
  // },
  {
    key: '2',
    value: 2,
    name: 'pesticides2',
    text: '減農薬',
  },
  {
    key: '3',
    value: 3,
    name: 'pesticides3',
    text: '無農薬',
  },
];

export const pesticidesNames = pesticides.map(({ name }) => name);

export default pesticides;
