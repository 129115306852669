import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Header, Image, Icon } from 'semantic-ui-react';
import { paths } from '@/config';
import enhance from './enhancer';
import { HashLink } from 'react-router-hash-link';
import Rating from 'react-rating';
import { replaceTofu } from '@/utils/string';

function ProfileHeader(props) {
  const {
    selledCount,
    userThumbnail,
    isShowEditButton,
    averagePoint,
    totalEvaluationCount,
    users: {
      userProfile: { id, isCompany, company, companyAddress, displayName },
    },
    followed,
    followCounts,
    followerCounts,
    isLoading,
    handleUpdateFollow,
    handleDeleteFollow,
  } = props;

  return (
    <div className="app_profile_header">
      <div className="main">
        <div className="f__userThumbnail">
          <div className="w__circle">
            <Image src={userThumbnail} fluid />
            <Link
              to={paths.mypage.edit}
              className={classNames({ edit: isShowEditButton })}
            >
              編集する
            </Link>
          </div>
        </div>
        <div className="w__description">
          <Header>
            {isCompany ? replaceTofu(company) : replaceTofu(displayName)}
          </Header>
          <span className="accessories">
            {isCompany ? (
              <span className="company-address">
                {companyAddress}
                <br />
              </span>
            ) : null}
            出品数 {selledCount}
            <span className="new-line">
              <br />
            </span>
            <span className="profile_reputation">
              {averagePoint}
              <Rating
                initialRating={averagePoint}
                emptySymbol={<Icon name="star" color="grey" />}
                fullSymbol={<Icon name="star" color="yellow" />}
                readonly={true}
              />
              <HashLink smooth to="#reviewTitle">
                ({totalEvaluationCount}件の評価)
              </HashLink>
            </span>
            <br />
            <span className="profile-follow other">
              <span className="follow-num">
                <Link to={`/users/${id}/follow`}>{followCounts}フォロー</Link>
              </span>
              <span className="follower-num">
                {followerCounts}
                フォロワー
              </span>
              {window.isAuthed && !isLoading ? (
                followed ? (
                  <div className="unfollow-button sm">
                    <Button floated="right" onClick={handleDeleteFollow}>
                      フォロー中
                    </Button>
                  </div>
                ) : (
                  <div className="follow-button sm">
                    <Button floated="right" onClick={handleUpdateFollow}>
                      フォローする
                    </Button>
                  </div>
                )
              ) : null}
            </span>
          </span>
        </div>
      </div>
      {window.isAuthed && !isLoading ? (
        followed ? (
          <div className="unfollow-button">
            <Button floated="right" onClick={handleDeleteFollow}>
              フォロー中
            </Button>
          </div>
        ) : (
          <div className="follow-button">
            <Button floated="right" onClick={handleUpdateFollow}>
              フォローする
            </Button>
          </div>
        )
      ) : null}
    </div>
  );
}

export default enhance((props) => <ProfileHeader {...props} />);
