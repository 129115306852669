import React from 'react';

import { Button } from 'semantic-ui-react';

import ViewSection from './ViewSection';
import DetailSection from './DetailSection';
import { useModal } from './useModas';

import './style.scss';

const SellConfirmModal = (props) => {
  const {
    previewData,
    mydata,
    confirmSubmit,
    loading,
    showErrorMessage,
    setShowErrorMessage,
  } = props;
  useModal();

  return (
    <div className="modal__sell-confirm">
      <div className="modal__sell-confirm__main">
        <ViewSection
          price={previewData?.price}
          fishKind={previewData?.fish_kind}
          images={previewData?.images}
          closeBidAt={previewData?.close_bid_at}
        />
        <DetailSection
          explanation={previewData?.explanation}
          // 追加
          sales={{ item: previewData }}
          {...props}
        />
      </div>

      <div className="modal__sell-confirm__main-action">
        {showErrorMessage && (
          <div className="modal__sell-confirm__error-message">
            <span
              onClick={() => {
                setShowErrorMessage('');
              }}
            >
              <img
                src={
                  'https://s3-ap-northeast-1.amazonaws.com/assets.gemcook.com/icons/close.svg'
                }
              />
            </span>

            <div>
              <h3>出品エラー</h3>
              <p>{showErrorMessage}</p>
            </div>
          </div>
        )}

        <Button
          positive
          fluid
          onClick={confirmSubmit}
          loading={loading}
          disabled={loading}
        >
          出品する
        </Button>
      </div>
    </div>
  );
};

export default SellConfirmModal;
