import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({ users: state.users.toJS() });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('UserFollowerList'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
