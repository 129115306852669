import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ push }, dispatch);

const enhance = compose(
  setDisplayName('RegistCredit'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
