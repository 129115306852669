import {
  makeQueryParam,
  paginationQueryBuilder,
  queryBuilder,
  isHttpStatusOK,
} from '@/apis/utils';

import { conditionQueryBuilder, parseSale } from './utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function getSales(pagination, conditions, sort) {
  try {
    const sortq = makeQueryParam('sort', sort.join(''));
    const query = queryBuilder(
      paginationQueryBuilder(pagination),
      conditionQueryBuilder(conditions),
      sortq
    );

    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/sales${query}`,
      {
        method: 'GET',
      }
    );

    if (isHttpStatusOK(response.status)) {
      const json = await response.json();
      const totalCount = Number(response.headers.get('x-total-count'));
      const results = json.map((responseJson) => {
        return parseSale(responseJson);
      });

      return {
        totalCount,
        results,
      };
    }

    throw new Error(response);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default getSales;
