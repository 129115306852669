import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStripe } from '@/config';
import { Routes } from './components/Routes';
import storage from 'store';
import { configureStore } from './store';
import { RecoilRoot } from 'recoil';
import { Auth } from '@/utils';
import TrafficProvider from '@/app/TrafficProvider';

import { getEnv } from '@/utils';
import './styles/index.scss';

const { COGNITO_REGION, COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } = getEnv();

Auth.init(COGNITO_REGION, COGNITO_USER_POOL_ID, COGNITO_CLIENT_ID);
window.isFishData = true;
window.Stripe.Key = configureStripe().ApiKey;

(async function main() {
  window.isAuthed = false;
  window.isInitial = true;

  try {
    window.isAuthed = await Auth.checkSession(false);
  } catch (error) {
    // 未認証の場合uuid以外のstorageを削除する
    storage.each((value, key) => {
      if (key !== 'uuid') {
        storage.remove(key);
      }
    });
    window.isAuthed = false;
  }

  ReactDOM.render(
    <Provider store={configureStore}>
      <RecoilRoot>
        <TrafficProvider>
          <Routes />
        </TrafficProvider>
      </RecoilRoot>
    </Provider>,
    window.document.getElementById('root')
  );
})();
