import { MEAT_ITEMS } from '../config';
import { IsShow } from './is-view';

export const CheckValidate = (values: any) => {
  const errors = {};

  // 種類
  const category = values.get('category');
  if (!category) {
    errors['category'] = `種類を選択してください`;
  }

  // 各項目をチェック
  for (const key in MEAT_ITEMS) {
    const d = MEAT_ITEMS[key];
    const val = values.get(key);

    // 表示されている項目かをチェックし、非表示ならチェックしない
    if (!IsShow(category, values, val)) {
      continue;
    }

    // 必須かどうかのチェック
    const isRequired = (() => {
      if (d.linkRequired) {
        const data = values.get(d.linkRequired.name);

        if (
          data &&
          (d.linkRequired.value === true || d.linkRequired.value === data)
        ) {
          return true;
        }
      }

      return d.required;
    })();

    // 必須で値があるかを確認
    if (isRequired) {
      // 日付の場合
      if (d.type === 'date') {
        const year = values.get(`${key}_year`);
        const month = values.get(`${key}_month`);
        const day = values.get(`${key}_day`);

        if (!year || !month || !day) {
          errors[key] = `${d.errorName ?? d.label}を入力してください`;
          continue;
        }
      }
      // その他
      else if (!val) {
        errors[key] = `${d.errorName ?? d.label}を入力してください`;
        continue;
      }
    }
  }

  return errors;
};
