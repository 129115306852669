/* eslint-disable no-useless-catch */
import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { parseCard } from './utils';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function putUserCards(userId, stripeTokenId) {
  try {
    const token = await Auth.getIdToken();
    // 今は cardId 固定で運用します。
    const cardId = 1;

    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/users/${userId}/cards/${cardId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          token: stripeTokenId,
        }),
      }
    );
    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      const result = responseJson.map(() => {
        return parseCard(responseJson);
      });

      return result;
    }

    throw new Error(parseFailed(responseJson));
  } catch (err) {
    throw err;
  }
}

export default putUserCards;
