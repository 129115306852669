import notify from '@/migration/notification';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withHandlers, compose, setDisplayName } from 'recompose';
import { reduxForm } from 'redux-form/immutable';

import { report as validate } from '@/validates';
import { reportMail } from '../../../../apis';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  validate,
});

const mapDispatchToProps = (dispatch) => {
  bindActionCreators({ notify }, dispatch);
};

const enhance = compose(
  setDisplayName('reportModal'),

  reduxForm({
    form: 'reportForm',
    validate,
  }),

  connect(mapStateToProps, mapDispatchToProps),

  withHandlers({
    reportItem: (props) => async (content) => {
      const values = content.toJS();
      const itemPath = window.location.href;

      try {
        const params = {
          blame_content: values.reportContent,
          email: values.reporterEmail,
          governments: values.governments,
          reporter_name: values.reporterName,
          item_path: itemPath,
        };

        props.handleCloseModal(!props.isModal);

        await reportMail(params);

        notify('通報しました', 'info');
      } catch (err) {
        props.handleCloseModal(!props.isModal);

        notify('通報に失敗しました', 'error');
      }
    },
  })
);

export default enhance;
