import { compose, setDisplayName, withHandlers } from 'recompose';
import notify from '@/migration/notification';
import Jimp from 'jimp';
import loadImage from 'blueimp-load-image';

const enhance = compose(
  setDisplayName('UploadModalDropZone'),
  withHandlers({
    drop: (props) => async (acceptedFiles) => {
      try {
        if (acceptedFiles.length > 1) {
          notify(`1度にアップロードできる画像は1枚です`, 'error', undefined, 5);
          return;
        }

        const promises = acceptedFiles.map(async (file) => {
          const newFile = new Promise((resolve) => {
            loadImage(
              file,
              async (img) => {
                img.toBlob((blob) => {
                  const blobUrl = URL.createObjectURL(blob);
                  resolve(
                    Object.assign(file, {
                      original: blobUrl,
                    })
                  );
                });
              },
              {
                orientation: true,
                meta: true,
                contain: true,
                maxWidth: 1000,
              }
            );
          });

          return newFile;
        });

        const nextFiles = await Promise.all(promises);

        if (nextFiles.length) {
          props.setIsLoading(true);
          const readedImage = await Jimp.read(nextFiles[0].original);
          const base64Image = await readedImage.getBase64Async(
            readedImage._originalMime
          );
          props.change('image', base64Image);
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        props.setIsLoading(false);
      }
    },
  })
);

export default enhance;
