import React from 'react';
import { Loader } from 'semantic-ui-react';

import Header from './Header';
import Card from '@/components/Common/Card';

const INVALID_VALUE = 2147483647;

const Content = ({ isLoading, data }) => {
  if (isLoading) {
    return (
      <div className="b__fishes-company__loading">
        <Loader
          active={isLoading}
          inline="centered"
          className="fished_loading"
        />
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="b__fishes-company__empty">
        <span>対象の商品は存在しません</span>
      </div>
    );
  }

  return (
    <div className="b__fishes-company__content">
      {data.map((company) => {
        return (
          <div className="b__fishes-company__wrapper" key={company.id}>
            <Header
              id={company.id}
              name={company.name}
              address={company.address}
              sales_count={company.sales_count}
              average_point={company.average_point}
              total_evaluation_count={company.total_evaluation_count}
            />
            <div className="b__fishes-company__body">
              {company.sales.length ? (
                <>
                  <div className="b__fishes-company__body-inner">
                    {company.sales.map((sales) => (
                      // TODO:カードの表示変更箇所
                      <Card
                        key={sales.id}
                        id={sales.id}
                        imageUrl={sales.images?.[0]}
                        fishKind={sales.fishKind}
                        isCompany={true}
                        sellerName={''}
                        sellerCompany={sales.sellerCompany}
                        fixPrice={
                          INVALID_VALUE === sales.fixPrice
                            ? '-'
                            : sales.fixPrice
                        }
                        displayPrice={sales.displayPrice}
                        bidCount={sales.bidCount}
                        fixed={sales.fixed}
                        closeBidAtTemp={sales.closeBidAtTemp}
                        fishingSpot={sales.fishingSpot}
                        weight={sales.weight}
                        isNature={sales.isNature}
                        shippingExpense={sales.shippingExpense}
                        bid={sales.bid}
                        closed={sales.closed}
                        closedReason={sales.closedReason}
                        commentAdd={sales.commentAdd}
                        imageAdd={sales.imageAdd}
                      />
                    ))}
                  </div>

                  <div className="b__fishes-company__footer">
                    <a
                      href={`/users/${company.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      店頭ページに行く
                    </a>
                  </div>
                </>
              ) : (
                <div className="b__fishes-company__empty">
                  まだ出品されていません。
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Content;
