import React from 'react';
import enhance from './enhancer';
import Dropzone from '../DropZone';
import { FieldTextArea } from '../../../../Form';

import { Button, Image } from 'semantic-ui-react';

import { Field } from 'redux-form/immutable';

const UploadModal = (props) => {
  const {
    handleSubmit,
    form: {
      UploadModalForm: { values },
    },
    isLoading,
    setIsLoading,
    submit,
  } = props;

  return (
    <form className="a__upload-modal__form" onSubmit={handleSubmit(submit)}>
      {!values.image ? (
        <Field
          name="image"
          component={Dropzone}
          change={props.change}
          setIsLoading={setIsLoading}
        />
      ) : (
        <div className="a__upload-modal__image">
          <Image src={values.image} />
        </div>
      )}
      <p
        style={{
          fontSize: '12px',
          fontFamily: 'sans-serif',
          marginTop: '12px',
        }}
      >
        ※アップロード可能拡張子 .apng, .bmp, .gif, .ico, .jpg, .jpeg, .png,
        .svg, .tiff, .webp
      </p>

      <div className="form_item">
        <Field
          rows={5}
          className="text_item"
          name="comment"
          component={FieldTextArea}
          placeholder="例) コメントを入力してください"
          maxLength={50}
        />
        <span className="word_count_under">(最大50文字)</span>
      </div>

      <div className="a__upload-modal__btn">
        <Button fluid positive loading={isLoading}>
          登録する
        </Button>
      </div>
    </form>
  );
};

export default enhance((props) => <UploadModal {...props} />);
