import { Base64 } from 'js-base64';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import notify from '@/migration/notification';
import { compose, setDisplayName, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import storage from 'store';

import { signIn as validate } from '@/validates';
import { appAction, usersAction } from '../../../actions';
import { paths } from '../../../config';

import { Auth } from '@/utils';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  app: state.app.toJS(),
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, ...appAction, notify, push }, dispatch);

const enhance = compose(
  setDisplayName('SignIn'),
  reduxForm({
    form: 'signIn',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withState('displayLoginError', 'setDisplayLoginError', false),
  withHandlers({
    signIn: (props) => async (immutableValues) => {
      const values = immutableValues.toJS();
      const { getMe, setIsLoading, setDisplayLoginError, push } = props;

      setDisplayLoginError(false);
      setIsLoading(true);

      try {
        await Auth.signIn(values.username.trim(), values.password);
        await getMe();

        const uuid = storage.get('uuid');
        // uuidがlocalStorageにある場合、メールアドレス更新確認画面に遷移させる
        if (uuid) {
          window.location.href = paths.common.confirmEmail;
        }
      } catch (error) {
        if (error?.code === 'UserNotConfirmedException') {
          push(
            `${paths.before.signup_confirm}/?username=${Base64.encode(
              values.username.trim()
            )}`
          );

          notify('認証が必要です。', 'error', undefined, 5);
        } else {
          setIsLoading(false);
          // ログイン失敗時のメッセージ表示を追加する。
          setDisplayLoginError(true);
        }
      }
    },
    federation: () => async (event, identityProvider) => {
      try {
        event.preventDefault();
        await Auth.snsRegister(identityProvider);
      } catch (error) {
        console.log(error);
      }
    },
  })
);

export default enhance;
