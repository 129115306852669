import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Loader } from 'semantic-ui-react';
import Modal from '@/migration/modal';
import { sorts } from '@/components/Common/SideNav/data';

import {
  FishSearchForm,
  SalesList,
  NotReviewedModal,
} from '@/components/Common';
import { paths } from '@/config';
import enhance from './enhancer';
import { FeatureList } from './FeatureList';
import { Intro } from './Intro';
import {
  setNotReviewItem,
  getYetReviewItem,
  getNotReviewItemFindById,
} from '../../../utils/reviewNotification';

function Landing(props) {
  const {
    searchSales,
    sales: { landing },
    users: { notReviewedItem },
    isLoading,
    updateShowModal,
    showModal,
    history,
  } = props;

  // 2ページ目の並び
  const orderParam = (key) => {
    const resp = sorts.find((f) => f.key === key);
    return resp?.value ?? '';
  };

  const onceCloseModal = () => {
    const id = String(notReviewedPart.id);
    setNotReviewItem(id);
    updateShowModal(!showModal);
  };

  let notReviewedPart = {};
  if (notReviewedItem.length > 0) {
    notReviewedPart = getYetReviewItem(notReviewedItem);
  }
  if (notReviewedPart === undefined) {
    notReviewedPart = notReviewedItem.slice(-1)[0];
  }
  return (
    <div className="app_landing">
      {window.isAuthed ? null : <Intro />}

      {getNotReviewItemFindById(notReviewedPart.id) &&
      Object.keys(notReviewedPart).length > 0 ? (
        <Modal
          isModal={showModal}
          handleCloseModal={onceCloseModal}
          className="not_reviewed_modal"
          size="sm"
          ModalBody={NotReviewedModal}
          modalProps={{ notReviewedPart }}
          history={history}
        />
      ) : null}
      <FeatureList />

      <div className="fish_search_form form">
        <FishSearchForm handleSearch={searchSales} {...props} />
      </div>

      <div className="fishes flex">
        <div className="sales_list">
          <div
            style={{
              textAlign: 'center',
            }}
            className={classNames({
              c__hidden: !isLoading,
            })}
          >
            <div>
              <Loader className="tiny-loader" active inline size="tiny" />
            </div>
          </div>
          <div
            className={classNames({
              c__hidden: isLoading,
            })}
          >
            <SalesList data={landing.data} me={props.users.me} />
          </div>
        </div>

        <div className="route_fishes flex">
          {landing.data.length !== 0 && (
            <Link
              to={`${paths.fishes.all}?page=2&sort=${orderParam(
                'from_shorter'
              )}`}
            >
              <Button content="もっと見る" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <Landing {...props} />);
