import React from 'react';

const FareTable = ({ shippingCosts }) => {
  const items = [
    {
      header: '北海道',
      key: 'hokkaido',
      subHeader: ['北海道'],
    },
    {
      header: '北東北',
      key: 'kita_tohoku',
      subHeader: ['青森県', '秋田県', '岩手県'],
    },
    {
      header: '南東北',
      key: 'minami_tohoku',
      subHeader: ['宮城県', '山形県', '福島県'],
    },
    {
      header: '関東',
      key: 'kanto',
      subHeader: [
        '茨城県',
        '栃木県',
        '群馬県',
        '埼玉県',
        '千葉県',
        '神奈川県',
        '山梨県',
      ],
    },
    {
      header: '東京',
      key: 'tokyo',
      subHeader: ['東京都'],
    },
    {
      header: '信越',
      key: 'shinetsu',
      subHeader: ['新潟県', '長野県'],
    },
    {
      header: '北陸',
      key: 'hokuriku',
      subHeader: ['富山県', '石川県', '福井県'],
    },
    {
      header: '東海',
      key: 'tokai',
      subHeader: ['静岡県', '愛知県', '三重県', '岐阜県'],
    },
    {
      header: '関西',
      key: 'kansai',
      subHeader: ['大阪府', '京都府', '滋賀県', '奈良県', '和歌山県', '兵庫県'],
    },
    {
      header: '中国',
      key: 'chugoku',
      subHeader: ['岡山県', '広島県', '山口県', '鳥取県', '島根県'],
    },
    {
      header: '四国',
      key: 'shikoku',
      subHeader: ['香川県', '徳島県', '愛媛県', '高知県'],
    },
    {
      header: '北九州',
      key: 'kita_kyushu',
      subHeader: ['福岡県', '佐賀県', '長崎県', '大分県'],
    },
    {
      header: '南九州',
      key: 'minami_kyushu',
      subHeader: ['熊本県', '宮崎県', '鹿児島県'],
    },
    {
      header: '沖縄',
      key: 'okinawa',
      subHeader: ['沖縄県'],
    },
  ];
  const headerRow = items.map(({ header }) => (
    <th className="th" key={header}>
      {header}
    </th>
  ));
  const subHeaderRow = items.map(({ header, subHeader }) => (
    <th className="th" key={header}>
      <ul className="list">
        {subHeader.map((sub) => (
          <li key={sub}>{sub}</li>
        ))}
      </ul>
    </th>
  ));
  const bodyRow = [60, 80, 100, 120, 140, 160, 170].map((size) => (
    <tr key={size}>
      <td className="td">{size}</td>
      {items.map(({ key }) => {
        const shippingCost = shippingCosts[`${key}${size}`];

        return (
          <td className="td" key={key}>
            {shippingCost ? shippingCost.toLocaleString() : null}
          </td>
        );
      })}
    </tr>
  ));

  return (
    <div className="a_fare-table">
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr className="header">
              <th className="th">サイズ</th>
              {headerRow}
            </tr>
            <tr className="sub-header">
              <th className="th" />
              {subHeaderRow}
            </tr>
          </thead>
          <tbody>{bodyRow}</tbody>
        </table>
      </div>
    </div>
  );
};

export default FareTable;
