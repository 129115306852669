import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import notify from '@/migration/notification';
import { compose, setDisplayName, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  app: state.app.toJS(),
  users: state.users.toJS(),
  sales: state.sales.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, ...usersAction, notify }, dispatch);

const enhance = compose(
  setDisplayName('ProfileHeader'),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleUpdateFollow: (props) => async () => {
      const {
        users: { mydata, userProfile },
        postFollow,
      } = props;
      await postFollow(mydata.id, userProfile.id);
    },

    handleDeleteFollow: (props) => async () => {
      const {
        users: { mydata, userProfile },
        deleteFollow,
      } = props;
      await deleteFollow(mydata.id, userProfile.id);
    },
  })
);

export default enhance;
