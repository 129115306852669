import React, { useEffect } from 'react';
import { ScrollContext } from 'gatsby-react-router-scroll';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '@/config';
import enhance from './enhancer';
import { AfterRoutes, BeforeRoutes } from './Parents';
import { shouldUpdateScroll } from './utils';
import { GA } from '@/utils';
import { getMentenance } from '@/apis/config/';

function Routes() {
  const { listen } = history;
  useEffect(() => {
    const unlisten = listen((location) => {
      GA.trackingPageView(location.pathname);
    });

    return unlisten;
  }, [listen]);

  useEffect(() => {
    const asyncFunc = async () => {
      await getMentenance();
    };
    asyncFunc();
  }, [history.location.pathname]);

  return (
    <ConnectedRouter history={history}>
      <ScrollContext shouldUpdateScroll={shouldUpdateScroll}>
        <div className="a__routes">
          <>{window.isAuthed ? <AfterRoutes /> : <BeforeRoutes />}</>
        </div>
      </ScrollContext>
    </ConnectedRouter>
  );
}

export default enhance((props) => <Routes {...props} />);
