import { getSalesDetail } from '@/apis';
import { useEffect, useState } from 'react';

export const useDetailItem = (id: number) => {
  const [detailData, setDetailData] = useState<any>({});

  useEffect(() => {
    (async () => {
      if (id !== 0) {
        try {
          const { data } = await getSalesDetail(id);
          setDetailData(data);
        } catch {
          console.error;
        }
      }
    })();
  }, [id]);

  return { detailData };
};
