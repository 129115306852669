// ENHANCE create-react-app化でtsに修正
import React from 'react';
import { Button } from 'semantic-ui-react';
import enhance, { TAB_ITEMS } from './enhancer';

const FishTab = (props) => {
  const { handleTabClick, activeTab, isLoading } = props;

  return (
    <div className="b__fishes__tab">
      <Button.Group>
        {TAB_ITEMS.map(({ key, name }) => (
          <Button
            disabled={isLoading}
            key={key}
            positive={activeTab === key}
            onClick={() => handleTabClick(key)}
          >
            {name}
          </Button>
        ))}
      </Button.Group>
    </div>
  );
};

export default enhance((props) => <FishTab {...props} />);
