import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Item } from 'semantic-ui-react';
import enhance from './enhancer';
import { paths } from '@/config';
import Rating from 'react-rating';

// Reply
import ReplyModal from './ReplyModal';
import Modal from '@/migration/modal';

function ReviewItem(props) {
  const {
    id,
    point,
    sourceUserID,
    comment,
    reply,
    name,
    users: { mydata },
    onClick,
    isLinkDisabled,
    isReplyModal,
    setIsReplyModal,
    current,
    fishKind,
  } = props;

  return (
    <React.Fragment>
      {sourceUserID === 0 ? (
        <Item id={id}>
          <Item.Content>
            <div className="w__auto-review">
              <Item.Meta>自動評価</Item.Meta>
              <Item.Header>
                <div className="w__rating">
                  <Rating
                    initialRating={point}
                    emptySymbol={<Icon name="star" color="grey" />}
                    fullSymbol={<Icon name="star" color="yellow" />}
                    readonly={true}
                  />
                </div>
              </Item.Header>
              <Item.Description>{comment}</Item.Description>
            </div>
          </Item.Content>
        </Item>
      ) : (
        <Item id={id}>
          <Item.Content>
            <div className="w__review">
              <Item.Meta>
                {mydata.id === sourceUserID ? (
                  <div className="links">
                    <div className="w__link">
                      <Link to={paths.mypage.all}>{name}</Link>
                    </div>
                  </div>
                ) : isLinkDisabled ? (
                  <span className="disabled-link">{name}</span>
                ) : (
                  <div className="links">
                    <div className="w__link">
                      <Link to={`/users/${sourceUserID}`} onClick={onClick}>
                        {name}
                      </Link>
                    </div>

                    <button
                      className="w__reply"
                      onClick={() => setIsReplyModal(true)}
                    >
                      {reply ? '返信を更新する' : '返信する'}
                    </button>
                  </div>
                )}
              </Item.Meta>
              <Item.Header>
                {fishKind !== '' ? (
                  <div className="fish_kind">{fishKind}</div>
                ) : (
                  <div className="fish_kind">
                    落札した商品名は公開されません
                  </div>
                )}
                <div className="w__rating">
                  <Rating
                    initialRating={point}
                    emptySymbol={<Icon name="star" color="grey" />}
                    fullSymbol={<Icon name="star" color="yellow" />}
                    readonly={true}
                  />
                </div>
              </Item.Header>
              {comment !== ' ' ? (
                <Item.Description>
                  <b> {fishKind ? '落札者' : '出品者'}：</b>
                  {comment}
                </Item.Description>
              ) : null}
              {reply ? (
                <Item.Description className="reply__message">
                  {fishKind ? '出品者' : '落札者'}：{reply}
                </Item.Description>
              ) : null}
            </div>
          </Item.Content>

          <Modal
            rateId={id}
            current={current}
            className="mypage__reply-modal"
            isReply={reply}
            isModal={isReplyModal}
            handleCloseModal={() => setIsReplyModal(false)}
            ModalBody={ReplyModal}
          />
        </Item>
      )}
    </React.Fragment>
  );
}

export default enhance((props) => <ReviewItem {...props} />);
