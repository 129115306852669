import React from 'react';

import { FishTab, FishesContent, TitleBar } from '../../Common';
import SearchBtn from '../../Layouts/SearchBtn';
import { FishSideNav } from '@/components/Common';
import FishSort from '@/components/Common/FishSort';

import enhance from './enhancer';

/** 商品一覧 すべて */
const FishAllPage = (props) => {
  const {
    sales: {
      fishes: { current, pages, totalPages },
    },
    history,
    isLoading,
    users,
    handleChangePage,
    handleSearch,
    handleToggle,
    handleSort,
    isOpen,
    location,
  } = props;

  return (
    <div className="a__fishe-all">
      <div className="b__sidenav-layout">
        <FishSideNav
          handleSearch={handleSearch}
          handleToggle={handleToggle}
          isOpen={isOpen}
          location={location}
        />

        <div className="a__fishe-all__main">
          <TitleBar title="出品商品一覧" />

          <FishTab history={history} isLoading={isLoading} activeTab={0} />

          <FishSort handleSort={handleSort} location={location} />

          <FishesContent
            isLoading={isLoading}
            active={pages.active}
            me={users.me}
            currentPage={current}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
          />
        </div>

        <SearchBtn handleToggle={handleToggle} />
      </div>
    </div>
  );
};

export default enhance((props) => <FishAllPage {...props} />);
