import axios from 'axios';

import { Auth } from '@/utils';
import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

export const getShippingDetail = async (boutUserId, sale_id) => {
  const token = await Auth.getIdToken();
  const res = await axios
    .create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .get(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/shipping_info?bought_user_id=${boutUserId}&sale_id=${sale_id}`
    );

  return res.data;
};
