export default function confirmSignUp(values) {
  const error = {};

  const confirmCode = values.get('code');

  if (!confirmCode) {
    error.code = '認証コードを入力してください';
  }

  return error;
}
