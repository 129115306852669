import camelCaseKeys from 'camelcase-keys';
import parseSale from './parseSale';

export default function parseSales(items) {
  const result = items.map((item) => {
    const camelItem = camelCaseKeys(item);
    const parseItem = parseSale(item);

    return Object.assign(camelItem, parseItem);
  });

  return result;
}
