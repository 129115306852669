import Jimp from 'jimp';

export default async function resizeDroppedImages(images) {
  const promises = images.map(async (image) => {
    const readedImage = await Jimp.read(image.thumb);

    const base64Image = await readedImage.getBase64Async(
      readedImage._originalMime
    );
    return base64Image;
  });
  const resultImages = await Promise.all(promises);
  return resultImages;
}
