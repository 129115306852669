import { handleActions } from 'redux-actions';
import { appAction } from '../actions';
import { App } from '../models';

export default handleActions(
  {
    [appAction.holdSignUpSteps](state, action) {
      return state.holdSignUpSteps(state, action);
    },
    [appAction.updateHeaderText](state, action) {
      return state.updateHeaderText(state, action);
    },
    [appAction.resetHeaderText](state, action) {
      return state.resetHeaderText(state, action);
    },
    [appAction.setTempEmail](state, action) {
      return state.setTempEmail(state, action);
    },
  },
  new App()
);
