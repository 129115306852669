import { saleDetailType } from '@/types';
import { getEnv } from '@/utils';
import axios from 'axios';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

export const getSalesDetails = async (ids: number[]) => {
  try {
    const response = await axios.get<saleDetailType[]>(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/sales/details/${ids.map((e) => e)}`
    );

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
