import { constants } from '../constants';

const getActivityMsg = (activityCode, saleTitle, userName) => {
  const code = activityCode.toUpperCase();
  let draftMessage = constants.ACTIVITY_TEMPLATES[code];

  if (!draftMessage) {
    draftMessage = '';
  }

  if (draftMessage.includes('<sale_title>')) {
    draftMessage = draftMessage.replace(/<sale_title>/, saleTitle);
  }

  if (draftMessage.includes('<user_name>')) {
    draftMessage = draftMessage.replace(/<user_name>/, userName);
  }

  return draftMessage;
};

export const activities = { getActivityMsg };
