import { compose, setDisplayName, withHandlers } from 'recompose';

const enhance = compose(
  setDisplayName('FieldRadio'),
  withHandlers({
    changeToggle: (props) => () => {
      props.input.onChange(props.label);
    },
  })
);

export default enhance;
