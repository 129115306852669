import * as React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';

import { paths } from '@/config';
import { Sell } from '@/components/Pages';
import enhance from './enhancer';

function SellRoutes() {
  return (
    <Switch>
      <Route strict path={paths.sell} component={Sell} />
    </Switch>
  );
}

export default enhance((props) => <SellRoutes {...props} />);
