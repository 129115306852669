const soil = [
  {
    key: '1',
    value: 1,
    name: 'soil1',
    text: '無肥料',
  },
  {
    key: '2',
    value: 2,
    name: 'soil2',
    text: '化学肥料',
  },
  {
    key: '3',
    value: 3,
    name: 'soil3',
    text: '有機肥料',
  },
  {
    key: '4',
    value: 4,
    name: 'soil4',
    text: '堆肥',
  },
];

export const soilNames = soil.map(({ name }) => name);

export default soil;
