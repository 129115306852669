import { date } from '@/utils';
import { constants } from '@/constants';

const dateFormatTemplate = 'MM. DD. (ddd)';

export const getTimeType = (closeBidAtTemp: string, fixed?: boolean) => {
  let type = '',
    diff = 0;

  if (fixed) {
    type = constants.TIMES.FINISH;
    return { type, diff };
  }

  const date = new Date();
  const _date = new Date(closeBidAtTemp);

  diff = (_date.getTime() - date.getTime()) / constants.MINUTE_MILLISECOND;
  type = constants.TIMES.MINUTE;

  if (diff > 60) {
    //時間
    diff /= 60;
    type = constants.TIMES.HOUR;

    if (diff > 24) {
      // 日
      diff /= 24;
      type = constants.TIMES.DAY;
    }
  }

  diff = Math.ceil(diff);
  if (diff < 0) {
    type = constants.TIMES.FINISH;
  }

  return { type, diff };
};

export const closedMessage = (
  type: string,
  diff: number,
  closeBidAtTemp: string
) => {
  switch (type) {
    case constants.TIMES.FINISH:
      return {
        closeMessageLabel: '終了',
        closeMessageValue: date.formatDateTemplate(
          closeBidAtTemp,
          dateFormatTemplate
        ),
      };
    case constants.TIMES.HOUR:
      return {
        closeMessageLabel: '残り',
        closeMessageValue: `${diff}時間`,
      };
    case constants.TIMES.MINUTE:
      return {
        closeMessageLabel: '残り',
        closeMessageValue: `${diff}分`,
      };
    case constants.TIMES.DAY:
      return {
        closeMessageLabel: '残り',
        closeMessageValue: `${diff}日`,
      };
    default:
      return {
        closeMessageLabel: '',
        closeMessageValue: '',
      };
  }
};

export const shippingMessage = (shippingExpense: number) => {
  switch (shippingExpense) {
    case 1:
      return '送料無料';
    case 2:
      return '着払い';
    case 3:
      return '元払い';
    default:
      return '';
  }
};
