import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import notify from '@/migration/notification';

import { replyComment } from '../../../../apis/rates';
import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...usersAction,
    },
    dispatch
  );

const enhance = compose(
  setDisplayName('ReplyModal'),
  reduxForm({
    form: 'ReplyModalForm',
    initialValues: {
      reply: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    submit: (props) => async (rateId, current) => {
      const {
        getMe,
        form: { ReplyModalForm },
        getUserReviewPagination,
        setIsLoading,
      } = props;

      const { reply } = ReplyModalForm.values;

      if (!reply.trim()) {
        notify('コメントを入力してください', 'error', undefined, 5);
        return;
      }

      try {
        await replyComment(rateId, { reply });
        const { value } = await getMe();

        //getMeでクエリに込める{id}を取得
        const id = value.id;

        await getUserReviewPagination({ limit: 5, current, id }, [
          '-created_at',
        ]);
        notify('コメントの返信に成功しました', 'info', undefined, 5);
      } catch (error) {
        notify('コメントの返信に失敗しました', 'error', undefined, 5);
      } finally {
        setIsLoading(false);
      }
    },
  })
);

export default enhance;
