import notify from '@/migration/notification';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, setDisplayName } from 'recompose';
import { reduxForm } from 'redux-form/immutable';

import { resetPasswordModal as validate } from '@/validates';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ notify }, dispatch);
};

const enhance = compose(
  setDisplayName('passwordResetModal'),
  reduxForm({
    form: 'passwordResetForm',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
