import { Auth } from '@/utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function postSale(data) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(`${GO_ENDPOINT}/v1/${SITE_TYPE}/sales`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    if (responseJson.error) {
      throw new Error(responseJson.errors[0].message);
    } else {
      return responseJson;
    }
  } catch (error) {
    return error;
  }
}

export default postSale;
