import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

function FieldLabelLinkCheckbox(props) {
  const { meta, input } = props;
  return (
    <>
      <Checkbox
        {...props}
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={!!input.value}
        id={props.id}
      />
      <div>
        <label htmlFor={props.id} className="label-link-checkbox">
          {props.children}
        </label>
        {props.annotation && (
          <small style={{ paddingLeft: '10px', display: 'inline-block' }}>
            {props.annotation}
          </small>
        )}
      </div>

      <br />
      {meta.touched && meta.error && (
        <span className="redux_form error">{meta.error}</span>
      )}
    </>
  );
}

export default FieldLabelLinkCheckbox;
