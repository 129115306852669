import { Base64 } from 'js-base64';
import store from 'store';
import { paths } from '@/config';

export default function storeUser(values, push, setIsLoading, destroy) {
  const email = Base64.encode(values.email.trim());

  push(`${paths.before.signup_confirm}/?username=${email}`);

  store.set('AutoAuthorization', {
    email: Base64.encode(values.email.trim()),
    password: Base64.encode(values.password),
  });
  setIsLoading(false);
  destroy();
}
