type Env = {
  CLIENT_ID: string;
  APP_DOMAIN: string;
  REDIRECT_DOMAIN: string;
  GO_ENDPOINT: string;
  SITE_TYPE: string;
  NODE_SLS_ENDPOINT: string;
  UPLOADER_ENDPOINT: string;
  COGNITO_REGION: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_CLIENT_ID: string;
  REDIRECT_URL_SIGN_IN_VENDOR: string;
  REDIRECT_URL_VENDOR_SHIPPING_COST: string;
};

/** local */
const local: Env = {
  CLIENT_ID: '7r9ev1smu38gucunqik870sk8j',
  APP_DOMAIN: 'fishsale-local.auth.ap-northeast-1.amazoncognito.com',
  REDIRECT_DOMAIN: 'http://localhost:1110/cognito-callback',
  // GO_ENDPOINT: 'http://localhost:1130',
  GO_ENDPOINT: 'https://dev.api.meatsale.jp',
  SITE_TYPE: 'meat',
  NODE_SLS_ENDPOINT:
    'https://ipxufxd33i.execute-api.ap-northeast-1.amazonaws.com/local',
  UPLOADER_ENDPOINT: 'https://upload.meatsale.jp/local',
  COGNITO_REGION: 'ap-northeast-1',
  COGNITO_USER_POOL_ID: 'ap-northeast-1_kTg8o2iYc',
  COGNITO_CLIENT_ID: '7idpc79ied61j2j4fsd0otkp87',
  // COGNITO_USER_POOL_ID: 'ap-northeast-1_p5AdpeGgu',
  // COGNITO_CLIENT_ID: '7r9ev1smu38gucunqik870sk8j',
  REDIRECT_URL_SIGN_IN_VENDOR: 'http://localhost:5959/signin',
  REDIRECT_URL_VENDOR_SHIPPING_COST: 'http://localhost:5959/shipping-costs',
};

/** develop */
const dev: Env = {
  CLIENT_ID: '7idpc79ied61j2j4fsd0otkp87',
  APP_DOMAIN: 'fishsale-dev.auth.ap-northeast-1.amazoncognito.com',
  REDIRECT_DOMAIN: 'https://dev.meatsale.jp/cognito-callback',
  GO_ENDPOINT: 'https://dev.api.meatsale.jp',
  SITE_TYPE: 'meat',
  NODE_SLS_ENDPOINT:
    'https://ttt5zix0z9.execute-api.ap-northeast-1.amazonaws.com/dev',
  UPLOADER_ENDPOINT: 'https://upload.meatsale.jp/develop',
  COGNITO_REGION: 'ap-northeast-1',
  COGNITO_USER_POOL_ID: 'ap-northeast-1_kTg8o2iYc',
  COGNITO_CLIENT_ID: '7idpc79ied61j2j4fsd0otkp87',
  REDIRECT_URL_SIGN_IN_VENDOR: 'https://dev.vendor.meatsale.jp/signin',
  REDIRECT_URL_VENDOR_SHIPPING_COST:
    'https://dev.vendor.meatsale.jp/shipping-costs',
};

/** release */
const stg: Env = {
  CLIENT_ID: '252d8tdseubl0rmujtanfeg47d',
  APP_DOMAIN: 'fishsale-stg.auth.ap-northeast-1.amazoncognito.com',
  REDIRECT_DOMAIN: 'https://stg.meatsale.jp/cognito-callback',
  GO_ENDPOINT: 'https://stg.api.meatsale.jp',
  SITE_TYPE: 'meat',
  NODE_SLS_ENDPOINT:
    'https://pbj8jmnr50.execute-api.ap-northeast-1.amazonaws.com/stg',
  UPLOADER_ENDPOINT: 'https://upload.meatsale.jp/release',
  COGNITO_REGION: 'ap-northeast-1',
  COGNITO_USER_POOL_ID: 'ap-northeast-1_tEOvXpfPz',
  COGNITO_CLIENT_ID: '252d8tdseubl0rmujtanfeg47d',
  REDIRECT_URL_SIGN_IN_VENDOR: 'https://stg.vendor.meatsale.jp/signin',
  REDIRECT_URL_VENDOR_SHIPPING_COST:
    'https://stg.vendor.meatsale.jp/shipping-costs',
};

/** master */
const prd: Env = {
  CLIENT_ID: 'noup2ghqt868a9sv2cse9mopd',
  APP_DOMAIN: 'fishsale.auth.ap-northeast-1.amazoncognito.com',
  REDIRECT_DOMAIN: 'https://meatsale.jp/cognito-callback',
  GO_ENDPOINT: 'https://api.meatsale.jp',
  SITE_TYPE: 'meat',
  NODE_SLS_ENDPOINT:
    'https://vvxrj8jck0.execute-api.ap-northeast-1.amazonaws.com/prd',
  UPLOADER_ENDPOINT: 'https://upload.meatsale.jp/p',
  COGNITO_REGION: 'ap-northeast-1',
  COGNITO_USER_POOL_ID: 'ap-northeast-1_sNoYSX8Pq',
  COGNITO_CLIENT_ID: 'noup2ghqt868a9sv2cse9mopd',
  REDIRECT_URL_SIGN_IN_VENDOR: 'https://vendor.meatsale.jp/signin',
  REDIRECT_URL_VENDOR_SHIPPING_COST:
    'https://vendor.meatsale.jp/shipping-costs',
};

export const env = {
  local,
  dev,
  stg,
  prd,
};
