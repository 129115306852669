/* @flow */
import { assets, app } from '@/config';
import { constants } from '../constants';

const mappingTable = assets.delivery.prices;
const prefectures = app.prefecture.data;

// eslint-disable-next-line
export default function getDeliveryPrice(from, to) {
  try {
    const _to = prefectures[to].text;

    return mappingTable[from][_to][constants.SIZES.FREIGHT_RATE_STANDARD_SIZE];
  } catch (error) {
    return '';
  }
}
