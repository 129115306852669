import { FormLabelIcon } from '@/components/Common';
import { Field } from 'redux-form/immutable';
import React from 'react';
import { FieldDropdown } from '@/components/Form';
import { MEAT_CATEGORY } from '../config';

interface Props {
  disabled?: boolean;
}

const MeatCategory = ({ disabled }: Props) => {
  return (
    <div className="field_wrapper">
      <label className="flex">
        種類
        <FormLabelIcon value="必須" />
      </label>
      <Field
        name="category"
        className="category"
        component={FieldDropdown}
        selection
        fluid
        options={MEAT_CATEGORY}
        placeholder="選択してください"
        disabled={disabled}
      />
    </div>
  );
};

export default MeatCategory;
