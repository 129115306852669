import isEmpty from 'lodash/isEmpty';
import { getLocaleStringLocales } from '@/utils';
import { CheckValidate } from '@/modules/meat';

export default function sell(values) {
  // 金額の最低値
  const min_price = 50;
  // 金額の最大値
  const max_price = 10000001;
  // 重量の最小値
  const min_weight = 1;
  // 重量の最大値
  const max_weight = 10000000;

  /**
   * ミート固有の項目チェック
   */
  const errors = CheckValidate(values);

  const sellImages = values.get('sellImages');
  const name = values.get('name');
  const size = values.get('size');
  const weight = values.get('weight');
  const shipping_expense = values.get('shipping_expense');
  const shipping_timing = values.get('shipping_timing');
  const area = values.get('area');
  const delivery_method = values.get('delivery_method');
  const price = values.get('price');
  const fix_price = values.get('fix_price');
  const close_bid_min_date = values.get('close_bid_min_date');
  const close_bid_min_hour = values.get('close_bid_min_hour');
  const explanation = values.get('explanation');
  const shipping_size = values.get('shipping_size');

  //商品名
  if (!name) {
    errors.name = '商品名を入力してください';
  }

  if (isEmpty(sellImages)) {
    errors.sellImages = '画像を選択してください';
  }

  //サイズは任意項目
  if (size) {
    if (!/^[0-9]+$/.test(size)) {
      // 半角数字でない場合はエラーを出す
      errors.size = '半角数字を入力してください';
    } else if (/^[0-9]+$/.test(size)) {
      if (size < 1) {
        // 1未満の場合
        errors.size = '1以上の数字を入力してください';
      } else if (size > 10000) {
        // 10000超過の場合
        errors.size = '10,000以下の数字を入力してください';
      }
    }
  }

  // ここまで追加

  // 重量は任意項目
  if (weight) {
    if (!/^[0-9]+$/.test(weight)) {
      // 半角数字でない場合はエラーを出す
      errors.weight = '半角数字を入力してください';
    } else {
      const weight_amount = Number(weight);
      if (weight_amount < min_weight) {
        errors.weight = `${min_weight}以上の数字を入力してください`;
      } else if (weight_amount > max_weight) {
        errors.weight = `${getLocaleStringLocales(
          max_weight
        )}以下の数字を入力してください`;
      }
    }
  }

  // 配送料の負担
  if (shipping_expense == undefined) {
    errors.shipping_expense = '配送料の負担を入力してください';
  }

  // 配送までの日数
  if (shipping_timing == undefined) {
    errors.shipping_timing = '配送までの日数を入力してください';
  }

  // 配送元の地域
  if (area == undefined) {
    errors.area = '発送元の地域を入力してください';
  }

  // 配送方法
  if (delivery_method == undefined) {
    errors.delivery_method = '配送方法を入力してください';
  }

  // 配送方法
  if (shipping_expense == 3 && shipping_size === undefined) {
    errors.shipping_size = '配送サイズ区分を入力してください';
  }

  if (!price) {
    errors.price = '出品時の開始価格を入力してください';
  } else {
    const price_num = Number(price);
    if (price_num < min_price) {
      errors.price = `${min_price}円以上の金額を入力してください`;
    } else if (price_num >= max_price) {
      errors.price = `${getLocaleStringLocales(
        max_price - 1
      )}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(price)) {
      errors.price = '半角数字を入力してください';
    }
  }

  let start_price = min_price;
  if (price && !isNaN(Number(price)) && Number(price) > min_price) {
    start_price = Number(price);
  }

  // 即決価格は任意入力項目
  if (fix_price) {
    const fix_price_num = Number(fix_price);
    if (start_price >= max_price) {
      errors.fix_price = `出品時の開始価格を${getLocaleStringLocales(
        max_price - 1
      )}円以下の金額にしてください`;
    } else if (fix_price_num <= start_price) {
      errors.fix_price = `${getLocaleStringLocales(
        start_price + 1
      )}円以上の金額を入力してください`;
    } else if (fix_price_num > max_price) {
      errors.fix_price = `${getLocaleStringLocales(
        max_price
      )}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(fix_price)) {
      errors.fix_price = '半角数字を入力してください';
    }
  }

  // 出品期限(日付)
  if (!close_bid_min_date) {
    errors.close_bid_min_date = '出品期限を入力してください';
  } else if (close_bid_min_date) {
    if (!/^[0-9]+$/.test(close_bid_min_date)) {
      // 半角数字でない場合はエラーを出す
      errors.close_bid_min_date = '半角数字を入力してください';
    } else if (close_bid_min_date < 0 || close_bid_min_date > 11) {
      errors.close_bid_min_date = '本日 〜 10日後の間で入力してください';
    }
  }

  // 出品期限(時間)
  if (!close_bid_min_hour) {
    errors.close_bid_min_hour = '出品期限を入力してください';
  } else if (close_bid_min_hour) {
    if (!/^[0-9]+$/.test(close_bid_min_hour)) {
      // 半角数字でない場合はエラーを出す
      errors.close_bid_min_hour = '半角数字を入力してください';
    } else if (close_bid_min_date == 1) {
      const now = new Date();
      if (now.getHours() + 1 >= close_bid_min_hour) {
        errors.close_bid_min_hour = '現在時刻以降を入力してください';
      }
    } else if (close_bid_min_hour < 0 || close_bid_min_hour > 24) {
      errors.close_bid_min_hour = '0時台 〜 23時台で入力してください';
    }
  }

  // 出品商品説明
  if (explanation == undefined) {
    errors.explanation = '出品商品説明を入力してください';
  } else if (explanation.length > 1000) {
    errors.explanation = '出品商品説明は1,000文字以内で入力してください';
  }

  // // コンプライアンス
  // if (!red_list_check) {
  //   errors.red_list_check = '内容を確認して、チェックしてください';
  // }

  // if (!rare_spacies_check) {
  //   errors.rare_spacies_check = '内容を確認して、チェックしてください';
  // }

  // if (!release_check) {
  //   errors.release_check = '内容を確認して、チェックしてください';
  // }

  // if (!toxic_fish_confirm) {
  //   errors.toxic_fish_confirm = '内容を確認して、チェックしてください';
  // }

  return errors;
}
