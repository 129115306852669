import { env } from '@/config/env';

export const getEnv = () => {
  const ENV_CODE = process.env.REACT_APP_ENV_CODE;

  switch (ENV_CODE) {
    case 'local':
      return {
        ...env.local,
      };
    case 'dev':
      return {
        ...env.dev,
      };
    case 'stg':
      return {
        ...env.stg,
      };
    case 'prd':
      return {
        ...env.prd,
      };
    default:
      return {
        ...env.local,
      };
  }
};
