export default {
  data: [
    {
      text: '全ての都道府県',
      value: 'all',
      key: 'all',
    },
    {
      text: '北海道',
      value: 'hokkaido',
      key: 'hokkaido',
    },
    {
      text: '青森県',
      value: 'aomori',
      key: 'aomori',
    },
    {
      text: '岩手県',
      value: 'iwate',
      key: 'iwate',
    },
    {
      text: '宮城県',
      value: 'miyagi',
      key: 'miyagi',
    },
    {
      text: '秋田県',
      value: 'akita',
      key: 'akita',
    },
    {
      text: '山形県',
      value: 'yamagata',
      key: 'yamagata',
    },
    {
      text: '福島県',
      value: 'fukushima',
      key: 'fukushima',
    },
    {
      text: '茨城県',
      value: 'ibaraki',
      key: 'ibaraki',
    },
    {
      text: '栃木県',
      value: 'tochigi',
      key: 'tochigi',
    },
    {
      text: '群馬県',
      value: 'gunma',
      key: 'gunma',
    },
    {
      text: '埼玉県',
      value: 'saitama',
      key: 'saitama',
    },
    {
      text: '千葉県',
      value: 'chiba',
      key: 'chiba',
    },
    {
      text: '東京都',
      value: 'tokyo',
      key: 'tokyo',
    },
    {
      text: '神奈川県',
      value: 'kanagawa',
      key: 'kanagawa',
    },
    {
      text: '新潟県',
      value: 'nigata',
      key: 'nigata',
    },
    {
      text: '富山県',
      value: 'toyama',
      key: 'toyama',
    },
    {
      text: '石川県',
      value: 'ishikawa',
      key: 'ishikawa',
    },
    {
      text: '福井県',
      value: 'fukui',
      key: 'fukui',
    },
    {
      text: '山梨県',
      value: 'yamanashi',
      key: 'yamanashi',
    },
    {
      text: '長野県',
      value: 'nagano',
      key: 'nagano',
    },
    {
      text: '岐阜県',
      value: 'gifu',
      key: 'gifu',
    },
    {
      text: '静岡県',
      value: 'shizuoka',
      key: 'shizuoka',
    },
    {
      text: '愛知県',
      value: 'aichi',
      key: 'aichi',
    },
    {
      text: '三重県',
      value: 'mie',
      key: 'mie',
    },
    {
      text: '滋賀県',
      value: 'shiga',
      key: 'shiga',
    },
    {
      text: '京都府',
      value: 'kyoto',
      key: 'kyoto',
    },
    {
      text: '大阪府',
      value: 'osaka',
      key: 'osaka',
    },
    {
      text: '兵庫県',
      value: 'hyogo',
      key: 'hyogo',
    },
    {
      text: '奈良県',
      value: 'nara',
      key: 'nara',
    },
    {
      text: '和歌山県',
      value: 'wakayama',
      key: 'wakayama',
    },
    {
      text: '鳥取県',
      value: 'tottori',
      key: 'tottori',
    },
    {
      text: '島根県',
      value: 'shimane',
      key: 'shimane',
    },
    {
      text: '岡山県',
      value: 'okayama',
      key: 'okayama',
    },
    {
      text: '広島県',
      value: 'hiroshima',
      key: 'hiroshima',
    },
    {
      text: '山口県',
      value: 'yamaguchi',
      key: 'yamaguchi',
    },
    {
      text: '徳島県',
      value: 'tokushima',
      key: 'tokushima',
    },
    {
      text: '香川県',
      value: 'kagawa',
      key: 'kagawa',
    },
    {
      text: '愛媛県',
      value: 'ehime',
      key: 'ehime',
    },
    {
      text: '高知県',
      value: 'kochi',
      key: 'kochi',
    },
    {
      text: '福岡県',
      value: 'fukuoka',
      key: 'fukuoka',
    },
    {
      text: '佐賀県',
      value: 'saga',
      key: 'saga',
    },
    {
      text: '長崎県',
      value: 'nagasaki',
      key: 'nagasaki',
    },
    {
      text: '熊本県',
      value: 'kumamoto',
      key: 'kumamoto',
    },
    {
      text: '大分県',
      value: 'oita',
      key: 'oita',
    },
    {
      text: '宮崎県',
      value: 'miyazaki',
      key: 'miyazaki',
    },
    {
      text: '鹿児島県',
      value: 'kagoshima',
      key: 'kagoshima',
    },
    {
      text: '沖縄県',
      value: 'okinawa',
      key: 'okinawa',
    },
  ],
  saleData: [
    {
      text: '北海道',
      value: 1,
      key: '1',
    },
    {
      text: '青森県',
      value: 2,
      key: '2',
    },
    {
      text: '岩手県',
      value: 3,
      key: '3',
    },
    {
      text: '宮城県',
      value: 4,
      key: '4',
    },
    {
      text: '秋田県',
      value: 5,
      key: '5',
    },
    {
      text: '山形県',
      value: 6,
      key: '6',
    },
    {
      text: '福島県',
      value: 7,
      key: '7',
    },
    {
      text: '茨城県',
      value: 8,
      key: '8',
    },
    {
      text: '栃木県',
      value: 9,
      key: '9',
    },
    {
      text: '群馬県',
      value: 10,
      key: '10',
    },
    {
      text: '埼玉県',
      value: 11,
      key: '11',
    },
    {
      text: '千葉県',
      value: 12,
      key: '12',
    },
    {
      text: '東京都',
      value: 13,
      key: '13',
    },
    {
      text: '神奈川県',
      value: 14,
      key: '14',
    },
    {
      text: '新潟県',
      value: 15,
      key: '15',
    },
    {
      text: '富山県',
      value: 16,
      key: '16',
    },
    {
      text: '石川県',
      value: 17,
      key: '17',
    },
    {
      text: '福井県',
      value: 18,
      key: '18',
    },
    {
      text: '山梨県',
      value: 19,
      key: '19',
    },
    {
      text: '長野県',
      value: 20,
      key: '20',
    },
    {
      text: '岐阜県',
      value: 21,
      key: '21',
    },
    {
      text: '静岡県',
      value: 22,
      key: '22',
    },
    {
      text: '愛知県',
      value: 23,
      key: '23',
    },
    {
      text: '三重県',
      value: 24,
      key: '24',
    },
    {
      text: '滋賀県',
      value: 25,
      key: '25',
    },
    {
      text: '京都府',
      value: 26,
      key: '26',
    },
    {
      text: '大阪府',
      value: 27,
      key: '27',
    },
    {
      text: '兵庫県',
      value: 28,
      key: '28',
    },
    {
      text: '奈良県',
      value: 29,
      key: '29',
    },
    {
      text: '和歌山県',
      value: 30,
      key: '30',
    },
    {
      text: '鳥取県',
      value: 31,
      key: '31',
    },
    {
      text: '島根県',
      value: 32,
      key: '32',
    },
    {
      text: '岡山県',
      value: 33,
      key: '33',
    },
    {
      text: '広島県',
      value: 34,
      key: '34',
    },
    {
      text: '山口県',
      value: 35,
      key: '35',
    },
    {
      text: '徳島県',
      value: 36,
      key: '36',
    },
    {
      text: '香川県',
      value: 37,
      key: '37',
    },
    {
      text: '愛媛県',
      value: 38,
      key: '38',
    },
    {
      text: '高知県',
      value: 39,
      key: '39',
    },
    {
      text: '福岡県',
      value: 40,
      key: '40',
    },
    {
      text: '佐賀県',
      value: 41,
      key: '41',
    },
    {
      text: '長崎県',
      value: 42,
      key: '42',
    },
    {
      text: '熊本県',
      value: 43,
      key: '43',
    },
    {
      text: '大分県',
      value: 44,
      key: '44',
    },
    {
      text: '宮崎県',
      value: 45,
      key: '45',
    },
    {
      text: '鹿児島県',
      value: 46,
      key: '46',
    },
    {
      text: '沖縄県',
      value: 47,
      key: '47',
    },
  ],
  searchData: [
    {
      text: '全ての都道府県 (発送元の地域)',
      value: '0',
      key: '0',
    },
    {
      text: '北海道',
      value: '1',
      key: '1',
    },
    {
      text: '青森県',
      value: '2',
      key: '2',
    },
    {
      text: '岩手県',
      value: '3',
      key: '3',
    },
    {
      text: '宮城県',
      value: '4',
      key: '4',
    },
    {
      text: '秋田県',
      value: '5',
      key: '5',
    },
    {
      text: '山形県',
      value: '6',
      key: '6',
    },
    {
      text: '福島県',
      value: '7',
      key: '7',
    },
    {
      text: '茨城県',
      value: '8',
      key: '8',
    },
    {
      text: '栃木県',
      value: '9',
      key: '9',
    },
    {
      text: '群馬県',
      value: '10',
      key: '10',
    },
    {
      text: '埼玉県',
      value: '11',
      key: '11',
    },
    {
      text: '千葉県',
      value: '12',
      key: '12',
    },
    {
      text: '東京都',
      value: '13',
      key: '13',
    },
    {
      text: '神奈川県',
      value: '14',
      key: '14',
    },
    {
      text: '新潟県',
      value: '15',
      key: '15',
    },
    {
      text: '富山県',
      value: '16',
      key: '16',
    },
    {
      text: '石川県',
      value: '17',
      key: '17',
    },
    {
      text: '福井県',
      value: '18',
      key: '18',
    },
    {
      text: '山梨県',
      value: '19',
      key: '19',
    },
    {
      text: '長野県',
      value: '20',
      key: '20',
    },
    {
      text: '岐阜県',
      value: '21',
      key: '21',
    },
    {
      text: '静岡県',
      value: '22',
      key: '22',
    },
    {
      text: '愛知県',
      value: '23',
      key: '23',
    },
    {
      text: '三重県',
      value: '24',
      key: '24',
    },
    {
      text: '滋賀県',
      value: '25',
      key: '25',
    },
    {
      text: '京都府',
      value: '26',
      key: '26',
    },
    {
      text: '大阪府',
      value: '27',
      key: '27',
    },
    {
      text: '兵庫県',
      value: '28',
      key: '28',
    },
    {
      text: '奈良県',
      value: '29',
      key: '29',
    },
    {
      text: '和歌山県',
      value: '30',
      key: '30',
    },
    {
      text: '鳥取県',
      value: '31',
      key: '31',
    },
    {
      text: '島根県',
      value: '32',
      key: '32',
    },
    {
      text: '岡山県',
      value: '33',
      key: '33',
    },
    {
      text: '広島県',
      value: '34',
      key: '34',
    },
    {
      text: '山口県',
      value: '35',
      key: '35',
    },
    {
      text: '徳島県',
      value: '36',
      key: '36',
    },
    {
      text: '香川県',
      value: '37',
      key: '37',
    },
    {
      text: '愛媛県',
      value: '38',
      key: '38',
    },
    {
      text: '高知県',
      value: '39',
      key: '39',
    },
    {
      text: '福岡県',
      value: '40',
      key: '40',
    },
    {
      text: '佐賀県',
      value: '41',
      key: '41',
    },
    {
      text: '長崎県',
      value: '42',
      key: '42',
    },
    {
      text: '熊本県',
      value: '43',
      key: '43',
    },
    {
      text: '大分県',
      value: '44',
      key: '44',
    },
    {
      text: '宮崎県',
      value: '45',
      key: '45',
    },
    {
      text: '鹿児島県',
      value: '46',
      key: '46',
    },
    {
      text: '沖縄県',
      value: '47',
      key: '47',
    },
  ],
};
