const harvestTiming = [
  {
    key: '1',
    value: 1,
    name: 'harvest_timing1',
    text: '早朝',
  },
  {
    key: '2',
    value: 2,
    name: 'harvest_timing2',
    text: '早',
  },
  {
    key: '3',
    value: 3,
    name: 'harvest_timing3',
    text: '昼',
  },
  {
    key: '4',
    value: 4,
    name: 'harvest_timing4',
    text: '夕',
  },
  {
    key: '5',
    value: 5,
    name: 'harvest_timing5',
    text: '夜',
  },
];

export const harvestTimingNames = harvestTiming.map(({ name }) => name);

export default harvestTiming;
