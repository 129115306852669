import React from 'react';
import { Route, Switch } from 'react-router';

import { MatchPathRedirect } from '../MatchPathRedirect';
import { paths } from '@/config';
import { AfterRoutes } from '../AfterRoutes';
import {
  CognitoCallbackRoutes,
  ConfirmResetPasswordRoutes,
  ConfirmSignUpRoutes,
  ContactRoutes,
  FishesRoutes,
  IntroductionRoutes,
  LandingRoutes,
  NotFoundRoutes,
  ResetPasswordRoutes,
  SaleRoutes,
  UserFollowRoutes,
  UserProfileRoutes,
  SignInRoutes,
  SignUpRoutes,
  ChangeEmailRoutes,
  FeeRoutes,
  MediaPublicationRoutes,
} from '@/components/Routes/Children';

import { Footer, Header } from '@/components/Layouts';
import enhance from './enhancer';

function Routes(props) {
  const { router } = props;
  const location = router.location;

  if (window.isAuthed) {
    return <AfterRoutes />;
  }

  const blackLists = [
    paths.cognitocallback,
    paths.before.signup,
    paths.before.signin,
    paths.before.resetPassword,
    paths.before.confirmPassword,
    window.location.pathname.includes(paths.before.signup_confirm)
      ? window.location.pathname
      : '',
    window.location.pathname.includes(paths.common.confirmEmail)
      ? window.location.pathname
      : '',
  ];

  return (
    <div className="a__before_routes">
      {blackLists.find(
        (blackPath) => blackPath === window.location.pathname
      ) ? null : (
        <Header />
      )}

      <Switch location={location}>
        <Route path={paths.common.confirmEmail} component={ChangeEmailRoutes} />
        <Route
          strict
          path={paths.cognitocallback}
          component={CognitoCallbackRoutes}
        />

        <Route exact path={paths.fishes.all} component={FishesRoutes} />
        <Route exact path={paths.fishes.company} component={FishesRoutes} />
        <Route exact path={paths.fishes.personal} component={FishesRoutes} />

        <Route
          exact
          path={paths.before.confirmPassword}
          component={ConfirmResetPasswordRoutes}
        />

        <Route strict path={paths.common.contact} component={ContactRoutes} />

        <Route exact path={paths.fishes.root} redirectUrl={paths.fishes.all} />

        <Route
          strict
          path={paths.introduction.root}
          component={IntroductionRoutes}
        />

        <Route exact path={paths.landing} component={LandingRoutes} />

        <Route
          strict
          path={paths.mypage.root}
          render={(props) => {
            return (
              <MatchPathRedirect {...props} redirectUrl={paths.before.signin} />
            );
          }}
        />

        <Route
          strict
          path={paths.mypage.credit_register}
          render={(props) => {
            return (
              <MatchPathRedirect {...props} redirectUrl={paths.before.signin} />
            );
          }}
        />

        <Route
          exact
          path={paths.before.resetPassword}
          component={ResetPasswordRoutes}
        />

        <Route strict path={paths.fishes.saleItem} component={SaleRoutes} />

        <Route
          strict
          path={paths.sell}
          render={(props) => {
            return (
              <MatchPathRedirect {...props} redirectUrl={paths.before.signin} />
            );
          }}
        />

        <Route strict path={paths.before.signin} component={SignInRoutes} />

        <Route exact path={paths.before.signup} component={SignUpRoutes} />

        <Route
          exact
          path={paths.before.signup_confirm}
          component={ConfirmSignUpRoutes}
        />

        <Route
          exact
          path={paths.before.signUpThank}
          render={(props) => {
            return (
              <MatchPathRedirect {...props} redirectUrl={paths.before.signin} />
            );
          }}
        />

        <Route
          strict
          path={paths.stripecallback}
          render={(props) => {
            return (
              <MatchPathRedirect {...props} redirectUrl={paths.before.signin} />
            );
          }}
        />

        <Route strict path={paths.users.follow} component={UserFollowRoutes} />

        <Route
          strict
          path={paths.users.profile}
          component={UserProfileRoutes}
        />

        <Route component={FeeRoutes} exact={true} path={paths.common.fee} />
        <Route
          component={MediaPublicationRoutes}
          path={paths.common.mediaPublication}
          strict
        />
        <Route component={NotFoundRoutes} />
      </Switch>

      {blackLists.find(
        (blackPath) => blackPath === window.location.pathname
      ) ? null : (
        <Footer />
      )}
    </div>
  );
}

export default enhance((props) => <Routes {...props} />);
