import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers } from 'recompose';
import { appAction } from '@/actions';
import { bindActionCreators } from 'redux';
import { paths } from '@/config';

const mapStateToProps = (state) => ({
  users: state.users.toJS(),
  router: state.router,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...appAction }, dispatch);

const enhance = compose(
  setDisplayName('NavBar'),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleResetHeaderText: (props) => () => {
      if (!(props.router.location.pathname === paths.fishes.root)) {
        props.resetHeaderText();
      }
    },
  })
);

export default enhance;
