import { formatDate } from '@/apis/utils';

export default function parseSale({
  sale_id,
  bought_user_id,
  serller_name,
  seller_company,
  seller_user_id,
  size,
  weight,
  weight_additional_info,
  area_text,
  spot_text,
  hide_fishing_spot,
  fixed,
  method_text,
  water_text,
  expense_text,
  timing_text,
  fix_price,
  close_bid_at,
  posted_at,
  price,
  bid,
  bid_count,
  bidder_user_id,
  bidder_name,
  fish_kind,
  explanation,
  images,
  closed,
  closed_reason,
  closed_at,
  shippingMethod_text,
  year_text,
  month_text,
  day_text,
  ikijime_timing_text,
  bought_user_name,
  display_price,
  is_nature,
  is_company,
  shipping_expense,
  comment_add,
  image_add,
  harvest_date,
  // 追加
  name,
}) {
  const nextCloseBidAt = formatDate(close_bid_at);
  const nextPostedAt = formatDate(posted_at);
  const nextClosedAt = formatDate(closed_at);
  const nextHarvestDate = formatDate(harvest_date);

  let nextImageURL = '';

  // imagesの長さが0よりも大きければ配列の0番目を代入する
  if (images && images.length > 0) {
    nextImageURL = images[0];
  }

  // TODO:値調整
  return Object.assign(
    {},
    {
      id: sale_id,
      boughtUserId: bought_user_id,
      sellerName: serller_name,
      sellerCompany: seller_company,
      sellerUserId: seller_user_id,
      size,
      weight,
      weightAdditionalInfo: weight_additional_info,
      area: area_text,
      // fishingSpot: spot_text,
      // hideFishingSpot: hide_fishing_spot,
      fixed,
      methodText: method_text,
      // waterText: water_text,
      expenseText: expense_text,
      // timingText: timing_text,
      fixPrice: fix_price,
      closeBidAt: nextCloseBidAt,
      closeBidAtTemp: close_bid_at,
      postedAt: nextPostedAt,
      price,
      bid,
      bidCount: bid_count,
      bidderUserId: bidder_user_id,
      bidderName: bidder_name,
      // fishKind: fish_kind,
      explanation,
      imageURL: nextImageURL,
      images,
      closed,
      closedReason: closed_reason,
      closedAt: nextClosedAt,
      shippingMethodText: shippingMethod_text,
      yearText: year_text,
      monthText: month_text,
      dayText: day_text,
      // ikijimeTimingText: ikijime_timing_text,
      boughtUserName: bought_user_name,
      displayPrice: display_price,
      // isNature: is_nature,
      isCompany: is_company,
      shippingExpense: shipping_expense,
      commentAdd: comment_add,
      imageAdd: image_add,
      harvestDate: nextHarvestDate,
      name: name,
    }
  );
}
