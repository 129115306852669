import * as React from 'react';
import enhance from './enhancer';
import { ReviewList, TitleBar } from '@/components/Common';
import { Loader } from 'semantic-ui-react';
import Pagination from '@/migration/pagination';

function Review(props) {
  const {
    isLoading,
    users: {
      mydata: { averagePoint, totalEvaluationCount },
      review: { pages, current },
    },
    onChangePage,
    isPaginationDisabled,
  } = props;

  return (
    <React.Fragment>
      <TitleBar title="評価一覧" />

      {pages.active.length === 0 || isLoading ? (
        <div className="my_review_loader">
          <Loader active={isLoading} inline="centered" />
          {!isLoading ? <span>まだ評価はありません</span> : null}
        </div>
      ) : (
        <div className="my_review">
          <ReviewList
            averagePoint={averagePoint}
            totalEvaluationCount={totalEvaluationCount}
            data={pages.active}
            current={current}
          />
          <Pagination
            totalCount={totalEvaluationCount}
            current={current}
            pageSize={5}
            changePage={onChangePage}
            disabled={isPaginationDisabled}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default enhance((props) => <Review {...props} />);
