import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { paths } from '@/config';
import enhance from './enhancer';

function CommonRoutes() {
  return (
    <div className="a__common_routes">
      <Switch>
        <Route
          exact
          path={paths.landing}
          render={() => {
            return <div />;
          }}
        />
      </Switch>
    </div>
  );
}

export default enhance((props) => <CommonRoutes {...props} />);
