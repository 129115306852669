import qs from 'qs';
import find from 'lodash/find';

/**
 * クエリパラメーターをオブジェクトに変換する
 * @param queryParams クエリパラメーター
 */

export const parse = (queryParams) => {
  const query = String(queryParams);
  const res = qs.parse(query.substr(1));

  return res;
};

/**
 * クエリパラメーターに入力された値が対象の配列の中に存在するか判定する
 * @param param クエリパラメーター
 * @param mappings 対象の配列
 */

export const isValid = (param, mappings) => {
  const target = find(mappings, (mapping) => mapping.value === param);
  return !!target;
};

/**
 * 対象の配列からvalueを取得する
 * @param param クエリパラメーター
 * @param mappings 対象の配列
 */

export const getValue = (param, mappings) => {
  const target = find(mappings, (mapping) => mapping.key === param);
  // 存在しない値を入力された場合、デフォルトを返す
  return target && target.value ? target.value : '-biddable-fixed-created_at';
};

/**
 * 対象の配列からkeyを取得する
 * @param param クエリパラメーター
 * @param mappings 対象の配列
 */
export const getKey = (param, mappings) => {
  const target = find(mappings, (mapping) => mapping.value === param);
  return target && target.key ? target.key : 'from_new_arrival';
};

export const toString = (query) => {
  return qs.stringify(query);
};
