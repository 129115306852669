import * as React from 'react';
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from '@/components/Common';
import enhance from './enhancer';

function SnsIconRow(props) {
  const {
    sales: {
      item: { explanation },
    },
  } = props;

  return (
    <div className="a__sns_icon_row">
      <FacebookShareButton type="desktop" />
      <TwitterShareButton type="desktop" explanation={explanation} />
      <LineShareButton type="desktop" />
    </div>
  );
}

export default enhance((props) => <SnsIconRow {...props} />);
