import { Auth, getEnv } from '@/utils';
import { base } from '../../utils';

export const deleteUser = async (id) => {
  const token = await Auth.getIdToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const { SITE_TYPE } = getEnv();
    const { data } = await base.delete(`/v1/${SITE_TYPE}/users/${id}`, {
      headers,
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
