import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { salesAction } from '@/actions';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction }, dispatch);

const enhance = compose(
  setDisplayName('SnsIconRow'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
