import * as React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import classNames from 'classnames';
import TweenLite from 'gsap';
import 'gsap/ScrollToPlugin';
import { Button } from 'semantic-ui-react';
import { is, Map } from 'immutable';

const propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
};

const defaultProps = {
  initialPage: 1,
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  // eslint-disable-next-line
  componentWillMount() {
    // set page if items array isn't empty
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !is(Map(this.props.items), Map(nextProps.items)) ||
      !is(Map(this.state.pager), Map(nextState.pager))
    );
  }

  componentDidUpdate(prevProps) {
    // reset page if items array has changed
    if (!is(Map(this.props.items), Map(prevProps.items))) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    const items = this.props.items;

    let pager = this.state.pager;

    // eslint-disable-next-line
    let pageSize = this.props.pageSize;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page, pageSize);

    // get new page of items from items array
    const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfItems);
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
      // more than 10 total pages so calculate start and end pages
    } else if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  render() {
    const pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <ul className="app_pagination flex">
        <li
          className={classNames({
            disabled: pager.currentPage === 1,
            first: true,
          })}
        >
          <Button
            color="yellow"
            onClick={() => {
              // ページネート押下でTopへ戻る
              TweenLite.to(window, 0.1, {
                scrollTo: '.routes',
              });
              this.setPage(1);
            }}
          >
            最初へ
          </Button>
        </li>
        <li
          className={classNames({
            disabled: pager.currentPage === 1,
            previous: true,
          })}
        >
          <Button
            color="yellow"
            onClick={() => {
              // ページネート押下でTopへ戻る
              TweenLite.to(window, 0.1, {
                scrollTo: '.routes',
              });
              this.setPage(pager.currentPage - 1);
            }}
          >
            前へ
          </Button>
        </li>
        {pager.pages.map((page, index) => (
          <li
            key={index}
            className={classNames({
              active: pager.currentPage === page,
              item: true,
            })}
          >
            <Button
              color="yellow"
              onClick={() => {
                // ページネート押下でTopへ戻る
                TweenLite.to(window, 0.1, {
                  scrollTo: '.routes',
                });
                this.setPage(page);
              }}
            >
              {page}
            </Button>
          </li>
        ))}
        <li
          className={classNames({
            disabled: pager.currentPage === pager.totalPages,
            next: true,
          })}
        >
          <Button
            color="yellow"
            onClick={() => {
              // ページネート押下でTopへ戻る
              TweenLite.to(window, 0.1, {
                scrollTo: '.routes',
              });
              this.setPage(pager.currentPage + 1);
            }}
          >
            次へ
          </Button>
        </li>
        <li
          className={classNames({
            disabled: pager.currentPage === pager.totalPages,
            last: true,
          })}
        >
          <Button
            color="yellow"
            onClick={() => {
              // ページネート押下でTopへ戻る
              TweenLite.to(window, 0.1, {
                scrollTo: '.routes',
              });
              this.setPage(pager.totalPages);
            }}
          >
            最後へ
          </Button>
        </li>
      </ul>
    );
  }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;
