import axios from 'axios';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

import { Auth } from '@/utils';

const resouces = '/users';

/** 新しいメールアドレスを作成 */
/** 更新後のメール送信まで行う */
export const createNewEmail = async (userId, body) => {
  try {
    const token = await Auth.getIdToken();

    const { data } = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .post(`${GO_ENDPOINT}/v1/${SITE_TYPE}${resouces}/${userId}/email`, body);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
