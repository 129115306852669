import { connect } from 'react-redux';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { appAction } from '@/actions';
const mapStateToProps = (state) => ({
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...appAction }, dispatch);

const enhance = compose(
  setDisplayName('Footer'),
  connect(mapStateToProps, mapDispatchToProps),
  onlyUpdateForKeys(['app'])
);

export default enhance;
