import parseFailed from '@/apis/utils/parseFailed';

import { getEnv } from '@/utils';

const { NODE_SLS_ENDPOINT } = getEnv();

async function postSendMail(name, email, content) {
  try {
    const response = await fetch(`${NODE_SLS_ENDPOINT}/sendmail`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        email,
        content,
      }),
    });
    const responseJson = await response.json();

    if (response.status === 200) {
      return true;
    }

    throw parseFailed(responseJson);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default postSendMail;
