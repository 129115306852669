import * as React from 'react';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';
import { getLocaleStringLocales } from '@/utils';
import { replaceTofu } from '@/utils/string';

export default function BidInfoRow(props) {
  const { itemBids, fixed } = props;

  return (
    <div>
      {itemBids.map((bid, index) => {
        const postedAt = moment(bid.postedAt).format(
          'YYYY. MM. DD  (dd) HH:mm'
        );

        if (fixed && index === 0) {
          return (
            <div className="a__bid_info_row" key={uuid()}>
              <div className="w__notice-chat">
                {bid.boughtUserId === bid.user_id ? (
                  <div>
                    <span className="w__date">{postedAt}</span>
                    <span className="buyer">
                      {replaceTofu(bid.displayName)}
                    </span>
                    さんが¥
                    {getLocaleStringLocales(bid.price)}
                    で落札しました！
                  </div>
                ) : null}
              </div>
            </div>
          );
        } else {
          return (
            <div className="a__bid_info_row" key={uuid()}>
              <div className="w__notice-chat">
                <span className="w__date">{postedAt}</span>
                <span className="buyer">{replaceTofu(bid.displayName)}</span>
                さんが¥
                {bid.boughtUserId === bid.user_id &&
                bid.boughtUserId !== bid.bidderUserId ? (
                  // 購入者IDとユーザーIDが一致、購入者IDと最終入札者IDが違う場合繰り上がり
                  <>
                    {getLocaleStringLocales(bid.price)}
                    で落札しました!
                  </>
                ) : (
                  <>
                    {getLocaleStringLocales(bid.price)}
                    で入札しました!
                  </>
                )}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}
