import * as React from 'react';
import { Link } from 'react-router-dom';

import { TitleBar } from '@/components/Common';
import { paths } from '@/config';

function RegisterPayment(props) {
  return (
    <div>
      {props.code === 'accountAndCardAndIsCompany' ? (
        <>
          <TitleBar title="商品を出品するには" />
          <div className="register-process">
            <div className="process-list">
              <div className="process-content">
                1.
                <Link to={paths.mypage.edit}>&nbsp;事業者登録</Link>
                してください。
              </div>
              <div className="process-content">
                2.
                <Link to={paths.mypage.credit_register}>
                  &nbsp;クレジットカード・デビットカード情報を登録
                </Link>
                してください。
              </div>
              <div className="process-content">
                3.
                <Link to={paths.mypage.bank_account}>
                  &nbsp;売上げ受取口座情報を登録
                </Link>
                してください。
              </div>
              <div className="process-content">
                4. 写真・詳細情報を入力してオークションにカンタン出品！
              </div>
              <div className="process-remarks">
                ※ カード・口座の情報は2回目以降も保持されます
              </div>
            </div>
          </div>
        </>
      ) : props.code === 'accountAndCard' ? (
        <>
          <TitleBar title="商品を出品するには" />
          <div className="register-process">
            <div className="process-list">
              <div className="process-content">
                1.
                <Link to={paths.mypage.credit_register}>
                  &nbsp;クレジットカード・デビットカード情報を登録
                </Link>
                してください。
              </div>
              <div className="process-content">
                2.
                <Link to={paths.mypage.bank_account}>
                  &nbsp;売上げ受取口座情報を登録
                </Link>
                してください。
              </div>
              <div className="process-content">
                3. 写真・詳細情報を入力してオークションにカンタン出品！
              </div>
              <div className="process-remarks">
                ※ カード・口座の情報は2回目以降も保持されます
              </div>
            </div>
          </div>
        </>
      ) : props.code === 'card' ? (
        <div className="selling-requirements">
          <div>クレジットカード・デビットカードが登録されていません。</div>
          <div>
            出品するには、マイページより
            <Link to={paths.mypage.credit_register}>
              クレジットカード・デビットカードの登録
            </Link>
            が必要です。
          </div>
        </div>
      ) : props.code === 'account' ? (
        <div className="selling-requirements">
          <div>売上げ受取口座が登録されていません。</div>
          <div>
            出品するには、マイページより
            <Link to={paths.mypage.bank_account}>売上げ受取口座の登録</Link>
            が必要です。
          </div>
        </div>
      ) : props.code === 'isCompany' ? (
        <div className="selling-requirements">
          <div>事業者登録がされていません</div>
          <div>
            出品するには、マイページより
            <Link to={paths.mypage.edit}>事業者登録</Link>
            が必要です。
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default RegisterPayment;
