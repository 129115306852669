import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { Privacy, Terms } from '@/components/Pages';
import enhance from './enhancer';

function PolicyRoutes() {
  return (
    <>
      <Route strict path={paths.policy.privacy} component={Privacy} />
      <Route strict path={paths.policy.terms} component={Terms} />
    </>
  );
}

export default enhance((props) => <PolicyRoutes {...props} />);
