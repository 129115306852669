import { createActions } from 'redux-actions';

export default createActions({
  holdSignUpSteps: (values) => values,

  updateHeaderText: (text) => ({ data: text }),

  resetHeaderText: () => null,

  setTempEmail: (email) => ({ data: email }),
});
