import * as React from 'react';
import { Radio } from 'semantic-ui-react';
import enhance from './enhance';

function FieldRadio(props) {
  const { meta } = props;

  return (
    <div className="field-group radio">
      <Radio
        label={props.label}
        onChange={props.changeToggle}
        checked={props.input.value === props.label}
        className={props.className}
        name={props.input.name}
      />
      {meta.touched && meta.error && (
        <div className="redux_form error">{meta.error}</div>
      )}
    </div>
  );
}

export default enhance((props) => <FieldRadio {...props} />);
