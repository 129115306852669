// eslint-disable @typescript-eslint/camelcase
export default function parseSaleBid({
  sale_id,
  user_id,
  body,
  price,
  display_name,
  posted_at,
  bought_user_id,
  bidder_user_id,
  harvest_date,
}) {
  return Object.assign(
    {},
    {
      sale_id,
      user_id,
      body,
      price,
      displayName: display_name,
      postedAt: posted_at,
      boughtUserId: bought_user_id,
      bidderUserId: bidder_user_id,
      harvestDate: harvest_date,
    }
  );
}
