import * as React from 'react';
import { Field } from 'redux-form/immutable';
import { Button, Image } from 'semantic-ui-react';

import { TitleBar } from '@/components/Common';
import { FieldInput } from '../../Form';
import enhance from './enhancer';
import { images } from '@/config';

function ResetPassword(props) {
  const { isLoading, resetPassword, handleSubmit } = props;

  return (
    <>
      {/* TODO: β版終了後、headerを削除する */}
      <header className="app_header flex-beta">
        <Image src={images.logo_header} className="logo" />
      </header>
      <div className="wrapper-beta-reset-password">
        <div
          className="app_reset_password flex"
          onSubmit={handleSubmit(resetPassword)}
        >
          <TitleBar title="パスワードの再設定" />

          <form className="flex">
            <span className="confirm_message">
              メールアドレスをご入力ください。
              <br />
              パスワード再設定に必要な情報をメール致します。
            </span>
            <div className="amplify-container">
              <Field
                name="username"
                className="inp_code"
                placeholder="メールアドレスを入力ください"
                component={FieldInput}
                disabled={isLoading}
                type="email"
                autoCorrect="off"
                autoCapitalize="off"
              />
              <Button
                loading={isLoading}
                disabled={isLoading}
                content="パスワード再設定用のメールを送る"
                className="btn_submit"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default enhance((props) => <ResetPassword {...props} />);
