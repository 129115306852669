// eslint-disable @typescript-eslint/camelcase
import React from 'react';
import Notification from 'rc-notification';
import classNames from 'classnames';

import { notification as uNotification } from '@/utils';
import { style } from './style';

let notification = null;
Notification.newInstance({}, (n) => {
  notification = n;
});

function GcNotification(
  message,
  type = 'gemcook',
  onClose = null,
  duration = 2,
  closable = true,
  data,
  handleClose
) {
  let bgColor;
  let color;

  switch (type) {
    case 'default': {
      bgColor = '#ECF0F1';
      color = '#2C3E50';
      break;
    }
    case 'success': {
      bgColor = '#A3DE83';
      color = '#239D60';
      break;
    }
    case 'info': {
      bgColor = '#84B9EF';
      color = '#0960BD';
      break;
    }
    case 'error': {
      bgColor = '#FF847C';
      color = '#C9182B';
      break;
    }
    case 'warning': {
      bgColor = '#FFC97C';
      color = '#EA7659';
      break;
    }
    case 'black': {
      bgColor = '#393E46';
      color = '#F2F2F2';
      break;
    }
    case 'gemcook': {
      bgColor = '#F95B76';
      color = '#fff';
      break;
    }
    case 'facebook': {
      bgColor = '#3B5998';
      color = '#fff';
      break;
    }
    case 'twitter': {
      bgColor = '#1DA1F2';
      color = '#fff';
      break;
    }
    case 'airbnb': {
      bgColor = '#FF5A60';
      color = '#fff';
      break;
    }
    case 'instagram': {
      bgColor = '#262626';
      color = '#fff';
      break;
    }
    case 'snapchat': {
      bgColor = '#FFFC00';
      color = '#fff';
      break;
    }
    case 'reddit': {
      bgColor = '#ff5700';
      color = '#fff';
      break;
    }
    case 'medium': {
      bgColor = '#1A1919';
      color = '#fff';
      break;
    }
    case 'google': {
      bgColor = '#cc3732';
      color = '#fff';
      break;
    }
    case 'pinterest': {
      bgColor = '#BD081C';
      color = '#fff';
      break;
    }
    case 'line': {
      bgColor = '#5ae628';
      color = '#fff';
      break;
    }
    case 'spotify': {
      bgColor = '#84BD00';
      color = '#fff';
      break;
    }
    case 'mixi': {
      bgColor = '#d1ad59';
      color = '#fff';
      break;
    }
    default: {
      break;
    }
  }

  notification.notice({
    content: (
      <pre
        className={classNames('a__notification', {
          gc__notification: true,
        })}
        style={style}
      >
        {message}
      </pre>
    ),
    style: {
      backgroundColor: bgColor,
      color,
      fontSize: '18px',
      fontWeight: 'bold',
      right: '50%',
    },
    duration,
    closable,
    onClose() {
      if (onClose) {
        async () => {
          await uNotification.readNews(data.id);
        };
      }
      handleClose(data.id);
    },
    maxCount: 2,
  });
}

export default GcNotification;
