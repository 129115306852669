import { Auth } from '@/utils';
import { isHttpStatusOK } from '@/apis/utils';
import { parseUser } from './utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function getMe() {
  let headers = {};
  try {
    const token = await Auth.getIdToken();

    headers = {
      Authorization: `Bearer ${token}`,
    };
  } catch (error) {
    headers = {};
  }

  // eslint-disable-next-line
  try {
    const response = await fetch(`${GO_ENDPOINT}/v1/${SITE_TYPE}/me`, {
      method: 'GET',
      headers,
    });
    const json = await response.json();

    if (isHttpStatusOK(response.status)) {
      window.isAuthed = await Auth.checkSession();
      const response = parseUser(json);

      return response;
    }

    throw new Error(response);
  } catch (error) {
    throw error;
  }
}

export default getMe;
