import React from 'react';

const SalesAccount = () => {
  return (
    <div className="a__introduction_sales_account">
      <div className="w__introduction_sales_account">
        <header>Stripeについて</header>
        <div className="stripe_explain">
          売上代金を受け取るためにStripe（決済代行会社）で口座登録をする際に、ビジネスとしてどの様に活用するのか複数の設問があるので、MeatSaleを含む運営会社FreshSpeed株式会社提供するサービスの売上受取だけの目的であったとしても、Stripeに登録する必要があります。
          こちらで入力していただく個人情報（または法人情報）の取得は、MeatSale（運営会社FreshSpeed株式会社）では一切行いません。Stripe社によって管理保護されております。Stripeは世界中の企業で利用されている、安全安心な決済サービス会社です。
          <br />
          <div className="stripe_information">
            Stripe会社情報
            <br />
            <a
              href="https://stripe.com/jp/about"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://stripe.com/jp/about
            </a>
          </div>
          <div className="stripe_support">
            Stripeサポート
            <br />
            <a
              href="https://support.stripe.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.stripe.com/contact
            </a>
          </div>
          <div className="introduction_example">
            <img
              src="https://meatsale.jp/assets/stripe.png"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesAccount;
