import Dexie from 'dexie';
import storage from 'store';

const DB_NAME = 'fish_sale';

const schemas = {
  notification:
    'id,body_text,start_disclose,end_disclose,is_disclosed,is_readable',
};

/** db open */
export const open = async () => {
  if (storage.get('notifications')) {
    storage.remove('notifications');
    await new Dexie(DB_NAME).delete();
  }

  const db = new Dexie(DB_NAME);
  db.version(2).stores(schemas);

  return await db.open();
};

/** 更新 */
export const update = async (tableName, value) => {
  try {
    const db = await open();

    await db.transaction('rw', db[tableName], async () => {
      await db[tableName].put(value);
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** 一括更新 */
export const bundleUpdate = async (tableName, array) => {
  try {
    const db = await open();

    await db.transaction('rw', db[tableName], async () => {
      await db[tableName].bulkPut(array);
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** 登録 */
export const insert = async (tableName, value) => {
  try {
    const db = await open();

    await db.transaction('rw', db[tableName], async () => {
      await db[tableName].add(value);
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** 一括登録 */
export const bundleInsert = async (tableName, params) => {
  try {
    const db = await open();

    await db.transaction('rw', db[tableName], async () => {
      await db[tableName].bulkAdd(params);
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** 削除 */
export const remove = async (tableName, value) => {
  try {
    const db = await open();

    await db.transaction('rw', db[tableName], async () => {
      await db[tableName].where('id').equals(value.id).delete();
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** 一括削除 */
export const bundleRemove = async (tableName) => {
  try {
    const db = await open();

    await db.transaction('rw', db[tableName], async () => {
      await db[tableName].clear();
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** １件取得 */
export const findOne = async (tableName, value) => {
  try {
    const db = await open();

    return await db[tableName].get(value.id);
  } catch (error) {
    throw new Error(error);
  }
};
