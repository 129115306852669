import * as React from 'react';
import enhance from './enhance';
import { Field } from 'redux-form/immutable';
import { TitleBar, FormLabelIcon } from '@/components/Common';
import { FieldTextArea, FieldDropdown } from '../../../Form/';
import { Button } from 'semantic-ui-react';
import { app } from '@/config';
import Modal from '@/migration/modal';
import { ConfirmLeave } from './ModalBody';

function Leave(props) {
  const {
    handleSubmit,
    leaveAccount,
    showModal,
    updateShowModal,
    openModal,
    isLoading,
  } = props;

  return (
    <div className="a__leave">
      <TitleBar title="退会" />
      <div className="w__main">
        <form className="w__form" onSubmit={handleSubmit(openModal)}>
          <div className="w__field-title">
            退会理由
            <FormLabelIcon value="必須" />
          </div>
          <div className="w__field">
            <Field
              name="reason"
              placeholder="選択してください"
              selection
              fluid
              component={FieldDropdown}
              options={app.leaveReasons}
            />
          </div>
          <div className="w__field-title">
            詳しい内容
            <FormLabelIcon value="必須" />
          </div>
          <div className="w__field">
            <Field
              name="detail"
              fluid
              component={FieldTextArea}
              maxLength={501}
            />
          </div>
          <div className="w__action-btn">
            <Button content="退会する" fluid />
          </div>
          <Modal
            isModal={showModal}
            handleCloseModal={updateShowModal}
            className="w__leave-modal"
            size="sm"
            ModalBody={() => (
              <ConfirmLeave
                leaveAccount={leaveAccount}
                handleSubmit={handleSubmit}
                updateShowModal={updateShowModal}
                isModal={showModal}
                isLoading={isLoading}
              />
            )}
          />
        </form>
      </div>
    </div>
  );
}

export default enhance((props) => <Leave {...props} />);
