import * as React from 'react';
import { Field } from 'redux-form/immutable';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { TitleBar } from '@/components/Common';
import Pagination from '@/migration/pagination';
import { Button, Header } from 'semantic-ui-react';
import { FieldTextArea } from '../../../Form';
import enhance from './enhancer';
import { replaceTofu } from '@/utils/string';

function TradeCommentsBefore(props) {
  const {
    handleSubmit,
    submitComment,
    submitting,
    sales: {
      commentsBefore: { current, totalCount, pages },
      item: { sellerName, sellerUserId, fixed },
    },
    users: { mydata },
    onChangePage,
    isPaginationDisabled,
    isLoading,
  } = props;

  return (
    <div className="a__comments">
      {fixed && pages.active.length === 0 ? null : (
        <Header as="h2" className="fiex_chat_header">
          <TitleBar title="質問・コメント" />
        </Header>
      )}
      {!fixed ? (
        <React.Fragment>
          <div className="fix-chat-text">
            {sellerUserId === mydata.id ? (
              <div>
                この出品について問い合わせやコメントが来た場合は、返事をしてあげましょう。
              </div>
            ) : (
              <div>
                この出品について
                <Link to={`/users/${sellerUserId}`}>
                  <span className="seller_name">{replaceTofu(sellerName)}</span>
                </Link>
                さんに聞きたいことやコメントを送りましょう。
              </div>
            )}
            <br className="hidden_more_lg" />
            <div className="chat_notes">
              ※コメント欄での値引き交渉・取引等は禁止されています。
            </div>
          </div>
          <div className="chat_input_area">
            <div className="form_wrap">
              <form className="flex" onSubmit={handleSubmit(submitComment)}>
                <div className="form_item">
                  <Field
                    rows={5}
                    className="text_item"
                    name="message"
                    component={FieldTextArea}
                    maxLength={500}
                  />
                  <span className="word_count_under">(最大500文字)</span>
                </div>

                <Button
                  disabled={submitting}
                  loading={submitting}
                  content="書き込む"
                  className="message_submit"
                />
              </form>
            </div>
          </div>
        </React.Fragment>
      ) : null}

      <div className="chat-list-area">
        <Loader
          active={isLoading}
          inline="centered"
          className="fished_loading"
        />
        {pages.active.map((row) => (
          <div className="chat-content-block flex" key={row.id}>
            <div className="chat-user">
              {mydata.id === row.user_id ? (
                <div className="user-name">{row.userDisplayName}</div>
              ) : (
                <div className="user-name">{row.userDisplayName}</div>
              )}
            </div>
            <div className="triangle" />
            <div className="chat-content">
              <p className="content">{row.body}</p>
            </div>
          </div>
        ))}
        <Pagination
          totalCount={totalCount}
          current={current}
          pageSize={5}
          changePage={onChangePage}
          disabled={isPaginationDisabled}
        />
      </div>
    </div>
  );
}

export default enhance((props) => <TradeCommentsBefore {...props} />);
