/** 送付先地方について */
export const prefectures = [
  {
    data: [
      {
        area: '北海道',
        rowSpan: 1,
        prefectures: ['北海道'],
      },
      {
        area: '北東北',
        rowSpan: 4,
        prefectures: ['青森県', '岩手県', '宮城県'],
      },
      {
        area: '南東北',
        rowSpan: 4,
        prefectures: ['秋田県', '山形県', '福島県'],
      },
      {
        area: '関東',
        rowSpan: 6,
        prefectures: ['茨城県', '群馬県', '埼玉県', '神奈川県', '山梨県'],
      },
      {
        area: '東京',
        rowSpan: 1,
        prefectures: ['東京都'],
      },
      {
        area: '信越',
        rowSpan: 3,
        prefectures: ['新潟県', '長野県'],
      },
      {
        area: '北陸',
        rowSpan: 4,
        prefectures: ['富山県', '石川県', '福井県'],
      },
      {
        area: '東海',
        rowSpan: 5,
        prefectures: ['岐阜県', '静岡県', '愛知県', '三重県'],
      },
    ],
  },
  {
    data: [
      {
        area: '関西',
        rowSpan: 7,
        prefectures: [
          '滋賀県',
          '京都府',
          '大阪府',
          '兵庫県',
          '奈良県',
          '和歌山県',
        ],
      },
      {
        area: '中国',
        rowSpan: 6,
        prefectures: ['鳥取県', '島根県', '岡山県', '広島県', '山口県'],
      },
      {
        area: '四国',
        rowSpan: 5,
        prefectures: ['徳島県', '香川県', '愛媛県', '高知県'],
      },
      {
        area: '北九州',
        rowSpan: 5,
        prefectures: ['福岡県', '佐賀県', '長崎県', '大分県'],
      },
      {
        area: '南九州',
        rowSpan: 4,
        prefectures: ['熊本県', '宮崎県', '鹿児島県'],
      },
      {
        area: '沖縄',
        rowSpan: 1,
        prefectures: ['沖縄県'],
      },
    ],
  },
];

/** クール料金について */
export const coolPrices = [
  {
    data: [
      {
        company: '佐川急便',
        rowSpan: 7,
        priceSize: [
          {
            size: 60,
            price: 275,
          },
          {
            size: 80,
            price: 330,
          },
          {
            size: 100,
            price: 440,
          },
          {
            size: 140,
            price: 715,
          },
          {
            size: 160,
            price: 935,
          },
          {
            size: 170,
            price: 1375,
          },
        ],
      },
      {
        company: 'ヤマト運輸',
        rowSpan: 5,
        priceSize: [
          {
            size: 60,
            price: 220,
          },
          {
            size: 80,
            price: 220,
          },
          {
            size: 100,
            price: 330,
          },
          {
            size: 120,
            price: 660,
          },
        ],
      },
    ],
  },
  {
    data: [
      {
        company: '日本郵便',
        rowSpan: 7,
        priceSize: [
          {
            size: 60,
            price: 225,
          },
          {
            size: 80,
            price: 360,
          },
          {
            size: 100,
            price: 675,
          },
          {
            size: 120,
            price: 675,
          },
          {
            size: 140,
            price: 1330,
          },
          {
            size: 150,
            price: 2100,
          },
        ],
      },
    ],
  },
];
