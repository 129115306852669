import React from 'react';

import { CANCELED, EXPIRED, FAILED_CHARGING } from '@/constants/closedReason';

interface Props {
  bid: boolean;
  closed: boolean;
  fixed: boolean;
  closedReason: number;
}

const CardLabel: React.FC<Props> = ({ bid, closed, fixed, closedReason }) => {
  if (bid && !closed) {
    if (fixed)
      return (
        <span className="fish-card__label fish-card__label-soldout">
          落札済み
        </span>
      );

    return (
      <span className="fish-card__label fish-card__label-onsale">
        オークション中
      </span>
    );
  }

  if (fixed)
    return (
      <span className="fish-card__label fish-card__label-soldout">
        落札済み
      </span>
    );

  if (closedReason === EXPIRED)
    return (
      <span className="fish-card__label fish-card__label-expired">
        期限切れ
      </span>
    );

  if (closedReason === CANCELED)
    return (
      <span className="fish-card__label fish-card__label-canceled">
        取り下げ
      </span>
    );

  if (closedReason === FAILED_CHARGING)
    return (
      <span className="fish-card__label fish-card__label-failed">
        決済エラー
      </span>
    );

  return null;
};

export default CardLabel;
