import axios from 'axios';
import { Auth } from '@/utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function postSaleCommentBefore(saleId, message) {
  try {
    const token = await Auth.getIdToken();

    const url = `${GO_ENDPOINT}/v1/${SITE_TYPE}/sales/${saleId}/comments/before_fixed`;

    const response = await axios.post(
      url,
      {
        message: `${message}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export default postSaleCommentBefore;
