export default function parseShipping({
  full_name,
  post_number,
  address,
  phone_number,
  delivery_time,
  confirm_address,
  auto_pass_to_seller,
}) {
  return Object.assign(
    {},
    {
      full_name,
      post_number,
      address,
      phone_number,
      delivery_time,
      confirm_address,
      auto_pass_to_seller,
    }
  );
}
