const grade = [
  {
    key: '1',
    value: 1,
    name: 'grade1',
    text: '良々',
  },
  {
    key: '2',
    value: 2,
    name: 'grade2',
    text: '良(無印)',
  },
  {
    key: '3',
    value: 3,
    name: 'grade3',
    text: '優',
  },
  {
    key: '4',
    value: 4,
    name: 'grade4',
    text: '青秀',
  },
  {
    key: '5',
    value: 5,
    name: 'grade5',
    text: '赤秀',
  },
  {
    key: '6',
    value: 6,
    name: 'grade6',
    text: '特秀',
  },
  {
    key: '7',
    value: 7,
    name: 'grade7',
    text: '特選',
  },
];

export const gradeNames = grade.map(({ name }) => name);

export default grade;
