import moment from 'moment';
import 'moment/locale/ja';

const getFishingYear = (past = 0) => {
  if (past !== 0) {
    return moment().add(past, 'year').format('YYYY');
  }
  return moment().format('YYYY');
};

const getFishingMonth = () => {
  return moment().format('M');
};

const getFishingDay = () => {
  return moment().format('D');
};

const margeFisingDay = (yy, mm, dd) => {
  return moment([yy, mm - 1, dd]).format('YYYY-MM-DD');
};

const formatDate = (rawDate) => {
  if (!rawDate) {
    return rawDate;
  }

  return moment(rawDate).locale('ja').format('YYYY. MM. DD (ddd)');
};

const formatDateTemplate = (rawDate, template) => {
  if (!rawDate) {
    return '';
  }
  if (!template) {
    return '';
  }

  return moment(rawDate).locale('ja').format(template);
};

const isFutureDate = (yy, mm, dd) => {
  return moment()
    .locale('ja')
    .isBefore([yy, mm - 1, dd]);
};

const now = () => {
  const dt = moment().format();
  return dt;
};

const plusDays = (base, day) => {
  const dt = moment(base).add(day, 'days');
  return dt;
};

const minusDays = (base, day) => {
  const dt = moment(base).subtract(day, 'days').format();
  return dt;
};

export const date = {
  getFishingYear,
  getFishingMonth,
  getFishingDay,
  margeFisingDay,
  formatDate,
  formatDateTemplate,
  isFutureDate,
  now,
  plusDays,
  minusDays,
};
