import { connect } from 'react-redux';
import notify from '@/migration/notification';
import { reduxForm, getFormValues } from 'redux-form/immutable';

import {
  compose,
  setDisplayName,
  withState,
  lifecycle,
  withHandlers,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction, salesAction } from '@/actions';
import { MYPAGE } from '../../../../constants';
import { parse } from '@/utils/queryParams';
import { paths } from '@/config';

const mapStateToProps = (state) => {
  const formValues = getFormValues('emailIsReadForm')(state);

  return {
    form: state.form.toJS(),
    sales: state.sales.toJS(),
    users: state.users.toJS(),
    formValues: formValues ? formValues.toJS() : {},
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, ...usersAction, notify }, dispatch);

const enhance = compose(
  setDisplayName('Notification'),
  reduxForm({
    form: 'emailIsReadForm',
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('activeIndex', 'setActiveIndex', MYPAGE.NOTIFICATION_TAB),
  withState('isListLoading', 'updateIsListLoading', false),
  withState('isEmailConfigModal', 'setIsEmailConfigModal', false),
  withState('isLoading', 'updateLoading', false),
  withHandlers({
    submitToRead: (props) => async () => {
      const {
        form: { emailIsReadForm },
        users: { mydata },
      } = props;

      try {
        const emailIsReads = Object.entries(emailIsReadForm.values);
        const ids = [];
        emailIsReads.forEach(([key, value]) => {
          if (value) {
            ids.push(Number(key));
          }
        });
        await props.putActivitiesToRead(mydata.id, ids);
        notify('通知を更新しました。', 'info', undefined, 5);
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } catch (error) {
        notify('通知の更新に失敗しました。', 'error', undefined, 5);
        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
    },
    submitToUnread: (props) => async () => {
      const {
        form: { emailIsReadForm },
        users: { mydata },
      } = props;

      try {
        const emailIsReads = Object.entries(emailIsReadForm.values);

        const ids = [];

        emailIsReads.forEach(([key, value]) => {
          if (value) {
            ids.push(Number(key));
          }
        });
        await props.putActivitiesToUnread(mydata.id, ids);
        notify('通知を更新しました。', 'info', undefined, 5);
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } catch (error) {
        notify('通知の更新に失敗しました。', 'error', undefined, 5);
        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
    },

    handleTabChange: (props) => (categoryId) => {
      const { setActiveIndex, history, updateIsListLoading } = props;

      updateIsListLoading(true);
      switch (categoryId) {
        // 全般
        case 0: {
          setActiveIndex(0);
          history.push(`${paths.mypage.notification}?page=1&category=g`);
          break;
        }

        // メッセージ
        case 1: {
          setActiveIndex(1);
          history.push(`${paths.mypage.notification}?page=1&category=m`);
          break;
        }
      }
      updateIsListLoading(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { location, setActiveIndex } = this.props;

      const params = parse(location.search);

      const index = params?.category === 'm' ? 1 : 0;
      setActiveIndex(index);
    },
    componentDidUpdate(prevProps) {
      const { location, setActiveIndex } = this.props;
      const { location: prevLocation } = prevProps;

      if (location.search === prevLocation.search) return;

      const params = parse(location.search);
      const index = params?.category === 'm' ? 1 : 0;
      setActiveIndex(index);

      window.scrollTo({ left: 0, top: 0 });
    },
  })
);

export default enhance;
