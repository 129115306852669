import * as React from 'react';
import { TextArea } from 'semantic-ui-react';

function FieldTextArea(props) {
  const { meta, input } = props;

  return (
    <div className="field-group">
      <TextArea
        {...props}
        onChange={(event, field) => {
          if (field.maxLength < field.value.length) {
            return;
          } else {
            input.onChange(field.value);
          }
        }}
        onBlur={() => input.onBlur(input.value)}
        value={input.value}
      />
      {meta.touched && meta.error && (
        <a id="error">
          <span className="redux_form error">{meta.error}</span>
        </a>
      )}
    </div>
  );
}

export default FieldTextArea;
