import * as React from 'react';
import enhance from './enhancer';

function CardElements() {
  return (
    <div className="cell stripe-elements elements">
      <form>
        <div className="fieldset">
          <div>カード番号</div>
          <div id="elements-card-number" className="field empty" />
          <div>有効期限(月/年)</div>
          <div id="elements-card-expiry" className="field empty third-width" />
          <div>セキュリティコード</div>
          <div id="elements-card-cvc" className="field empty third-width" />
        </div>
        <button type="submit" data-tid="card_elements.form.pay_button">
          登録する
        </button>
        <div className="error" role="alert">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
          >
            <path
              className="base"
              fill="#000"
              d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
              className="glyph"
              fill="#FFF"
              d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
          </svg>
          <span className="message" />
        </div>
      </form>
      <div className="success">
        <div className="icon">
          <svg
            width="84px"
            height="84px"
            viewBox="0 0 84 84"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="border"
              cx="42"
              cy="42"
              r="40"
              strokeLinecap="round"
              strokeWidth="4"
              stroke="#000"
              fill="none"
            />
            <path
              className="checkmark"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338"
              strokeWidth="4"
              stroke="#000"
              fill="none"
            />
          </svg>
        </div>
        <h3 className="title" data-tid="elements_examples.success.title">
          カード情報の登録が成功しました。
        </h3>
      </div>
    </div>
  );
}

export default enhance((props) => <CardElements {...props} />);
