import { connect } from 'react-redux';
import { compose, lifecycle, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('RedCircleBadge'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidMount() {
      const { getActivitiesUnreadCounts, getMe } = this.props;

      try {
        const me = await getMe({ loose: true });
        getActivitiesUnreadCounts(me.value.id);
      } catch (error) {
        console.error(error);
      }
    },
  })
);

export default enhance;
