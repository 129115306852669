import * as React from 'react';
import enhance from './enhancer';

const maxCount = 99;

function RedCircleBadge(props) {
  const {
    users: {
      activities: { unreadCounts },
    },
  } = props;

  if (unreadCounts === 0 || typeof unreadCounts === 'object') {
    return null;
  }

  return (
    <div className="a__RedCircleBadge">
      {unreadCounts > maxCount
        ? `${maxCount.toString()}+`
        : unreadCounts.toString()}
    </div>
  );
}

export default enhance((props) => <RedCircleBadge {...props} />);
