import React from 'react';
import SideNavDropDown from '../DropDown';
import { Button } from 'semantic-ui-react';

import { compnaySorts } from '../data';

const CompanySearch = ({ submit }) => (
  <>
    <div className="b__sidenav__company">
      <Button
        positive
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement?.blur();
          submit();
        }}
      >
        検索
      </Button>
    </div>

    <ul className="b__sidenav__list">
      <SideNavDropDown
        label="並び替え"
        name="sort"
        options={compnaySorts}
        placeholder="レビュー件数順"
      />
    </ul>
  </>
);

export default CompanySearch;
