import React from 'react';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';

import { FormLabelIcon } from '@/components/Common';
import enhance from './enhancer';
import { FieldInput, FieldTextArea, FieldDropdown } from '../../../Form';
import { app, paths } from '@/config';

function ReportModal(props) {
  const { handleSubmit, handleCloseModal, reportItem } = props;

  return (
    <div id="report-modal">
      <div className="report-modal-title">
        <h2>出品を通報する</h2>
      </div>
      <div className="w__report-modal-content">
        {/* 通報者名入力 */}
        <div className="reporter-name">
          <div className="w__report-item">
            <span className="reporter-name-label">お名前</span>
            <FormLabelIcon value="必須" />
          </div>

          <Field
            type="text"
            name="reporterName"
            placeholder="例)商品太郎"
            component={FieldInput}
            maxLength={40}
          />
        </div>

        <div className="reporter-email">
          <div className="w__report-item">
            <span className="reporter-email-label">メールアドレス</span>
            <FormLabelIcon value="必須" />
          </div>

          <Field
            placeholder="例)fish@sakana.ne.jp"
            component={FieldInput}
            maxLength={256}
            type="email"
            name="reporterEmail"
          />
        </div>

        <div className="government">
          <div className="w__report-item">
            <span className="government-label">通報済み政府機関</span>
            <FormLabelIcon value="必須" />
          </div>

          <Field
            name="governments"
            selection
            options={app.governments}
            placeholder="選択してください"
            component={FieldDropdown}
          />
        </div>

        <div className="government_report_link">
          <a
            href={paths.common.contact}
            target="_blank"
            rel="noopener noreferrer"
          >
            【通報連絡先】
          </a>
          （こちらのリンク先に連絡先が記載しています）
        </div>
        <div className="report-content">
          <div className="w__report-item">
            <span className="report-content-label">内容</span>
            <FormLabelIcon value="必須" />
          </div>

          <Field
            type="text"
            name="reportContent"
            component={FieldTextArea}
            maxLength={501}
          />
        </div>

        <div className="w__buttons">
          <Button
            className="cancel-button"
            onClick={() => handleCloseModal(!props.isModal)}
          >
            キャンセル
          </Button>

          <Button className="report-button" onClick={handleSubmit(reportItem)}>
            通報する
          </Button>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <ReportModal {...props} />);
