import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import notify from '@/migration/notification';
import { compose, lifecycle, setDisplayName, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import urlParse from 'url-parse';
import { usersAction } from '@/actions';
import { paths } from '@/config';

const mapStateToProps = (state) => ({
  router: state.router,
  form: state.form.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, notify, push }, dispatch);

const enhance = compose(
  setDisplayName('StripeCallback'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'isLoadingUpdater', true),
  lifecycle({
    async componentDidMount() {
      const { getMe, putUserShop, isLoadingUpdater, push } = this.props;

      try {
        await getMe();
      } catch (error) {
        push(paths.before.signin);

        notify(
          'セッションが切れました。再度、ログインを行なってください',
          'error',
          undefined,
          5
        );
      }

      const {
        users: { mydata },
      } = this.props;

      try {
        const OAuthCode = urlParse(window.location.href, true).query.code;

        if (OAuthCode) {
          await putUserShop(mydata.id, OAuthCode);
        } else {
          push(paths.landing);

          notify(
            '口座の登録に失敗しました。時間をおいて再度お試しください',
            'error',
            undefined,
            5
          );
        }
      } catch (error) {
        push(paths.mypage.root);

        notify(
          '口座の登録に失敗しました。時間をおいて再度お試しください',
          'error',
          undefined,
          5
        );
      }
      isLoadingUpdater(false);
    },
  })
);

export default enhance;
