import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { FormLabelIcon, ThumbContainer } from '@/components/Common';
import enhance from './enhance';
import { styles } from './styles';

function FieldSellDropzone(props) {
  const [style, setStyle] = useState({});

  const onDrop = props.drop;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  useEffect(() => {
    setStyle({
      ...styles.baseStyle,
      ...(isDragActive ? styles.activeStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
    });
  }, [isDragActive, isDragReject]);

  return (
    <div>
      <div className="field_wrapper_sell_image_group">
        <label className="flex">
          出品画像
          <FormLabelIcon value="必須" />
        </label>
        <section>
          <div className="image_field_wrapper" {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div className="image_field_base_wrapper">
              <p className="image_field_text">
                ドラッグ＆ドロップ
                <br />
                またはクリックで画像を追加（最大10枚）
                <br />
              </p>
            </div>
          </div>
        </section>
        <p style={{ fontSize: '12px', fontFamily: 'sans-serif' }}>
          ※アップロード可能拡張子 .apng, .bmp, .gif, .ico, .jpg, .jpeg, .png,
          .svg, .tiff, .webp
        </p>
        <p style={{ fontSize: '12px', fontFamily: 'sans-serif' }}>
          ※複数画像アップロード時に、赤枠で選択されているものが一覧画面に表示されるサムネイル画像になります。
        </p>
        <p style={{ fontSize: '12px', fontFamily: 'sans-serif' }}>
          ※サムネイル画像に表示したい画像は、画像をタップすることで変更を行えます。
        </p>

        <ThumbContainer
          droppedImages={props.input.value}
          {...props}
          onClick={props.handleClick}
        />
        {props.meta.touched && props.meta.error && (
          <a id="error">
            <span className="redux_form error">{props.meta.error}</span>
          </a>
        )}
      </div>
    </div>
  );
}

export default enhance((props) => <FieldSellDropzone {...props} />);
