import React, { Fragment } from 'react';
import enhance from './enhancer';

import { regions } from './regions';

const Fee = ({ shippingRelayCosts }) => {
  // TODO ファイル切り分け
  const body = regions.map(({ key, text }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!shippingRelayCosts.hasOwnProperty(key)) {
      return null;
    }

    const children = shippingRelayCosts[key].map(
      ({ relay_cost, remote_island_group_text }, index) => (
        <tr key={remote_island_group_text}>
          {index === 0 ? (
            <td
              className="a__fee-region"
              rowSpan={shippingRelayCosts[key].length}
            >
              {text}
            </td>
          ) : null}
          <td className="a__fee-address">{remote_island_group_text || '-'}</td>
          <td className="a__fee-fee">
            {relay_cost ? `¥ ${relay_cost.toLocaleString()}` : '-'}
          </td>
        </tr>
      )
    );

    return <Fragment key={key}>{children}</Fragment>;
  });

  return (
    <div className="a__fee">
      <h2 className="b__sagawa">佐川急便</h2>
      <h3 className="a__fee-heading">元払い運賃の離島中継料金表</h3>
      <table cellPadding={4} cellSpacing={1} className="a__fee-table">
        <thead className="a__fee-thead">
          <tr>
            <th>地方</th>
            <th>住所</th>
            <th>料金</th>
          </tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
};

export default enhance(Fee);
