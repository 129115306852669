import React from 'react';
import { Field } from 'redux-form/immutable';

const Accordion = ({ title, id, propsName, component, data }) => {
  return (
    <li className="b__sidenav__listitem">
      <div className="b__sidenav__accordion">
        <input
          type="checkbox"
          id={id}
          className="b__sidenav__accordion-hidden"
        />
        <label htmlFor={id} className="b__sidenav__accordion-title">
          {title}
        </label>

        <div className="b__sidenav__accordion-content">
          {data.map((d) => (
            <Field
              name={propsName || d.name}
              label={d.text}
              key={d.key}
              component={component}
            />
          ))}
        </div>
      </div>
    </li>
  );
};

export default Accordion;
