import { connect } from 'react-redux';
import {
  compose,
  setDisplayName,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

import { constants } from '../../../constants';

const mapStateToProps = (state) => ({ users: state.users.toJS() });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('UserFollow'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withState('isLoading', 'updateLoading', false),
  withHandlers({
    onChangePage: (props) => async (current) => {
      window.scrollTo({ left: 0, top: 75, behavior: 'instant' });

      const {
        getFollowPagination,
        updateFollowPagesActive,
        updatePaginationDisabled,
        users: {
          userProfile: { id },
        },
      } = props;

      updatePaginationDisabled(true);
      updateFollowPagesActive(current);

      await getFollowPagination({ limit: 5, current: current, userId: id }, [
        '-created_at',
      ]);
      updatePaginationDisabled(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getFollowPagination,
        updateFollowPagesActive,
        getMe,
        updateLoading,
        getMyFollows,
        match,
      } = this.props;

      updateLoading(true);

      const userId = match.params.user_id;

      await getFollowPagination(
        { limit: 5, current: constants.PAGINATION.INITIAL, userId },
        ['-created_at']
      );
      updateFollowPagesActive(constants.PAGINATION.INITIAL);
      if (window.isAuthed) {
        const me = await getMe();
        const myId = me.value.id;
        getMyFollows(myId);
      }
      updateLoading(false);
    },
  })
);

export default enhance;
