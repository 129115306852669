import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { Law } from '@/components/Pages';
import enhance from './enhancer';

function LawRoutes() {
  return <Route strict path={paths.common.law} component={Law} />;
}

export default enhance((props) => <LawRoutes {...props} />);
