import React from 'react';

interface Props {
  commentAdd: boolean;
  imageAdd: boolean;
}

const CommentLabel: React.FC<Props> = ({ commentAdd, imageAdd }) => {
  if (commentAdd && imageAdd) {
    return (
      <span className="fish-card__comment-label">
        <span>コメント追加有</span>
        <span>画像追加有</span>
      </span>
    );
  }

  if (imageAdd) {
    return <span className="fish-card__comment-label">画像追加有</span>;
  }

  if (commentAdd) {
    return <span className="fish-card__comment-label">コメント追加有</span>;
  }

  return null;
};

export default CommentLabel;
