const size = [
  {
    key: '1',
    value: 1,
    name: 'size1',
    text: '2S',
  },
  {
    key: '2',
    value: 2,
    name: 'size2',
    text: 'S',
  },
  {
    key: '3',
    value: 3,
    name: 'size3',
    text: 'M',
  },
  {
    key: '4',
    value: 4,
    name: 'size4',
    text: '特M',
  },
  {
    key: '5',
    value: 5,
    name: 'size5',
    text: 'L',
  },
  {
    key: '6',
    value: 6,
    name: 'size6',
    text: 'L大',
  },
  {
    key: '7',
    value: 7,
    name: 'size7',
    text: '2L',
  },
  {
    key: '8',
    value: 8,
    name: 'size8',
    text: '3L',
  },
  {
    key: '9',
    value: 9,
    name: 'size9',
    text: '4L',
  },
];

export const sizeNames = size.map(({ name }) => name);

export default size;
