import { connect } from 'react-redux';
import { compose, withHandlers, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';

import * as queryParams from '@/utils/queryParams';

const mapStateToProps = (state) => ({
  app: state.app.toJS(),
  form: state.form.toJS(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const enhance = compose(
  reduxForm({
    form: 'sortForm',
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({}),
  lifecycle({
    componentDidMount() {
      const { location, change } = this.props;

      const queryParam = queryParams.parse(location?.search);

      if (queryParam.sort) {
        const sort = queryParam.sort.replaceAll(' ', '+');
        change('sort', sort || '');
      }
    },

    componentDidUpdate(prevProps) {
      const { location, change } = this.props;
      const { location: prevLocation } = prevProps;

      // urlが変わった時に更新を行う
      if (location.search !== prevLocation.search) {
        const queryParam = queryParams.parse(location?.search);

        if (queryParam.sort) {
          const sort = queryParam.sort.replaceAll(' ', '+');
          change('sort', sort || '');
        }
      }
    },
  })
);

export default enhance;
