import * as React from 'react';
import enhance from './enhancer';
import { MeatSaleInfo } from '@/modules/meat';
import { useDetailItem } from '@/hooks';

// const INVALID_DATE = 'Invalid date';

function SaleInfoList(props) {
  const { detailData } = useDetailItem(parseInt(props.sales.item.id));

  return (
    <>
      <MeatSaleInfo
        {...props}
        sales={{ item: { ...props.sales.item, ...detailData } }}
      />
    </>
  );
}

export default enhance((props) => <SaleInfoList {...props} />);
