import * as React from 'react';
import classNames from 'classnames';

function FormLabelIcon(props) {
  const { value, optional } = props;

  return (
    <div
      className={classNames({
        app_form_label_icon: true,
        flex: true,
        optional: optional,
      })}
    >
      <span>{value}</span>
    </div>
  );
}

export default FormLabelIcon;
