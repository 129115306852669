export default function getPasswordValidateMessage(password) {
  const passLen = password.length;
  let isLowerCase = false;
  let isUpperCase = false;
  let isNum = false;
  let isSymbol = false;
  // eslint-disable-next-line
  const reg = new RegExp(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g);

  isLowerCase = password.match(/[a-z]/);
  isUpperCase = password.match(/[A-Z]/);
  isNum = password.match(/[0-9]/);
  isSymbol = reg.test(password);

  if (passLen < 6) {
    return '6文字以上で構成してください';
  } else if (passLen >= 50) {
    return '50文字以内で構成してください';
  } else if (!isLowerCase || !isUpperCase || !isNum) {
    return '小文字、大文字、数字を含めてください';
  } else if (isSymbol) {
    return '英数字以外を含めないでください';
  }

  return undefined;
}
