import React from 'react';

function ErrorModal(props) {
  return (
    <div className="error-modal">
      <h3>決済エラー詳細</h3>
      <div className="error-message">
        {props.errorMessage.split('\n').map((message) => (
          <p key={message}>{message}</p>
        ))}
      </div>
    </div>
  );
}

export default ErrorModal;
