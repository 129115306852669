import * as React from 'react';
import { Select } from 'semantic-ui-react';

import enhance from './enhance';

function Dropdown(props) {
  return (
    <div className="field-group">
      <Select
        value={props.input.value}
        onChange={(event, values) => {
          props.changeToggle(event, values);
        }}
        readOnly={props.disabled}
        style={props.style}
        className={props.className}
        fluid={props.fluid}
        type={props.type}
        options={props.options}
        placeholder={props.placeholder}
        selection={props.selection}
        search={props.search}
        noResultsMessage={props.noResultsMessage}
      />
      {props.meta.touched && props.meta.error && (
        <a id="error">
          <div className="redux_form error">{props.meta.error}</div>
        </a>
      )}
    </div>
  );
}

export default enhance((props) => <Dropdown {...props} />);
