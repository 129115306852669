import { formatDate } from '@/apis/utils';

export default function parseUser({
  id,
  identity_verified,
  display_name,
  company,
  company_address,
  boat,
  about,
  avatar_url,
  prefecture,
  total_like_as_seller_count,
  total_rated_as_seller_count,
  last_login_at,
  is_seller,
  is_customer,
  average_point,
  total_evaluation_count,
  is_company,
  is_email_refused,
  shipping_provider,
  is_email_wanted,
  is_bid_popup,
  is_next_auto_bid,
}) {
  const nextLastLoginAt = formatDate(last_login_at);

  return Object.assign(
    {},
    {
      id,
      identityVerified: identity_verified,
      displayName: display_name,
      company,
      companyAddress: company_address,
      fishingBoat: boat,
      introduction: about,
      thumnailImageUrl: avatar_url,
      prefecture: prefecture,
      likesCount: total_like_as_seller_count,
      ratedCount: total_rated_as_seller_count,
      lastLoginAt: nextLastLoginAt,
      isSeller: is_seller,
      isCustomer: is_customer,
      averagePoint: average_point,
      totalEvaluationCount: total_evaluation_count,
      isCompany: is_company,
      isEmailRefused: is_email_refused,
      shippingProvider: shipping_provider,
      isEmailWanted: is_email_wanted,
      isBidPopup: is_bid_popup,
      isNextAutoBid: is_next_auto_bid,
    }
  );
}
