import getMatch from './getMatch';
import { paths } from '@/config';
import * as R from 'ramda';

export default function isDetailToFishesByLink(
  prevRouterProps,
  { location, history }
) {
  const prevPathname = R.pathOr(
    'prevPathname',
    ['location', 'pathname'],
    prevRouterProps
  );
  const prevHistoryAction = R.pathOr(
    'prevHistoryAction',
    ['history', 'action'],
    prevRouterProps
  );

  const prevMatch = getMatch(prevPathname, paths.fishes.saleItem);
  const currentMatch = getMatch(location.pathname, paths.fishes.root);

  const prevPath = R.pathOr('prevPath', ['path'], prevMatch);
  const currentPath = R.pathOr('currentPath', ['path'], currentMatch);

  if (
    prevPath === paths.fishes.saleItem &&
    currentPath === paths.fishes.root &&
    prevHistoryAction === 'PUSH' &&
    history.action === 'PUSH'
  ) {
    return true;
  }

  return false;
}
