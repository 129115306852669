import axios from 'axios';
import { getEnv } from './env';

const env = getEnv();

const base = axios.create({
  baseURL: env.GO_ENDPOINT,
  timeout: 30000,
});

const sls = axios.create({
  baseURL: env.NODE_SLS_ENDPOINT,
  timeout: 30000,
});

export { base, sls };
