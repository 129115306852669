import * as React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form/immutable';
import { Button, Image } from 'semantic-ui-react';

import { TitleBar } from '@/components/Common';
import { FieldInput } from '../../Form';
import enhance from './enhancer';
import { images } from '@/config';

function ConfirmSignUp(props) {
  const {
    submitSignUpConfirm,
    handleSubmit,
    isLoading,
    isResendLoading,
    disabledResend,
    resendConfirmCode,
  } = props;

  return (
    <>
      {/* TODO: β版終了後、headerを削除する */}
      <header className="app_header flex-beta">
        <Image src={images.logo_header} className="logo" />
      </header>
      <div className="app_signup_confirm flex">
        <TitleBar title="認証コード入力" />

        <form className="flex" onSubmit={handleSubmit(submitSignUpConfirm)}>
          <span className="confirm_message">
            メールに記載されている6桁の認証コードを入力してください。
          </span>
          <div className="amplify-container">
            <Field
              name="code"
              className="inp_code"
              placeholder="認証コードを入力してください"
              component={FieldInput}
              disabled={isLoading || isResendLoading}
              maxLength={6}
            />
            <Button
              loading={isLoading}
              disabled={isLoading || isResendLoading}
              content="送信する"
              className="btn_submit"
            />
            <div
              className={classNames({
                'code-resend': true,
                app_disabled: isLoading || isResendLoading || disabledResend,
              })}
            >
              <a href="" onClick={resendConfirmCode}>
                認証コードを再送信する
              </a>
              <br />
              (最低５分間待って再送信してください)
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default enhance((props) => <ConfirmSignUp {...props} />);
