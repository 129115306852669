import { getPasswordValidateMessage } from './utils';

export default function signUp(immutableValues) {
  const errors = {};
  const {
    displayName,
    email,
    password,
    confirmPassword,
    isAgreement,
    prefecture,
  } = immutableValues.toJS();

  // ニックネーム
  if (!displayName || !displayName.trim()) {
    errors.displayName = 'ニックネームを入力してください';
  } else if (displayName.length > 20) {
    errors.displayName = 'ニックネームは20文字以内で入力してください。';
  }

  // メールアドレス
  if (!email) {
    errors.email = 'メールアドレスを入力してください';
  } else if (email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
      errors.email = 'メールアドレスが正しい形式ではありません';
    } else if (email.length > 255) {
      errors.email = 'メールアドレスは255文字以内で入力してください';
    }
  }

  // パスワード確認
  if (!password) {
    errors.password = 'パスワードを入力してください';
  } else if (password) {
    errors.password = getPasswordValidateMessage(password);
  }

  // パスワード確認用
  if (!confirmPassword) {
    errors.confirmPassword = 'パスワード（確認用）を入力してください';
  } else if (password) {
    if (password != confirmPassword) {
      errors.confirmPassword = 'パスワードと一致していません。';
    }
  }

  if (!prefecture) {
    errors.prefecture = '都道府県を選択してください';
  }

  if (!isAgreement) {
    errors.isAgreement = '利用規約に同意してください';
  }
  return errors;
}
