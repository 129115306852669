import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { MediaPublication } from '@/components/Common';

const MediaPublicationRoutes = () => {
  return (
    <>
      <Route
        path={paths.common.MediaPublication}
        component={MediaPublication}
      />
    </>
  );
};

export default MediaPublicationRoutes;
