import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const enhance = compose(
  setDisplayName('ModalBody'),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
