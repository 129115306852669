import ikejimeMethods, {
  ikejimeMethodNames,
} from '../../../config/app/ikejimeMethod';
import cleaningMethods, {
  cleaningMethodNames,
} from '../../../config/app/cleaningMethod';
import savingMethods, {
  savingMethodNames,
} from '../../../config/app/savingMethod';
import shippingExpenses, {
  shippingExpenseNames,
} from '../../../config/app/shippingExpense';
import deliveryMethods, {
  deliveryMethodNames,
} from '../../../config/app/deliveryMethod';
import shippingTimings, {
  shippingTimingNames,
} from '../../../config/app/shippingTiming';
import ikejimeTimings from '../../../config/app/ikejimeTiming';

// 追加
import { MEAT_CATEGORY, MEAT_ITEMS, CreateArrayData } from '@/modules/meat';

// TODO:送る値修正
const whiteListKeys = [
  'min_size',
  'max_size',
  'min_weight',
  'max_weight',
  'min_price',
  'max_price',
  'min_fix_price',
  'max_fix_price',
  'shipping_timing',
  'is_new',
  'is_immediately',
  'is_nature',
  'keyword',
  'status',
  'area',
  'sort',
];

export const getRequestParameter = (values) => {
  const query = {};

  const ikejimeMethod = [];
  const cleaningMethod = [];
  const savingMethod = [];
  const shippingExpense = [];
  const deliveryMethod = [];
  const shippingTiming = [];
  const ikejimeTiming = [];

  // 追加
  const categories = [];

  Object.entries(values).forEach(([key, value]) => {
    // ====================== 追加 ======================

    // 種類
    if (MEAT_CATEGORY.map(({ name }) => name).includes(key)) {
      if (value) {
        const found = MEAT_CATEGORY.find(({ name }) => name === key);
        if (found) categories.push(found.value);
      }
    }

    if (categories.length) {
      query['category'] = categories;
    }

    // その他
    Object.keys(MEAT_ITEMS).forEach((name) => {
      const d = MEAT_ITEMS[name];

      if (d.isSideNav) {
        const res = check(key, value, name);
        if (res) query[name] = res;
      }
    });

    // ====================== ここまで追加 ======================

    if (ikejimeMethodNames.includes(key)) {
      if (value) {
        const found = ikejimeMethods.find(({ name }) => name === key);

        if (found) ikejimeMethod.push(found.value);
      }
    }

    if (cleaningMethodNames.includes(key)) {
      if (value) {
        const found = cleaningMethods.find(({ name }) => name === key);

        if (found) cleaningMethod.push(found.value);
      }
    }

    if (savingMethodNames.includes(key)) {
      if (value) {
        savingMethod.push(savingMethods.find(({ name }) => name === key).value);
      }
    }

    if (shippingExpenseNames.includes(key)) {
      if (value) {
        const found = shippingExpenses.find(({ name }) => name === key);

        if (found) shippingExpense.push(found.value);
      }
    }

    if (deliveryMethodNames.includes(key)) {
      if (value) {
        const found = deliveryMethods.find(({ name }) => name === key);

        if (found) deliveryMethod.push(found.value);
      }
    }

    if (shippingTimingNames.includes(key)) {
      if (value) {
        const found = shippingTimings.find(({ name }) => name === key);

        if (found) shippingTiming.push(found.value);
      }
    }

    if (key === 'ikejime_timing') {
      if (value) {
        const found = ikejimeTimings.find(({ text }) => text === value);

        if (found) ikejimeTiming.push(found.value);
      }
    }

    if (whiteListKeys.includes(key)) {
      if (value) {
        query[key] = value;
      }
    }
  });

  if (ikejimeMethod.length) {
    query['ikejime_method'] = ikejimeMethod;
  }

  if (cleaningMethod.length) {
    query['cleaning_method'] = cleaningMethod;
  }

  if (savingMethod.length) {
    query['saving_method'] = savingMethod;
  }

  if (shippingExpense.length) {
    query['shipping_expense'] = shippingExpense;
  }

  if (deliveryMethod.length) {
    query['delivery_method'] = deliveryMethod;
  }

  if (shippingTiming.length) {
    query['shipping_timing'] = shippingTiming;
  }

  if (ikejimeTiming.length) {
    query['ikejime_timing'] = ikejimeTiming;
  }

  return { query };
};

const check = (key, value, name) => {
  const data = [];
  const ITEM = CreateArrayData(name);
  if (ITEM.map(({ name }) => name).includes(key)) {
    if (value) {
      const found = ITEM.find(({ name }) => name === key);
      if (found) data.push(found.value);
    }
  }

  if (data.length) {
    return data;
  }
};
