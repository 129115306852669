import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import enhance from './enhancer';

function SaleCloseModal(props) {
  const {
    cancelSale,
    modalProps: { handleModal, bidderUserId, bidderName },
  } = props;

  return (
    <div id="sale_close_modal">
      {bidderUserId === 0 ? (
        <div>
          <p className="close_message">出品を取り下げますか？</p>
        </div>
      ) : (
        <div>
          <p className="close_message">出品を取り下げますか？</p>
          <h3>
            <Link to={`/users/${bidderUserId}`} className="bidder_name">
              {bidderName}
            </Link>
            さんが{bidderName.length >= 15 ? <br /> : null}入札しています。
          </h3>
          <p>※入札されている出品を取り下げると、星1つの自動評価を受けます。</p>
        </div>
      )}

      <div>
        <Button negative onClick={handleModal} className="negative_button">
          キャンセル
        </Button>
        <Button
          positive
          onClick={cancelSale}
          content="取り下げる"
          className="positive_button"
        />
      </div>
    </div>
  );
}

export default enhance((props) => <SaleCloseModal {...props} />);
