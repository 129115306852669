import * as React from 'react';
import { Route } from 'react-router-dom';
import { NotFound } from '@/components/Pages';
import enhance from './enhancer';

function NotFoundRoutes() {
  return <Route component={NotFound} />;
}

export default enhance((props) => <NotFoundRoutes {...props} />);
