import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { search as validate } from '@/validates';

import { getRequestParameter } from './whiteListKeys';

import { setSearchResult } from '../../../utils/sales';
import { GA } from '@/utils';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const enhance = compose(
  setDisplayName('SideNav'),
  reduxForm({
    form: 'SideNavForm',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    submit: (props) => async () => {
      const {
        handleSearch,
        form: { SideNavForm },
      } = props;

      if (SideNavForm.values) {
        const { values } = SideNavForm;
        let resultQuery: any = '';

        if (values) {
          const { query } = getRequestParameter(values);

          // 検索ワードが入力されていれば、Google Analyticsに送信する
          if (query.keyword) {
            GA.trackingSearchWord(query.keyword);
          }

          resultQuery = query;
        }

        await handleSearch(resultQuery);
      } else {
        await handleSearch('');
      }
    },
  }),

  lifecycle({
    componentDidMount() {
      const { location, change } = this.props;

      setSearchResult(location, change);
    },
    componentDidUpdate(prevProps) {
      const { location, change, reset } = this.props;
      const { location: prevLocation } = prevProps;

      if (location.search !== prevLocation.search) {
        reset();
        setSearchResult(location, change);
      }
    },
  })
);

export default enhance;
