import * as React from 'react';
import enhance from './enhancer';
import { UserFollowItem } from '../UserFollowItem/';
import { Item } from 'semantic-ui-react';

function UserFollowList(props) {
  const { data, followUsers } = props;

  return (
    <div className="user-follow-list">
      <Item.Group divided>
        {data.map((item) => (
          <UserFollowItem
            key={item.id}
            name={item.isCompany ? item.company : item.displayName}
            icon={item.avatarUrl}
            selfIntroduction={item.about}
            followed={followUsers.indexOf(item.followId) >= 0}
            followUserId={item.followId}
          />
        ))}
      </Item.Group>
    </div>
  );
}

export default enhance((props) => <UserFollowList {...props} />);
