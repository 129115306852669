import React from 'react';
import { Loader } from 'semantic-ui-react';
import classNames from 'classnames';

const Thumb = (props) => {
  return (
    <div
      className="thumb_outer"
      key={typeof props.file === 'string' ? props.file : props.file.original}
    >
      <div
        className={classNames({
          thumb_image_outer: true,
          upload: props.isImageLoading,
        })}
      >
        {props.isImageLoading ? (
          <div className="loader_outer">
            <div className="loader_wrapper">
              <Loader active inline />
            </div>
          </div>
        ) : null}

        <img
          src={typeof props.file === 'string' ? props.file : props.file.thumb}
          className={classNames('thumb_image', {
            'first-image': props.firstImage,
          })}
          style={
            typeof props.file === 'string' ? {} : { ...props.file.thumbStyle }
          }
          onClick={() => {
            props?.onClick();
          }}
        />
        <div className="w__item-text">
          <div
            className="w-icon"
            onClick={() => {
              props.deleteDroppedImage(props.file);
            }}
            role="button"
            tabIndex="0"
          >
            削除
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thumb;
