import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, setDisplayName } from 'recompose';
import { reduxForm } from 'redux-form/immutable';

import { resetEmailModal as validate } from '@/validates';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const enhance = compose(
  setDisplayName('emailResetModal'),
  reduxForm({
    form: 'emailResetForm',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
