export const styles = {
  baseStyle: {
    display: 'flex',
    height: 160,
    borderWidth: 2,
    borderColor: '#cccc',
    borderStyle: 'dashed',
    borderRadius: 5,
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffff',
  },
  activeStyle: {
    borderStyle: 'solid',
    borderColor: '#35477d',
    backgroundColor: '#ddd',
  },
  acceptStyle: {
    borderStyle: 'solid',
    borderColor: '#35477d',
  },
  rejectStyle: {
    borderStyle: 'solid',
    borderColor: '#ff1744',
  },
};
