import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

function FieldCheckbox(props) {
  const { meta, input } = props;

  return (
    <>
      <Checkbox
        {...props}
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={!!input.value}
        label={props.label}
      />
      {meta.touched && meta.error && (
        <span className="redux_form error">{meta.error}</span>
      )}
    </>
  );
}

export default FieldCheckbox;
