import { Record } from 'immutable';

const AppRecord = Record({
  signUpSteps: {},
  reduxFormWorkSpace: {},
  headerText: '',
  tempEmail: '',
});

class AppState extends AppRecord {
  holdSignUpSteps(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('signUpSteps', action.payload);

      return draftState;
    });

    return nextState;
  }

  setTempEmail(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('tempEmail', action.payload.data);

      return draftState;
    });

    return nextState;
  }

  pushNotification(state) {
    return state;
  }

  updateHeaderText(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('headerText', action.payload.data);

      return draftState;
    });

    return nextState;
  }

  resetHeaderText(state) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('headerText', '');

      return draftState;
    });

    return nextState;
  }
}

export default AppState;
