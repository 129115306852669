import { getPasswordValidateMessage } from './utils';

export default function resetPasswordModal(values) {
  const errors = {};
  const oldPassword = values.get('oldPassword');
  const newPassword = values.get('newPassword');
  const confirmPassword = values.get('confirmPassword');

  // 現在のパスワード
  if (!oldPassword) {
    errors.oldPassword = 'パスワードを入力してください';
  } else if (oldPassword) {
    errors.oldPassword = getPasswordValidateMessage(oldPassword);
  }

  // 新パスワード
  if (!newPassword) {
    errors.newPassword = 'パスワードを入力してください';
  } else if (newPassword) {
    errors.newPassword = getPasswordValidateMessage(newPassword);
  }

  // 新パスワード確認用
  if (!confirmPassword) {
    errors.confirmPassword = '確認用のパスワードを入力してください';
  } else if (newPassword) {
    if (newPassword != confirmPassword) {
      errors.confirmPassword = 'パスワードと一致していません。';
    }
  }

  return errors;
}
