import formatDate from '@/apis/utils/formatDate';

export default function parseSaleCommentBefore({
  id,
  is_deleted,
  is_enabled,
  created_at,
  updated_at,
  sale_id,
  user_id,
  body,
  user_display_name,
  user_avatar_url,
  user_is_company,
  user_company,
  user_is_email_refused,
}) {
  const nextCreatedAt = formatDate(created_at);
  const nextUpdatedAt = formatDate(updated_at);

  return Object.assign(
    {},
    {
      id,
      is_deleted,
      is_enabled,
      created_at: nextCreatedAt,
      updated_at: nextUpdatedAt,
      sale_id,
      user_id,
      body,
      userDisplayName: user_display_name,
      userIsCompany: user_is_company,
      userCompany: user_company,
      icon: user_avatar_url,
      userIsEmailRefused: user_is_email_refused,
    }
  );
}
