import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { paths } from '@/config';

function SellButton() {
  return (
    <Link to={paths.sell} className="app_sell_button">
      <div className="w__btn">
        <span className="label">出品</span>
        <Icon name="camera" />
      </div>
    </Link>
  );
}

export default SellButton;
