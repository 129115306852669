export const regions = [
  { key: 'hokkaido', text: '北海道' },
  { key: 'kita_tohoku', text: '北東北' },
  { key: 'minami_tohoku', text: '南東北' },
  { key: 'tokyo', text: '東京' },
  { key: 'kanto', text: '関東' },
  { key: 'shinetsu', text: '信越' },
  { key: 'hokuriku', text: '北陸' },
  { key: 'tokai', text: '東海' },
  { key: 'kansai', text: '関西' },
  { key: 'chugoku', text: '中国' },
  { key: 'shikoku', text: '四国' },
  { key: 'kita_kyushu', text: '北九州' },
  { key: 'minami_kyushu', text: '南九州' },
  { key: 'okinawa', text: '沖縄' },
];
