/* @flow */
export default function contact(values) {
  const errors = {};
  const name = values.get('name');
  const email = values.get('email');
  const content = values.get('content');

  if (name && name.length > 40) {
    errors.name = '名前は40字以内で入力してください';
  }

  if (!email) {
    errors.email = 'メールアドレスを入力してください';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = 'メールアドレスが正しい形式ではありません';
  } else if (email.length > 255) {
    errors.email = 'メールアドレスは255文字以内で入力してください';
  }

  if (!content) {
    errors.content = '内容を入力してください';
  } else if (content.length > 500) {
    errors.content = '500文字以内で入力してください';
  }
  return errors;
}
