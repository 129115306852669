import React from 'react';
import { Field } from 'redux-form/immutable';
import enhance from './enhancer';

import { sorts, compnaySorts } from '../../Common/SideNav/data';
import Select from './/Select';
import ListSwitch from './ListSwitch';

import './style.scss';

const FishSort = (props) => {
  const { isCompany, handleSort } = props;
  const options = isCompany ? compnaySorts : sorts;

  return (
    <div className="a__fishe-sort">
      <div>
        <ListSwitch />
      </div>
      <div className="a__fishe-sort__inset">
        <Field
          component={Select}
          fluid
          name="sort"
          selection
          options={options}
          handleSort={handleSort}
        />
      </div>
    </div>
  );
};

export default enhance((props) => <FishSort {...props} />);
