import React from 'react';
import { useSection } from './useSection';

import { MeatSaleInfo } from '@/modules/meat';

// TODO: 項目追加
const DetailSection = ({
  explanation,

  // 追加
  ...others
}) => {
  const {
    now,
    fishingDate,
    providerName,
    decisionPrice,
    deliveryPrice,
  } = useSection({
    fishingDay: others.fishingDay,
    shippingProvider: others.shippingProvider,
    fixPrice: others.fixPrice,
    isLandAfterFix: others.isLandAfterFix,
    area: others.area,
    prefecture: others.prefecture,
    size: others.size,
    isCompany: others.isCompany,
  });

  return (
    <div className="modal__sell-confirm__main-detail">
      <p className="modal__sell-confirm__main-description">{explanation}</p>

      <MeatSaleInfo
        sales={{
          item: {
            ...others.previewData,
            postedAt: now,
            fishingDate,
            providerName,
            decisionPrice,
            deliveryPrice,
          },
        }}
        users={{ mydata: others.mydata }}
      />
    </div>
  );
};

export default DetailSection;
