import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { paths } from '@/config';

function FeatureList() {
  return (
    <div
      className={classNames({
        b__featureList_image_before: !window.isAuthed,
        b__featureList_image_after: window.isAuthed,
      })}
    >
      <div className="wrap">
        <div className="heading">Meat Saleを利用するにあたって</div>
        <div className="list_item">
          ・偽装表示の防止と不正競争防止法については
          <a
            href="https://www.meti.go.jp/policy/economy/chizai/chiteki/pdf/20181201unfaircompetitiontext.pdf            "
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          <br />
          （P. 35を参照してください）
        </div>
        <div className="list_item">
          ・商品が売れた場合、サービス利用料10%がかかります
        </div>
        <Link to={paths.introduction.explain}>
          <Button content="Meat Saleについて" />
        </Link>
      </div>
    </div>
  );
}

export default FeatureList;
