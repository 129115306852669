import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { Landing } from '@/components/Pages';
import enhance from './enhancer';

function LandingRoutes() {
  return <Route exact path={paths.landing} component={Landing} />;
}

export default enhance((props) => <LandingRoutes {...props} />);
