import React from 'react';
import { Input, Icon } from 'semantic-ui-react';

function FieldBid(props) {
  const { meta, input } = props;
  return (
    <div className="field-group">
      <div className="field-input">
        <div className="form-titile">
          <h2>入札</h2>
        </div>
        <div className="max-price">
          <p>入札上限金額</p>
        </div>
        <Input
          {...props}
          onChange={(event, field) => input.onChange(field.value)}
          onBlur={() => props.input.onBlur(props.input.value)}
          placeholder="入力してください"
          icon={<Icon name="yen" />}
          iconPosition="left"
          maxLength={8}
        />
      </div>
      <div className="w__error">
        {meta.touched && meta.error && (
          <div className="redux_form error">{meta.error}</div>
        )}
      </div>
    </div>
  );
}

export default FieldBid;
