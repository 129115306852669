import * as React from 'react';
import { Redirect } from 'react-router';
import enhance from './enhancer';

function MatchPathRedirect(props) {
  const { match, location, redirectUrl } = props;

  if (match.path !== location.pathname) {
    return <Redirect to={redirectUrl} />;
  }

  return null;
}

export default enhance((props) => <MatchPathRedirect {...props} />);
