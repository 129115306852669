import * as React from 'react';
import enhance from './enhance';

function CreditCardTile(props) {
  const { last4, expMonth, expYear } = props;

  const shapeCreditCardDate = (expMonth, expYear) => {
    let month = expMonth + '';
    let year = expYear + '';

    year = year.slice(2);

    if (month.length === 1) {
      month = '0' + expMonth;
    }

    return `${month}/${year}`;
  };

  return (
    <div className="a__card_tile">
      <div className="b__tile-top">
        <span>カード番号</span>
        <span>有効期限</span>
      </div>
      <div className="b__tile-bottom">
        <span>**** **** **** {last4}</span>
        <span>{shapeCreditCardDate(expMonth, expYear)}</span>
      </div>
    </div>
  );
}

export default enhance((props) => <CreditCardTile {...props} />);
