import React from 'react';

import './style.scss';

const Select = (props) => {
  const { options, name } = props;
  console.log(props.input?.value);

  return (
    <select
      name={name}
      className="a__fishe-sort__select"
      onChange={(event) => {
        props.handleSort(event.target.value);
      }}
      value={
        props.input?.value
          ? props.input?.value
          : '-biddable-fixed-bid_count-created_at'
      }
    >
      {options.map(({ value, text }) => {
        return (
          <option value={value} key={text}>
            {text}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
