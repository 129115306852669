import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { assets } from '@/config';
import { TitleBar } from '@/components/Common';

function Privacy() {
  const expandText = () => {
    return assets.policy.privacy.map((item) => (
      <React.Fragment key={item.key}>
        <Header size="medium" className="item_title">
          {item.title}
        </Header>
        <p
          className="item_body"
          dangerouslySetInnerHTML={{ __html: item.body }}
        />
      </React.Fragment>
    ));
  };

  return (
    <div className="app_privacy flex">
      <div className="wrap">
        <TitleBar title="プライバシーポリシー" />

        <section className="flex">
          <div className="contents">
            {expandText()}
            <ul>
              <li>住所: 〒550-0013大阪市西区新町１丁目４番２６号６階</li>
              <li>社名: Fresh Speed株式会社</li>
              <li>担当部署: xxx</li>
              <li>Eメールアドレス: xxxx@fishsale.co.jp</li>
              <li>2018年02月27日制定</li>
            </ul>
            <p>以上</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Privacy;
