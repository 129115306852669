import React from 'react';
import { Icon, Button } from 'semantic-ui-react';

import enhance from './enhancer';

const SearchBtn = (props) => {
  const { handleToggle } = props;

  return (
    <Button
      icon
      circular
      className="b__search-btn"
      onClick={() => handleToggle(true)}
    >
      <Icon name="search" />
    </Button>
  );
};
export default enhance((props) => <SearchBtn {...props} />);
