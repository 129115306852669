import { getSaleId } from '@/utils';

export default function onChangePage(props) {
  return async (current) => {
    const {
      updatePaginationDisabled,
      getSaleCommentsWithPagination,
      updateCommentsPagesActive,
    } = props;

    updateCommentsPagesActive(current);

    updatePaginationDisabled(true);
    await getSaleCommentsWithPagination(getSaleId(), {
      limit: 5,
      page: current,
    });
    updatePaginationDisabled(false);
  };
}
