import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { selection } from '@/utils';
import classNames from 'classnames';

const isEqual = (target, comp) => {
  return Number(target) === Number(comp);
};

const FishesPagination = (props) => {
  const { handleChangePage, currentPage, totalPages } = props;

  return (
    <div className="b__fishes__pagination">
      <button
        onClick={() => {
          handleChangePage(1);
        }}
        disabled={isEqual(currentPage, 1)}
        className={classNames('b__jump-button', {
          disabled: isEqual(currentPage, 1),
        })}
      >
        最初
      </button>

      <button
        onClick={() => {
          const num = Number(currentPage) - 1;
          handleChangePage(num);
        }}
        disabled={isEqual(currentPage, 1)}
        className={classNames('b__jump-button', {
          disabled: isEqual(currentPage, 1),
        })}
      >
        前へ
      </button>

      <Dropdown
        placeholder="ページ"
        value={Number(currentPage)}
        onChange={(e, { value }) => {
          if (!isEqual(currentPage, value)) {
            handleChangePage(value);
          }
        }}
        selection
        options={selection.create(totalPages)}
        noResultsMessage=""
      />

      <button
        onClick={() => {
          const num = Number(currentPage) + 1;
          handleChangePage(num);
        }}
        disabled={isEqual(currentPage, totalPages)}
        className={classNames('b__jump-button', {
          disabled: isEqual(currentPage, totalPages),
        })}
      >
        次へ
      </button>

      <button
        onClick={() => {
          const num = Number(totalPages);
          handleChangePage(num);
        }}
        disabled={isEqual(currentPage, totalPages)}
        className={classNames('b__jump-button', {
          disabled: isEqual(currentPage, totalPages),
        })}
      >
        最後
      </button>
    </div>
  );
};

export default FishesPagination;
