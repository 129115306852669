import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { UserFollow } from '@/components/Pages';
import enhance from './enhancer';

function UserFollowRoutes() {
  return <Route strict path={paths.users.follow} component={UserFollow} />;
}

export default enhance((props) => <UserFollowRoutes {...props} />);
