export const wrapper = {
  display: 'flex',
  justifyContent: 'center',
};

export const wrapperInner = {
  width: '340px',
};

export const title = {
  fontSize: '20px',
  textAlign: 'center',
  marginTop: '16px',
  marginBottom: '16px',
};

export const flexField = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
};

export const paragraph = {
  marginBottom: '16px',
  textAlign: 'center',
};

export const subTitle = {
  textAlign: 'left',
  fontSize: '14px',
};

export const checkboxLabel = {
  marginLeft: '6px',
};

export const submitBtn = {
  marginTop: '20px',
  height: '40px',
};
