import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

function FieldCheckboxAgreement(props) {
  const { meta, input } = props;
  return (
    <>
      <Checkbox
        {...props}
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={!!input.value}
      />
      <br />
      {meta.touched && meta.error && (
        <a id="error">
          <span className="redux_form error">{meta.error}</span>
        </a>
      )}
    </>
  );
}

export default FieldCheckboxAgreement;
