import React from 'react';
import { Image } from 'semantic-ui-react';
import { assets } from '@/config';

const Fee = () => {
  return (
    <div className="a__introduction_fee">
      <div className="w__introduction_fee">
        <header>サービス利用料について</header>
        <section>
          <Image src={assets.fee_1} />
        </section>
      </div>
    </div>
  );
};

export default Fee;
