import React from 'react';
import classNames from 'classnames';

interface Props {
  label: string;
  value: string;
  className?: string;
}

const CardBodyItem: React.FC<Props> = ({ label, value, className }) => (
  <div className={classNames('fish-card__body-item', className)}>
    <div className="fish-card__body-item__label">{label}</div>

    <div className="fish-card__body-item__value">{value}</div>
  </div>
);

export default CardBodyItem;
