import classNames from 'classnames';
import * as React from 'react';
import { Field } from 'redux-form/immutable';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Pagination from '@/migration/pagination';
import { Button, Header, Image, Dropdown } from 'semantic-ui-react';
import Modal from '@/migration/modal';

import { TitleBar } from '@/components/Common';
import { FieldTextArea } from '../../../Form';
import enhance from './enhancer';
import { ReviewToSeller } from './ReviewToSeller';
import { ReviewToBuyer } from './ReviewToBuyer';
import { ShippingInfo } from './ShippingInfo';
import { sellerTemplates, bidderTemplates } from '../template';

function TradeComments(props) {
  const isSeller = props.users.mydata.id === props.sales.item.sellerUserId;

  return (
    <div className="a__comments">
      <div className="w__shipping_info">
        {props.users.mydata.id === props.sales.item.sellerUserId && (
          <ShippingInfo
            userShippingData={props.shippingInfo}
            boughtUserId={props.sales.item.boughtUserId}
            boughtUserName={props.sales.item.boughtUserName}
          />
        )}
        {props.users.mydata.id === props.sales.item.boughtUserId && (
          <ShippingInfo userShippingData={props.shippingInfo} isBoughtUser />
        )}
      </div>
      <Header as="h2" className="fiex_chat_header">
        <TitleBar title="落札後チャット" />
      </Header>
      <p>※このチャットは出品者と落札者にしか見えないチャットです。</p>
      <div className="chat_input_area">
        <div className="form_wrap">
          <form
            className="flex"
            onSubmit={props.handleSubmit(props.submitComment)}
          >
            <div
              className="form_item"
              style={{ maxWidth: 640, marginBottom: 16 }}
            >
              <Dropdown
                name="template"
                selection
                placeholder="定型文を入力"
                fluid
                options={isSeller ? sellerTemplates : bidderTemplates}
                onChange={(event, field) => {
                  props.change('message', field.value);
                }}
              />
            </div>
            <div className="form_item">
              <Field
                rows={5}
                className="text_item"
                name="message"
                component={FieldTextArea}
                maxLength={500}
              />
            </div>
            <div
              className={classNames({
                error_message: true,
                display_error: props.commentErrorMessage,
              })}
            >
              コメントを入力してください
            </div>
            <div className="form_item flex_container">
              <div /> {/* 最大500文字を右に寄せるための空div */}
              <div className="chat_count_under">(最大500文字)</div>
            </div>
            <Button
              disabled={props.submitting}
              loading={props.submitting}
              content="書き込む"
              className="message_submit"
            />
          </form>
          <div className="link_for_review">
            <span>
              <Button
                content={
                  props.sales.item.userRated
                    ? '再レビュー画面に行く'
                    : 'レビュー画面に行く'
                }
                className="link_button"
                onClick={() => props.setShowModal(true)}
              />
              <Modal
                isModal={props.showModalToSeller}
                handleCloseModal={props.setShowModal}
                className="w__review-modal"
                size="xs"
                ModalBody={ReviewToSeller}
                modalProps={{
                  setShowModal: props.setShowModal,
                  id: props.sales.item.id,
                  sellerUserId: props.sales.item.sellerUserId,
                  mydata: props.users.mydata,
                  getSaleById: props.getSaleById,
                  getNotReviewedItem: props.getNotReviewedItem,
                }}
              />
              <Modal
                isModal={props.showModalToBuyer}
                handleCloseModal={props.setShowModal}
                className="w__review-modal"
                size="xs"
                ModalBody={ReviewToBuyer}
                modalProps={{
                  setShowModal: props.setShowModal,
                  id: props.sales.item.id,
                  boughtUserId: props.sales.item.boughtUserId,
                  mydata: props.users.mydata,
                  getSaleById: props.getSaleById,
                  getNotReviewedItem: props.getNotReviewedItem,
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="chat-list-area">
        <Loader
          active={props.isLoading}
          inline="centered"
          className="fished_loading"
        />
        {props.sales.comments.pages.active.map((row) => (
          <div className="chat-content-block flex" key={row.id}>
            <div className="chat-user">
              <div className="userThumbnail circle grid">
                <Image className="image_circle" src={row.icon} fluid />
              </div>
              {props.users.mydata.id === row.user_id ? (
                <Link to={`/mypage/all`}>
                  <div className="user-name">
                    {row.userIsCompany ? row.userCompany : row.userDisplayName}
                  </div>
                </Link>
              ) : (
                <Link to={`/users/${row.user_id}`}>
                  <div className="user-name">
                    {row.userIsCompany ? row.userCompany : row.userDisplayName}
                  </div>
                </Link>
              )}
            </div>
            <div className="triangle" />
            <div className="chat-content">
              <p className="date">{row.created_at}</p>
              <p className="content">{row.body}</p>
            </div>
          </div>
        ))}
        <Pagination
          totalCount={props.sales.comments.totalCount}
          current={props.sales.comments.current}
          pageSize={5}
          changePage={props.onChangePage}
          disabled={props.isPaginationDisabled}
        />
      </div>
    </div>
  );
}

export default enhance((props) => <TradeComments {...props} />);
