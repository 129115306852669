import { httpClient } from '@/utils/http';

import { getEnv } from '@/utils';

const { SITE_TYPE } = getEnv();
const resouce = `/v1/${SITE_TYPE}/preview`;

export const sellPreview = async (sellData) => {
  try {
    const client = await httpClient();
    const { data } = await client.post(resouce, sellData);

    return { data };
  } catch (error) {
    throw new Error(error);
  }
};
