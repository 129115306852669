import { createActions } from 'redux-actions';
import {
  deleteSale,
  getSaleBids,
  getSaleById,
  getSales,
  postSale,
  postSaleBid,
  postSaleComment,
  postSaleCommentBefore,
  getSalesWithPagination,
  getSaleCommentsWithPagination,
  getSaleCommentsBeforePagination,
  getUnratedWithPagination,
} from '@/apis';

import { getSalesPagination } from '../../apis/sales/getSalesPagination';

import getUsersCompany from '../../apis/sales/getUsersCompany';

export default createActions({
  getSales: async (pagination, condition, order) =>
    getSales(pagination, condition, order),

  // Deprecated 商品一覧 / すべて あとで削除
  getSalesWithPagination: async (pagination, conditions, sort) => {
    const result = await getSalesWithPagination(pagination, conditions, sort);
    return Object.assign({}, result, pagination);
  },

  // サイドメニュー 商品一覧 / 全て・個人
  getSalesPagination: async (page, params) => {
    const result = await getSalesPagination(params);

    return {
      ...result,
      page,
    };
  },

  // 商品一覧 / 会社
  getSalesCompany: async (page, sort) => {
    const result = await getUsersCompany(page, sort);
    return {
      ...result,
      currentPage: page,
    };
  },

  updateFishesPagesActive: (current) => ({ current }),

  updateUserProfilePageActive: (current) => ({ current }),

  getOwnExhibitsCounts: async (pagination, conditions, sort) => {
    const result = await getSalesWithPagination(pagination, conditions, sort);
    return Object.assign({}, result, pagination);
  },

  getSalesForMyPage: async (pagination, conditions, sort) => {
    const result = await getSalesWithPagination(pagination, conditions, sort);
    return Object.assign({}, result, pagination);
  },

  getUnratedWithPagination: async (pagination, conditions, sort, myId) => {
    const result = await getUnratedWithPagination(
      pagination,
      conditions,
      sort,
      myId
    );
    return Object.assign({}, result, pagination);
  },

  getSalesForUserProfile: async (
    limitOptions,
    pagination,
    conditions,
    sort
  ) => {
    const result = await getSales(limitOptions, conditions, sort);
    const resultWithPagination = await getSalesWithPagination(
      pagination,
      conditions,
      sort
    );
    return Object.assign({}, result, { resultWithPagination });
  },

  updateCommentsPagesActive: (current) => ({ current }),

  updateCommentsBeforePagesActive: (current) => ({ current }),

  updateMyPagePagesActive: (current) => ({ current }),

  initializeSalesForMyPage: () => null,

  getSaleBids: async (saleId) => getSaleBids(saleId),

  getSaleById: async (saleId) => getSaleById(saleId),

  searchSales: async (category, area) => {
    const sales = await getSales({ limit: 3000 }, {}, [
      '-biddable',
      '-fixed',
      '-created_at',
    ]);
    return { sales, category, area };
  },

  postSale: async (data) => postSale(data),

  postSaleBid: async (saleId, amount, shippingInfoId) =>
    postSaleBid(saleId, {
      price: amount,
      shipping_info_id: shippingInfoId,
    }),

  updateBids: async (data, amount, userId) => ({
    ...data,
    bidderUserId: userId,
    price: amount,
  }),

  // TODO どこでつかわれているか確認
  getSaleComments: async () => null,

  getSaleCommentsWithPagination: async (saleId, pagination) =>
    getSaleCommentsWithPagination(saleId, pagination),

  getSaleCommentsBeforePagination: async (saleId, pagination) =>
    getSaleCommentsBeforePagination(saleId, pagination),

  postSaleComment: async (saleId, message) => postSaleComment(saleId, message),

  postSaleCommentBefore: async (saleId, message) =>
    postSaleCommentBefore(saleId, message),

  deleteSale: async (saleId, lastUpdatedAt) =>
    deleteSale(saleId, lastUpdatedAt),

  getSalesBySeller: async (pagenation, condition, order) =>
    getSales(pagenation, condition, order),

  cleanSaleComments: () => [],

  /** Landingページで使用 */
  getSalesForLanding: async () => {
    const data = await getSales({ limit: 20 }, {}, [
      '-biddable',
      '-fixed',
      '+close_bid_at',
      '-created_at',
    ]);

    return { data };
  },

  getSalesOwnerAll: async (pagenation, myId) =>
    getSales(pagenation, { seller: [`${myId}`] }, [
      '-biddable',
      '-fixed',
      '-bid_count',
      '-created_at',
    ]),
});
