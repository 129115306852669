import { Auth } from '@/utils';
import { Base64 } from 'js-base64';
import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { reduxForm } from 'redux-form/immutable';
import storage from 'store';
import { confirmSignUp as validate } from '@/validates';
import { appAction, usersAction } from '../../../actions';
import { paths } from '../../../config';
import { getUserNameByPathParams, hasUserNameByPathParams } from './utils';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, ...appAction, notify, push }, dispatch);

const enhance = compose(
  setDisplayName('ConfirmSignUp'),
  reduxForm({
    form: 'confirmSignUp',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withState('isResendLoading', 'setIsResendLoading', false),
  withState('disabledResend', 'preventStrikes', false),
  withHandlers({
    submitSignUpConfirm: (props) => async (immutableValues) => {
      const values = immutableValues.toJS();
      const username = getUserNameByPathParams();
      const { history, setIsLoading, preventStrikes, push } = props;

      setIsLoading(true);

      try {
        // 登録
        await Auth.confirmSignUp(username, values.code);

        if (!storage.get('AutoAuthorization')) {
          // ログイン情報がなかった場合、
          // TODO: Closed Beta期間が終われば signUpThank に飛ばす
          history.push(paths.before.signin);
          props.holdSignUpSteps();
          storage.clearAll();
          notify(
            'ご登録、誠にありがとうございます。ログインしてから、ご利用ください。',
            'success',
            undefined,
            5
          );
          return;
        }
      } catch (error) {
        const instruction =
          error.code === 'ExpiredCodeException' ? '再送信' : '確認';
        preventStrikes(false);
        notify(
          `認証に失敗しました。認証コードを${instruction}してください。`,
          'error',
          undefined,
          5
        );
        setIsLoading(false);

        return;
      }

      try {
        // 認証
        const storeValues = storage.get('AutoAuthorization');
        storage.remove('AutoAuthorization');

        await Auth.signIn(
          Base64.decode(storeValues.email),
          Base64.decode(storeValues.password)
        );

        // 遷移前にisAuthedを変更するとAfterRoutesに変わってしまう。
        // BeforeRoutesで動作させるための順序です。

        // TODO: Closed Beta期間が終わればコメントアウトを解除する
        // storage.set('signupThank', true);
        // window.isAuthed = await checkSession();
        // TODO: Closed Beta期間が終われば signUpThank に飛ばす
        push(paths.before.signin);
        props.holdSignUpSteps();
        storage.clearAll();
        notify(
          'ご登録、誠にありがとうございます。ログインしてから、ご利用ください。',
          'success',
          undefined,
          5
        );
        setIsLoading(false);
      } catch (error) {
        preventStrikes(false);
        notify(
          '認証に失敗しました。認証コードを確認してください。',
          'error',
          undefined,
          5
        );
        setIsLoading(false);

        return;
      }
    },
    resendConfirmCode: (props) => async (event) => {
      event.preventDefault();
      const username = getUserNameByPathParams();
      const { setIsResendLoading, preventStrikes } = props;
      preventStrikes(true);
      setIsResendLoading(true);

      try {
        await Auth.resendSignUp(username);
        setIsResendLoading(false);
        notify('認証コードを再送信しました。', 'info', undefined, 5);
      } catch (error) {
        preventStrikes(false);
        setIsResendLoading(false);

        notify(
          '認証コードの再送信に失敗しました。時間を置いて再度お試しください。',
          'error',
          undefined,
          5
        );
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { getMe, push } = this.props;

      try {
        !hasUserNameByPathParams && push(paths.landing);

        if (window.isAuthed) {
          getMe();
        }
      } catch (error) {
        // eslint-disable-next-line
      }
    },
  })
);

export default enhance;
