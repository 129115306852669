/* @flow */
import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { assets } from '@/config';
import { TitleBar } from '@/components/Common';

function Terms() {
  const introduction = (
    <p className="item_body">
      この規約（以下、「本利用規約」といいます。）は、Fresh
      Speed株式会社（以下、「当社」といいます。）が運営するインターネットサービス「Fish
      Sale」において、当社が提供する各種サービス（以下、「本サービス」といいます。）の利用に関する条件を定めるものです。
    </p>
  );

  const expandText = () => {
    return assets.policy.terms.map((item) => (
      <React.Fragment key={item.key}>
        <Header size="medium" className="item_title">
          {item.title}
        </Header>
        <p
          className="item_body"
          dangerouslySetInnerHTML={{ __html: item.body }}
        />
      </React.Fragment>
    ));
  };

  return (
    <div className="app_terms flex">
      <div className="wrap">
        <TitleBar title="ご利用規約" />
        <section className="flex">
          <div className="contents">
            {introduction}
            {expandText()}
            <br />
            <p className="update-at">（2019年8月9日制定）</p>
            <p className="update-at">（2021年2月9日改定）</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Terms;
