import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import {
  IntroductionAccount,
  IntroductionBanner,
  IntroductionFee,
  IntroductionCooking,
  IntroductionSell,
  IntroductionCard,
  IntroductionStyrofoam,
  IntroductionExplain,
  IntroductionSalesAccount,
  IntroductionHowtoSalesAccount,
  IntroductionChangeSalesAccount,
  NotFound,
  IntroductionPrepayment,
  IntroductionStripeFaq,
} from '@/components/Pages';
import enhance from './enhancer';

function IntroductionRoutes() {
  return (
    <>
      <Route exact path={paths.introduction.root} component={NotFound} />
      <Route
        strict
        path={paths.introduction.account}
        component={IntroductionAccount}
      />
      <Route strict path={paths.introduction.fee} component={IntroductionFee} />
      <Route
        strict
        path={paths.introduction.banner}
        component={IntroductionBanner}
      />
      <Route
        strict
        path={paths.introduction.cooking}
        component={IntroductionCooking}
      />
      <Route
        strict
        path={paths.introduction.sell}
        component={IntroductionSell}
      />
      <Route
        strict
        path={paths.introduction.card}
        component={IntroductionCard}
      />
      <Route
        strict
        path={paths.introduction.styrofoam}
        component={IntroductionStyrofoam}
      />
      <Route
        strict
        path={paths.introduction.explain}
        component={IntroductionExplain}
      />
      <Route
        strict
        path={paths.introduction.sales_account}
        component={IntroductionSalesAccount}
      />
      <Route
        strict
        path={paths.introduction.howto_sales_account}
        component={IntroductionHowtoSalesAccount}
      />
      <Route
        strict
        path={paths.introduction.change_sales_account}
        component={IntroductionChangeSalesAccount}
      />
      <Route
        strict
        path={paths.introduction.prepayment}
        component={IntroductionPrepayment}
      />
      <Route
        strict
        path={paths.introduction.faq}
        component={IntroductionStripeFaq}
      />
    </>
  );
}

export default enhance((props) => <IntroductionRoutes {...props} />);
