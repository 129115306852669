import { setDisplayName, withState, lifecycle, compose } from 'recompose';
import loadjs from 'loadjs';

const enhance = compose(
  setDisplayName('ReCAPTCHA'),
  withState('grecaptcha', 'grecaptchaUpdater', null),
  lifecycle({
    async componentDidMount() {
      const { grecaptchaUpdater } = this.props;

      loadjs(['https://www.google.com/recaptcha/api.js'], () => {
        window.grecaptcha.ready(() => {
          grecaptchaUpdater({ ...window.grecaptcha });
        });
      });
    },
  })
);

export default enhance;
