import { getPasswordValidateMessage } from './utils';

export default function confirmPassword(values) {
  const errors = {};
  const code = values.get('code');
  const password = values.get('password');
  const confirmPassword = values.get('confirmPassword');

  // 確認コード
  if (!code || code === '') {
    errors.code = '認証コードを入力してください';
  }

  // パスワード確認
  if (!password) {
    errors.password = '新しいパスワードを入力してください';
  } else if (password) {
    errors.password = getPasswordValidateMessage(password);
  }

  // パスワード確認用
  if (!confirmPassword) {
    errors.confirmPassword = '新しいパスワード（確認用）を入力してください';
  } else if (password) {
    if (password != confirmPassword) {
      errors.confirmPassword = 'パスワードと一致していません。';
    }
  }

  return errors;
}
