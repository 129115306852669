import { listViewStore, listViewType } from '@/store';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { GridIcon, ListIcon } from './icons';
import Cookies from 'js-cookie';

import './style.scss';

const ListSwitch = () => {
  const [listView, setListView] = useRecoilState(listViewStore);

  const handleSwitch = (val: listViewType) => {
    Cookies.set('salesView', val);
    setListView(val);
  };

  useEffect(() => {
    const view = Cookies.get('salesView') as string | undefined;
    if (!view) {
      Cookies.set('salesView', 0);
      return;
    }

    return setListView(parseInt(view) as listViewType);
  }, []);

  return (
    <div className="view-switch">
      <button
        type="button"
        className={listView === 0 ? '-active' : undefined}
        onClick={() => handleSwitch(0)}
      >
        <GridIcon />
      </button>
      <button
        type="button"
        className={listView === 1 ? '-active' : undefined}
        onClick={() => handleSwitch(1)}
      >
        <ListIcon />
      </button>
    </div>
  );
};

export default ListSwitch;
