export default function report(values) {
  const errors = {};
  const reporterName = values.get('reporterName');
  const email = values.get('reporterEmail');
  const governments = values.get('governments');
  const reportContent = values.get('reportContent');

  // 通報者名
  if (!reporterName || reporterName.trim() === '') {
    errors.reporterName = '通報者名を入力してください';
  }

  // メールアドレス
  if (!email) {
    errors.reporterEmail = 'メールアドレスを入力してください';
  } else if (email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
      errors.reporterEmail = 'メールアドレスが正しい形式ではありません';
    } else if (email.length > 255) {
      errors.reporterEmail = 'メールアドレスは255文字以内で入力してください';
    }
  }

  // 通報済み政府機関
  if (!governments) {
    errors.governments = '通報済み政府機関を選択してください';
  }

  // 通報内容
  if (!reportContent || reportContent.trim() === '') {
    errors.reportContent = '通報内容を入力してください';
  } else if (reportContent && reportContent.length > 500) {
    errors.reportContent = '500文字以内で入力してください';
  }

  return errors;
}
