import { useEffect, useState } from 'react';
import { CamelDetailType } from '@/types';
import { getSalesDetails } from '@/apis';
import { snakeToCamel } from './utils';

export const useDetailList = (ids: number[]) => {
  const [detailsData, setDetailsData] = useState<CamelDetailType[]>([]);

  useEffect(() => {
    (async () => {
      if (ids.length > 0) {
        if (
          detailsData.length > 0 &&
          detailsData.filter((f) => !ids.includes(f.saleId)).length === 0
        )
          return;
        try {
          const { data } = await getSalesDetails(ids);
          const result: CamelDetailType[] = [];
          for (const e in data) {
            result.push(snakeToCamel(data[e]));
          }
          setDetailsData(result);
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    })();
  }, [ids]);

  return { detailsData };
};
