import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as R from 'ramda';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withStateHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';

import { salesAction, usersAction } from '@/actions';
import { getSaleId, date } from '@/utils';
import { sale as validate } from '@/validates';
import { onChangePage, submitComment, buildCommentWithAddress } from './misc';

import { constants } from '../../../../constants';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, ...salesAction, ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('TradeComments'),
  reduxForm({
    form: 'tradeComments',
    initialValues: {
      applyAddress: false,
      message: '',
    },
    enabledReinitialize: true,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'updateLoading', false),
  withState('showModalToSeller', 'updateModalToSeller', false),
  withState('showModalToBuyer', 'updateModalToBuyer', false),
  withStateHandlers(
    {
      commentErrorMessage: false,
      isPaginationDisabled: false,
      isLoading: true,
      temporaryComment: '',
    },
    {
      updateCommentErrorMessage: () => (value) => ({
        commentErrorMessage: typeof value === 'boolean' ? value : true,
      }),
      updatePaginationDisabled: () => (value) => ({
        isPaginationDisabled: value,
      }),
      updateLoading: () => (value) => ({
        isLoading: value,
      }),
      takeTemporaryComment: () => (value) => ({
        temporaryComment: value,
      }),
    }
  ),
  withHandlers({
    onChangePage: onChangePage,
    submitComment: submitComment,
    setShowModal: (props) => (type) => {
      // componentDidMountの時
      if (type === undefined) {
        const now = date.now();
        // 現在の日付から10日引く
        const day = date.minusDays(now, 10);

        if (props.sales.item.fixedAt < day) {
          props.users.mydata.id === props.sales.item.sellerUserId
            ? props.updateModalToBuyer(!props.showModalToBuyer)
            : props.updateModalToSeller(!props.showModalToSeller);
        }
      } else if (type) {
        // レビュー画面に行くをクリックした時
        props.users.mydata.id === props.sales.item.sellerUserId
          ? props.updateModalToBuyer(!props.showModalToBuyer)
          : props.updateModalToSeller(!props.showModalToSeller);
      } else {
        props.users.mydata.id === props.sales.item.sellerUserId
          ? props.updateModalToBuyer(false)
          : props.updateModalToSeller(false);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        users: { mydata, notReviewedItem },
        getSaleCommentsWithPagination,
        getUserShipping,
        updateLoading,
        updateCommentsPagesActive,
        setShowModal,
      } = this.props;

      updateLoading(true);

      await getSaleCommentsWithPagination(getSaleId(), {
        limit: 5,
        page: constants.PAGINATION.INITIAL,
      });

      updateCommentsPagesActive(constants.PAGINATION.INITIAL);
      getUserShipping(mydata.id);
      updateLoading(false);
      if (!notReviewedItem.id) {
        null;
      } else {
        setTimeout(async () => {
          await setShowModal();
        }, 3000);
      }
    },
    componentDidUpdate(prevProps) {
      const { form: PrevForm } = prevProps;
      const {
        form,
        users: { myshippingdata },
        change,
        takeTemporaryComment,
        temporaryComment,
      } = this.props;

      const prevApplyAddress = R.path(
        ['tradeComments', 'values', 'applyAddress'],
        PrevForm
      );
      const applyAddress = R.path(
        ['tradeComments', 'values', 'applyAddress'],
        form
      );

      if (prevApplyAddress !== applyAddress) {
        const message = R.path(['tradeComments', 'values', 'message'], form);

        takeTemporaryComment(message);

        applyAddress &&
          change('message', buildCommentWithAddress(message, myshippingdata));
        !applyAddress && change('message', temporaryComment);
      }
    },
  })
);

export default enhance;
