import React from 'react';
import classNames from 'classnames';
import Modal from '@/migration/modal';

import { CLOSED_REASON } from '../../../../constants';
import { FixModal } from '../FixModal';
import { BidModal } from '../BidModal';
import { SaleCloseModal } from '../SaleCloseModal';
import { BuyItNowModal } from '../BuyItNowModal';
import { Field } from 'redux-form/immutable';
import { Button } from 'semantic-ui-react';
import { FieldBid } from '../../../Form';
import FieldRadioBid from '@/components/Form/FieldRadioBid/FieldRadioBid';
import enhance from './enhancer';

import { getLocaleStringLocales } from '@/utils';
import { isValidShippingData } from '../isValidShippingData';

function BidButton(props) {
  const {
    sales: {
      item: {
        closedReason,
        fixed,
        auctioned,
        sellerUserId,
        closed,
        bidderUserId,
        bidderName,
        boughtUserId,
        bit,
        fixPrice,
        price,
        lastUpdatedAt,
        shippingExpense,
      },
    },
    users: { mydata, myshippingdata },
    isOpenModal,
    handleModal,
    handleSubmit,
    placeBids,
    isOpenConfirmModal,
    handleConfirmModal,
    isLoading,
    handleOpenBidModal,
    pristine,
    valid,
    validateAmount,
    handleCheckValidate,
    isOpenBuyItNowModal,
    handleBuyItNowModal,
    handleOpenBuyItNowModal,
    buyItNow,
    isMountLoading,
    form,
    shippingCost,
    coolDeliveryCost,
    shippingRelayCost,
  } = props;

  if (closedReason === CLOSED_REASON.EXPIRED) {
    return (
      <div className="a__bid_button">
        <Button
          type="button"
          className={classNames('button_gray', 'sale_withdrawal', {
            display_hidden: fixed || auctioned,
          })}
        >
          出品期限が切れました
        </Button>
      </div>
    );
  } else if (closedReason === CLOSED_REASON.CANCELED) {
    return (
      <div className="a__bid_button">
        <Button
          type="button"
          className={classNames('button_gray', 'sale_withdrawal', {
            display_hidden: fixed || auctioned,
          })}
        >
          取り下げられました
        </Button>
      </div>
    );
  } else if (closedReason === CLOSED_REASON.FAILED_CHARGING) {
    return (
      <div className="a__bid_button">
        <Button
          type="button"
          className={classNames('button_gray', 'sale_withdrawal', {
            display_hidden: fixed || auctioned,
          })}
        >
          決済が失敗しました
        </Button>
      </div>
    );
  } else if (fixed) {
    return (
      <div className="a__bid_button">
        <Button
          type="button"
          className={classNames('button_gray', {
            sale_withdrawal: true,
            display_hidden: auctioned,
          })}
        >
          落札されました
        </Button>
      </div>
    );
  } else if (sellerUserId === mydata.id && !closed && !fixed) {
    return (
      <form className="a__bid_button">
        <Button
          type="button"
          className={classNames('sale_withdrawal', {
            display_hidden: fixed || auctioned,
          })}
          onClick={handleModal}
        >
          出品を取り下げる
        </Button>
        {/* 出品取り下げモーダル */}
        <Modal
          isModal={isOpenModal}
          handleCloseModal={handleModal}
          size="xs"
          ModalBody={() => (
            <SaleCloseModal
              modalProps={{
                isOpenModal,
                handleModal,
                bidderUserId,
                bidderName,
                lastUpdatedAt,
              }}
            />
          )}
        />
      </form>
    );
  } else {
    return (
      <form
        onSubmit={handleSubmit(handleOpenBidModal)}
        className={classNames({
          selling_form: true,
        })}
      >
        <div className="sale_price_message">
          {mydata.id === bidderUserId && !fixed && (
            <>
              <h2>入札</h2>
              <div className="bidder_user_message">
                現在、最高額入札者です。
              </div>
              <div className="bidder_user_message">{`(入札上限金額: ￥${price})`}</div>
            </>
          )}
          {mydata.id === boughtUserId && fixed && (
            <div
              className={classNames('bidder_user_message', {
                forceGray: !bit && !auctioned,
              })}
            >
              あなたが落札者です。
            </div>
          )}
          {!fixed &&
            mydata.isCustomer &&
            mydata.id !== bidderUserId &&
            isValidShippingData(myshippingdata) &&
            !isMountLoading && (
              <Field
                name="amount"
                component={FieldBid}
                disabled={
                  mydata.id === bidderUserId ||
                  !mydata.isCustomer ||
                  !isValidShippingData(myshippingdata)
                }
                className={classNames({
                  display_hidden: fixed,
                })}
                validate={[validateAmount]}
                onChange={handleCheckValidate}
              />
            )}
        </div>
        {isValidShippingData(myshippingdata) ? (
          <>
            送付先住所
            <ul className="delivery_address">
              {(myshippingdata.length === undefined ? [] : myshippingdata).map(
                ({ address, id }) => (
                  <li key={id}>
                    <Field
                      displayLabel={address}
                      component={FieldRadioBid}
                      label={String(id)}
                      name="address"
                      value={id}
                    />
                  </li>
                )
              )}
            </ul>
          </>
        ) : null}
        <Button
          disabled={
            mydata.id === bidderUserId ||
            !mydata.isCustomer ||
            !isValidShippingData(myshippingdata) ||
            pristine ||
            !valid ||
            closedReason === CLOSED_REASON.FAILED_CHARGING
          }
          className={classNames({
            display_hidden: fixed,
          })}
          type="button"
          loading={isLoading}
          onClick={handleOpenBidModal}
        >
          この金額まで自動で入札する
        </Button>
        {/* 即決ボタン */}
        <Button
          disabled={
            !mydata.isCustomer ||
            !isValidShippingData(myshippingdata) ||
            fixPrice >= 2147483647 ||
            closedReason === CLOSED_REASON.FAILED_CHARGING
          }
          className={classNames('buy_it_now', {
            display_hidden: fixed,
          })}
          type="button"
          loading={isLoading}
          onClick={handleOpenBuyItNowModal}
        >
          {fixPrice >= 2147483647
            ? '即決する'
            : `¥${getLocaleStringLocales(fixPrice)}で即決する`}
        </Button>
        <div className="suggestion-price">
          入札上限金額を入力してください。
          <br />
          落札できるように上限金額まで10円単位で自動的に入札します。
        </div>

        {/* 入札モーダル */}
        <Modal
          isModal={isOpenModal}
          handleCloseModal={handleModal}
          className="sale_modal_container"
          size="xs"
          ModalBody={() => (
            <BidModal
              modalProps={{
                handleSubmit,
                isOpenModal,
                handleModal,
                placeBids,
                shippingCost,
                coolDeliveryCost,
                shippingRelayCost,
                shippingExpense,
                isBidPopup: mydata?.isBidPopup || false,
              }}
            />
          )}
        />

        <Modal
          isModal={isOpenConfirmModal}
          handleCloseModal={handleConfirmModal}
          className="sale_modal_container"
          size="xs"
          ModalBody={() => (
            <FixModal
              modalProps={{
                handleSubmit,
                isOpenConfirmModal,
                handleConfirmModal,
                placeBids,
                shippingExpense,
                isBidPopup: mydata?.isBidPopup || false,
              }}
            />
          )}
        />

        {/* 即決モーダル */}
        <Modal
          isModal={isOpenBuyItNowModal}
          handleCloseModal={handleBuyItNowModal}
          className="sale_modal_container"
          size="xs"
          ModalBody={() => (
            <BuyItNowModal
              modalProps={{
                form,
                isOpenBuyItNowModal,
                handleBuyItNowModal,
                buyItNow,
                shippingCost,
                coolDeliveryCost,
                shippingRelayCost,
                fixPrice,
                shippingExpense,
                isBidPopup: mydata?.isBidPopup || false,
              }}
            />
          )}
        />
      </form>
    );
  }
}

export default enhance((props) => <BidButton {...props} />);
