import { compose, setDisplayName, lifecycle } from 'recompose';

const enhance = compose(
  setDisplayName('Law'),
  lifecycle({
    componentDidMount: () => {
      if (window.location.hash) {
        const element = window.document.querySelector(window.location.hash);
        const position = element.getBoundingClientRect();

        window.scrollTo(0, position.top);
      }
    },
  })
);

export default enhance;
