export default function queryBuilder(...params) {
  let query = [];
  params.forEach((p) => {
    query = query.concat(p);
  });

  if (query.length === 0) {
    return '';
  }

  return `?${query.join('&')}`;
}
