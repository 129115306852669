import { useMemo } from 'react';
import moment from 'moment';

import { MINUTE_MILLISECOND } from '@/constants/timeType';

const HOUR = 60;

export const useSection = ({ closeBidAt }) => {
  const { isLessHour, diff } = useMemo(() => {
    const date = new Date();
    const _date = new Date(closeBidAt);

    const diff = Math.round(
      (_date.getTime() - date.getTime()) / MINUTE_MILLISECOND
    );

    const isLessHour = diff < HOUR;

    return {
      isLessHour,
      diff,
    };
  }, [closeBidAt]);

  const restTime = useMemo(() => {
    if (!closeBidAt) return '';

    return moment(closeBidAt).format('YYYY.MM.DD (dd)');
  }, [closeBidAt]);

  return { isLessHour, diff, restTime };
};
