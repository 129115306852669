export default [
  {
    key: 'from_new_arrival',
    text: '新着順',
    value: '-biddable-fixed-created_at',
  },
  {
    key: 'from_cheap',
    text: '価格の安い順',
    value: '-biddable-fixed+display_price-created_at',
  },
  {
    key: 'from_expensive',
    text: '価格の高い順',
    value: '-biddable-fixed-display_price-created_at',
  },
  {
    key: 'from_shorter',
    text: '残り時間の短い順',
    // value: '-biddable-fixed+close_bid_at-created_at',
    value: '-biddable-fixed_at+close_bid_at',
  },
  {
    key: 'from_longer',
    text: '残り時間の長い順',
    value: '-biddable-fixed-close_bid_at-created_at',
  },
  {
    key: 'from_cheap_buy_it_now',
    text: '即決価格の安い順',
    value: '-biddable-fixed+fix_price-created_at',
  },
  {
    key: 'from_expensive_buy_it_now',
    text: '即決価格の高い順',
    value: '-biddable-fixed-fix_price-created_at',
  },
  {
    key: 'from_more_count',
    text: '入札件数の多い順',
    value: '-biddable-fixed-bid_count-created_at',
  },
  {
    key: 'from_less_count',
    text: '入札件数の少ない順',
    value: '-biddable-fixed+bid_count-created_at',
  },
];
