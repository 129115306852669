import { connect } from 'react-redux';
import { compose, setDisplayName, withState, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const enhance = compose(
  setDisplayName('BidModal'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('redListCheck', 'updateRedListCheck', false),
  withState('rareSpeciesCheck', 'updateRareSpeciesCheck', false),
  withState('islandCheck', 'updateIslandCheck', false),

  withHandlers({
    toggleRedList: (props) => () => {
      const { updateRedListCheck, redListCheck } = props;
      updateRedListCheck(!redListCheck);
    },
    toggleRareSpecies: (props) => () => {
      const { updateRareSpeciesCheck, rareSpeciesCheck } = props;
      updateRareSpeciesCheck(!rareSpeciesCheck);
    },
    toggleIslandCheck: (props) => () => {
      const { islandCheck, updateIslandCheck } = props;
      updateIslandCheck(!islandCheck);
    },
  })
);

export default enhance;
