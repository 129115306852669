import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { appAction, salesAction, usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...salesAction, ...appAction, ...usersAction },
    dispatch
  );

const enhance = compose(
  setDisplayName('Admin'),

  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance;
