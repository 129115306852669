import React from 'react';
import Rating from 'react-rating';
import { Link } from 'react-router-dom';

import { Icon } from 'semantic-ui-react';

const Header = ({
  id,
  name,
  address,
  sales_count,
  average_point,
  total_evaluation_count,
}) => (
  <div className="b__fishes-company__header">
    <a
      href={`/users/${id}`}
      target="_blank"
      rel="noopener noreferrer"
      className="name"
    >
      {name}
    </a>
    <div className="address">{address}</div>
    <div className="evalution">
      <span className="count">{`出品数 ${sales_count}`}</span>
      <span>{average_point}</span>
      <Rating
        initialRating={average_point}
        readonly
        emptySymbol={<Icon name="star" color="grey" />}
        fullSymbol={<Icon name="star" color="yellow" />}
      />

      <Link to={`/users/${id}`} className="eva-count">
        ({total_evaluation_count}件の評価)
      </Link>
    </div>
  </div>
);

export default Header;
