import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { Contact } from '@/components/Pages';
import enhance from './enhancer';

function ContactRoutes() {
  return <Route strict path={paths.common.contact} component={Contact} />;
}

export default enhance((props) => <ContactRoutes {...props} />);
