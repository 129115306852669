export default function leave(values) {
  const errors = {};
  const reason = values.get('reason');
  const detail = values.get('detail');

  // 退会理由
  if (!reason) {
    errors.reason = '退会理由を選択してください';
  }

  // 詳細
  if (!detail || detail.trim() === '') {
    errors.detail = '詳しい内容を入力してください';
  } else if (detail.length > 500) {
    errors.detail = '500文字以内で入力してください';
  }

  return errors;
}
