import React from 'react';
import { Button } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import enhance from './enhancer';
import { FieldInput } from '../../../../Form';

function ResetPasswordModal(props) {
  const { resetPassword, loading } = props;

  return (
    <form className="flex">
      <div className="a__reset-password-modal">
        <div className="title">
          <h1>パスワードリセット</h1>
        </div>
        <div className="b__field">
          <Field
            type="password"
            name="oldPassword"
            fluid
            component={FieldInput}
            placeholder="現在のパスワード"
            disabled={loading}
          />
        </div>

        <div className="b__field">
          <Field
            type="password"
            name="newPassword"
            fluid
            component={FieldInput}
            placeholder="新しいパスワード"
            disabled={loading}
          />
        </div>

        <div className="b__field">
          <Field
            type="password"
            name="confirmPassword"
            fluid
            component={FieldInput}
            placeholder="新しいパスワード (確認用)"
            disabled={loading}
          />
        </div>

        <div className="w__actions">
          <Button
            positive
            type="button"
            fluid
            disabled={loading}
            loading={loading}
            onClick={() => resetPassword()}
          >
            パスワードを変更する
          </Button>
        </div>
      </div>
    </form>
  );
}

export default enhance((props) => <ResetPasswordModal {...props} />);
