import React from 'react';
import { Tab } from 'semantic-ui-react';
import classNames from 'classnames';

import { MYPAGE } from '../../../../constants';
import enhance from './enhance';
import { NotificationList, TitleBar } from '@/components/Common';

// メール設定
import Modal from '@/migration/modal';
import EmailConfig from './EmailConfig';

import { Button } from 'semantic-ui-react';
import * as Styles from './styles';

function Notification(props) {
  const {
    activeIndex,
    isListLoading,
    isLoading,
    isEmailConfigModal,
    setIsEmailConfigModal,
    users: { mydata },
    handleSubmit,
    submitToRead,
    submitToUnread,
    history,
    location,
    updateLoading,
    handleTabChange,
  } = props;

  const panes = [
    {
      activeIndex: MYPAGE.NOTIFICATION_TAB,
      menuItem: '全般',
      // eslint-disable-next-line
      render: () => (
        <Tab.Pane>
          <NotificationList
            updateLoading={updateLoading}
            location={location}
            history={history}
            activeIndex={activeIndex}
            isListLoading={isListLoading}
            isLoading={isLoading}
          />
        </Tab.Pane>
      ),
    },
    {
      activeIndex: MYPAGE.MESSAGE_TAB,
      menuItem: 'メッセージ',
      // eslint-disable-next-line
      render: () => (
        <Tab.Pane>
          <NotificationList
            updateLoading={updateLoading}
            location={location}
            history={history}
            activeIndex={activeIndex}
            isListLoading={isListLoading}
            isLoading={isLoading}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div className="b__email-config">
          <button onClick={() => setIsEmailConfigModal(true)}>
            メール通知設定
          </button>
        </div>

        <div className="b__email-isread-button">
          <Button
            positive
            style={Styles.submitBtn}
            onClick={handleSubmit(submitToRead)}
          >
            既読にする
          </Button>
          <Button
            positive
            style={Styles.submitBtn}
            onClick={handleSubmit(submitToUnread)}
          >
            未読にする
          </Button>
        </div>
        {/* メール設定モーダル */}
        <Modal
          className="mypage__email-config-modal"
          isModal={isEmailConfigModal}
          handleCloseModal={() => setIsEmailConfigModal(false)}
          ModalBody={EmailConfig}
          userId={mydata.id}
        />
      </div>
      <div className="w__mypage-notification b__email-bar">
        <TitleBar title="通知" />
        <div className="annotation">
          ※ 通知の表示期間は通知が届いてから<b>120日間</b>です。
        </div>
        <Tab
          panes={panes}
          defaultActiveIndex={MYPAGE.NOTIFICATION_TAB}
          activeIndex={activeIndex}
          onTabChange={async (e, data) => {
            handleTabChange(data.activeIndex);
          }}
          className={classNames('w__list', {
            loading: isListLoading || isLoading,
          })}
        />
      </div>
    </div>
  );
}

export default enhance((props) => <Notification {...props} />);
