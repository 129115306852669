import axios from 'axios';

import { Auth } from '@/utils';
import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

const resouces = '/users';

/** メール通知設定 */
export const updateUserEmailSetting = async (userId, body) => {
  try {
    const token = await Auth.getIdToken();

    const { data } = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .put(
        `${GO_ENDPOINT}/v1/${SITE_TYPE}${resouces}/${userId}/email/receive/setting`,
        body
      );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserEmailSetting = async (userId) => {
  try {
    const token = await Auth.getIdToken();

    const { data } = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .get(
        `${GO_ENDPOINT}/v1/${SITE_TYPE}${resouces}/${userId}/email/receive/setting`
      );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
