import { makeQueryParam } from '@/apis/utils';

export default function conditionQueryBuilder(condition) {
  const query = [];

  if (condition.id) {
    condition.id.forEach((i) => {
      query.push(makeQueryParam('id', i.toString()));
    });
  }

  // eslint-disable-next-line
  if (condition.hasOwnProperty('bid')) {
    query.push(makeQueryParam('bid', condition.bid ? '1' : '0'));
  }

  if (condition.area) {
    query.push(makeQueryParam('area', condition.area.toString()));
  }

  if (condition.fishKind) {
    condition.fishKind.forEach((fk) => {
      query.push(makeQueryParam('fish_kind', fk));
    });
  }

  if (condition.seller) {
    condition.seller.forEach((s) => {
      query.push(makeQueryParam('seller', s.toString()));
    });
  }

  // eslint-disable-next-line
  if (condition.hasOwnProperty('fixed')) {
    query.push(makeQueryParam('fixed', condition.fixed ? '1' : '0'));
  }

  if (condition.customer) {
    condition.customer.forEach((s) => {
      query.push(makeQueryParam('customer', s.toString()));
    });
  }

  if (condition.bidder) {
    condition.bidder.forEach((s) => {
      query.push(makeQueryParam('bidder', s.toString()));
    });
  }

  if (condition.keyword) {
    condition.keyword = condition.keyword.split(' ');
    condition.keyword.forEach((kw) => {
      query.push(makeQueryParam('keyword', kw));
    });
  }

  if (condition.closed) {
    condition.closed.forEach((c) => {
      query.push(makeQueryParam('closed', c.toString()));
    });
  }

  // eslint-disable-next-line
  if (condition.hasOwnProperty('is_rated')) {
    query.push(makeQueryParam('is_rated', condition.is_rated ? '1' : '0'));
  }

  // eslint-disable-next-line
  if (condition.hasOwnProperty('is_company')) {
    query.push(makeQueryParam('is_company', !!condition.is_company));
  }

  return query;
}
