import * as React from 'react';
import enhance from './enhancer';
import { Button, Image, Icon } from 'semantic-ui-react';
import { Field } from 'redux-form/immutable';
import { FieldTextArea } from '../../../../Form';
import { images } from '@/config';
import { FormLabelIcon } from '@/components/Common';
import Rating from 'react-rating';

function ReviewToSeller(props) {
  const {
    sales: {
      item: { sellerName },
    },
    onSubmit,
    submitComplete,
    handleRate,
    reputation,
    modalProps: { setShowModal },
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {submitComplete ? (
        <div className="w__review-modal-thanks">
          <div className="w__main">
            <p className="w__caption_closed">
              レビューを送信しました。
              <br />
              ご利用ありがとうございました。
            </p>
            <div className="w__form">
              <Image src={images.logo_header} className="logo" />
              <div className="w__button_closed">
                <span
                  role="button"
                  className="reputation_closed"
                  onClick={setShowModal}
                  tabIndex="0"
                >
                  閉じる
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w__review-modal">
          <p className="w__caption">
            {sellerName}さんとの取引はいかがでしたか？
            <br />
            採点してください。
          </p>
          <div className="w__form">
            <div className="reputation_point">
              <span>採点</span>
              <Rating
                placeholderRating={reputation}
                emptySymbol={<Icon name="star" color="grey" />}
                fullSymbol={<Icon name="star" color="yellow" />}
                placeholderSymbol={<Icon name="star" color="yellow" />}
                onClick={(rate) => {
                  handleRate(rate);
                }}
              />
              <FormLabelIcon value="必須" />
            </div>
            <div className="review_input">
              <div className="review_input_caption">
                コメント
                <FormLabelIcon value="任意" optional />
              </div>
              <Field
                type="text"
                name="reviewContent"
                component={FieldTextArea}
                maxLength={201}
              />
            </div>
            <div className="review_count_under">(最大200文字)</div>
            <div className="w__button">
              <Button className="review_submit">レビューする</Button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default enhance((props) => <ReviewToSeller {...props} />);
