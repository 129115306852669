import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { usersAction } from '@/actions';
import { paths } from '@/config';
import store from 'store';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, push }, dispatch);

const enhance = compose(
  setDisplayName('SignUpComplete'),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleOnIsAuth: (props) => async () => {
      const { push } = props;
      push(paths.landing);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { push } = this.props;

      const isOnceSignup = store.get('signupThank');
      if (!isOnceSignup) {
        push(paths.landing);
      }
    },
    componentWillUnmount() {
      store.remove('signupThank');
    },
  })
);

export default enhance;
