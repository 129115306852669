export default function review(values) {
  const errors = {};

  const reviewContent = values.get('reviewContent');

  if (reviewContent && reviewContent.length > 200) {
    errors.reviewContent = '200文字以内で入力してください';
  }

  return errors;
}
