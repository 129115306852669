import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';
import { configureStripe, configureStripeElements } from '@/config';
import { push, replace } from 'connected-react-router';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction, push, replace }, dispatch);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidMount() {
      try {
        await this.props.getMe();
      } catch (error) {
        //
      }
      // DOM Render確定のため
      const stripe = window.Stripe(configureStripe().ApiKey);
      const elements = stripe.elements({
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
          },
        ],
        locale: 'ja',
      });
      const elementStyles = {
        base: {
          color: '#fff',
          fontWeight: 600,
          fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':focus': {
            color: '#424770',
          },

          '::placeholder': {
            color: '#9BACC8',
          },

          ':focus::placeholder': {
            color: '#CFD7DF',
          },
        },
        invalid: {
          color: 'black',
          ':focus': {
            color: '#FA755A',
          },
          '::placeholder': {
            color: '#FFCCA5',
          },
        },
        complete: {
          color: 'black',
        },
      };

      const elementClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid',
      };

      const cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardNumber.mount('#elements-card-number');

      const cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardExpiry.mount('#elements-card-expiry');

      const cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardCvc.mount('#elements-card-cvc');

      configureStripeElements(
        [cardNumber, cardExpiry, cardCvc],
        'elements',
        stripe,
        this.props.postUserCard,
        this.props.users,
        this.props.putUserCards,
        this.props.getMe,
        this.props.replace
      );
    },
  })
);

export default enhance;
