import React from 'react';
import RcPagination, {PaginationProps} from 'rc-pagination';
import RcSelect from 'rc-select';
import classNames from 'classnames';

import "rc-pagination/assets/index.css";
import "./styles/index.scss";


import {en_US, ja_JP} from "./locale"
const DEFAULT_PAGESIZE = 10;

type Props = {
  changePage: (page: number, pageSize: number) => void;
  current: number;
  totalCount: number;
  size?: string;
  locale?: string;
  showSizeChanger?: boolean;
  pageSize?: number;
  changePageSize?: PaginationProps['onShowSizeChange'];
  disabled?: boolean;
  showLessItems?: boolean;
  scrollTop?: boolean;
};

type ChangePageWithScrollTop = (
  props: Pick<Props, 'changePage'>
) => PaginationProps['onChange'];


export const changePageWithScrollTop: ChangePageWithScrollTop = props => (
  page,
  pageSize
) => {
  window.scrollTo(0, 0);
  console.log(123)
  props.changePage(page, pageSize);
};


const Pagination: React.FC<Props> = props => {
  return (
    <div className="gc__pagination">
      <RcPagination
        style={{
          display: props.totalCount ? 'block' : 'none',
        }}
        className={classNames({
          small_size: props.size === 'small',
          disabled: props.disabled,
        })}
        total={props.totalCount}
        current={props.current}
        showLessItems
        pageSize={props.pageSize ? props.pageSize : DEFAULT_PAGESIZE}
        onChange={
          props.scrollTop
            ? changePageWithScrollTop(props)
            : props.changePage
        }
        locale={
          props.locale === 'en_US' ? en_US : ja_JP
        }
        {...(props.showSizeChanger && {selectComponentClass: RcSelect})}
        showSizeChanger={props.showSizeChanger || false}
        onShowSizeChange={props.changePageSize}
      />
    </div>
  );
};

export default Pagination;
