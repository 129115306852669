import formatDate from '@/apis/utils/formatDate';

export default function parseFollower({
  id,
  is_company,
  is_deleted,
  is_enabled,
  company,
  created_at,
  updated_at,
  user_id,
  about,
  display_name,
  avatar_url,
  is_email_refused,
}) {
  return Object.assign(
    {},
    {
      id,
      isCompany: is_company,
      isDeleted: is_deleted,
      isEnabled: is_enabled,
      company: company,
      createdAt: formatDate(created_at),
      updatedAt: formatDate(updated_at),
      userId: user_id,
      about: about,
      displayName: display_name,
      avatarUrl: avatar_url,
      isEmailRefused: is_email_refused,
    }
  );
}
