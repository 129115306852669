import PropTypes from 'prop-types';
import { compose, setDisplayName, lifecycle, setPropTypes } from 'recompose';

const enhance = compose(
  setDisplayName('MatchPathRedirect'),
  setPropTypes({ redirectUrl: PropTypes.string }),
  lifecycle({
    componentWillMount() {
      const { history, redirectUrl } = this.props;
      history.push(redirectUrl);
    },
  })
);

export default enhance;
