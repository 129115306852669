import area from './area';
import closeBidMinDate from './closeBidMinDate';
import closeBidMinHour from './closeBidMinHour';
import ikejimeMethod from './ikejimeMethod';
import ikejimeWater from './ikejimeWater';
import prefecture from './prefecture';
import shippingExpense from './shippingExpense';
import shippingTiming from './shippingTiming';
import leaveReasons from './leaveReason';
import deliveryMethod from './deliveryMethod';
import fishingYear from './fishingYear';
import fishingMonth from './fishingMonth';
import fishingDay from './fishingDay';
import ikejimeTiming from './ikejimeTiming';
import savingMethod from './savingMethod';
import governments from './governments';
import cleaningMethod from './cleaningMethod';
import sorts from './sorts';
import shippingSize from './shippingSize';

// 追加
import size from './size';
import saveMethod from './saveMethod';
import harvestTiming from './harvestTiming';
import cultivationMethod from './cultivationMethod';
import grade from './grade';
import pesticides from './pesticides';
import pesticidesAdditionalInfo from './pesticidesAdditionalInfo';
import genetic from './genetic';
import soil from './soil';
import soilAdditionalInfo from './soilAdditionalInfo';
import jas from './jas';

export default {
  area,
  closeBidMinDate,
  closeBidMinHour,
  ikejimeMethod,
  ikejimeWater,
  prefecture,
  shippingExpense,
  shippingTiming,
  leaveReasons,
  deliveryMethod,
  fishingYear,
  fishingMonth,
  fishingDay,
  ikejimeTiming,
  savingMethod,
  governments,
  cleaningMethod,
  sorts,
  shippingSize,

  size,
  saveMethod,
  harvestTiming,
  cultivationMethod,
  grade,
  pesticides,
  pesticidesAdditionalInfo,
  genetic,
  soil,
  soilAdditionalInfo,
  jas,
};
