import { getSaleId } from '@/utils';

export default function onChangePage(props) {
  return async (current) => {
    const {
      updatePaginationDisabled,
      getSaleCommentsBeforePagination,
      updateCommentsBeforePagesActive,
    } = props;

    updateCommentsBeforePagesActive(current);

    updatePaginationDisabled(true);
    await getSaleCommentsBeforePagination(getSaleId(), {
      limit: 5,
      page: current,
    });
    updatePaginationDisabled(false);
  };
}
