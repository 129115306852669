import findIndex from 'lodash/findIndex';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  compose,
  setDisplayName,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';
import $ from 'jquery';

import { appAction, salesAction, usersAction } from '@/actions';
import { paths } from '@/config';
import { buildFishSearchConditions, indexedDB, notification } from '@/utils';
import { getNews } from '@/apis';
import notify from './Notification';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  users: state.users.toJS(),
  sales: state.sales.toJS(),
  app: state.app.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...appAction, ...usersAction, ...salesAction, push },
    dispatch
  );

const enhance = compose(
  setDisplayName('Header'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('appState', 'appStateUpdater', {
    loading: true,
    error: false,
    success: false,
  }),
  withState('displayNotifications', 'setDisplayNotifications', []),

  withHandlers({
    handleSearchSales: (props) => async () => {
      const { getSalesWithPagination, push, app, form } = props;
      const bidConditionsType = R.pathOr(
        'all',
        ['fishSearchForm', 'values', 'statusCategory'],
        form
      );
      const area = R.pathOr('0', ['fishSearchForm', 'values', 'area'], form);
      const field = buildFishSearchConditions(
        bidConditionsType,
        area,
        app.headerText
      );

      // 検索フォームにキーワードが入力されている場合のみ、検索内容をURLに渡す
      // 検索フォームに入力されていない場合、パスパラメーター無しのURLにする
      if (app.headerText) {
        push(`${paths.fishes.root}/search/?name=${app.headerText}`);
      } else {
        push(`${paths.fishes.root}`);
      }

      const sort = ['-biddable', '-fixed', '-created_at'];
      // スマホで検索を行った時、キーボードを閉じるためfocusを外す
      document.getElementById('searchForm').blur();
      await getSalesWithPagination({ limit: 20, page: 1 }, field, sort);
    },

    /** 通知を表示する */
    showNotification: () => async () => {
      const _display = await notification.getDisplayNews();

      _display.forEach((d) => {
        notify(
          d.body_text,
          'info',
          undefined,
          0,
          true,
          d,
          notification.readNews
        );
      });
    },
  }),

  lifecycle({
    async componentDidMount() {
      const { showNotification, location } = this.props;
      const isAllow = notification.allowNotifyPath(location.pathname);
      try {
        if (window.isAuthed && isAllow) {
          const news = await getNews();
          this.props.setDisplayNotifications(news);
          const db = await indexedDB.open();

          const _notifications = await db.notification.toArray();

          // 通知を保存している場合
          if (_notifications.length > 0) {
            await notification.deleteLocal(news);
            await notification.diffUpdate(news);
            await notification.diffInsert(news);
            await showNotification();
          } else {
            // 初めて通知を取得した場合
            await notification.initialNews(news);

            await showNotification();
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    async componentDidUpdate(prevProps) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        const { location } = this.props;
        const isAllow = notification.allowNotifyPath(location.pathname);

        const notifications = await getNews();
        this.props.setDisplayNotifications(notifications);
        // 画面遷移前に表示していた通知バーと画面遷移後に取得した通知を比較する
        if (
          prevProps.displayNotifications !== this.props.displayNotifications
        ) {
          try {
            const updatedNotifications = [];

            if (window.isAuthed && isAllow) {
              // 期限の過ぎた通知、削除された通知がある場合
              if (
                this.props.displayNotifications.length <
                prevProps.displayNotifications.length
              ) {
                // 表示されている通知バーを全て閉じる
                $('.rc-notification-notice-close-x').trigger('click');

                // 更新後の通知バーを表示
                await notification.deleteLocal(notifications);
                await notification.diffUpdate(notifications);
                await notification.diffInsert(notifications);
                await this.props.showNotification();

                return;
              }

              // 画面遷移後に変更のあった通知のみ取得する
              prevProps.displayNotifications.forEach((_news) => {
                const found = this.props.displayNotifications.find(
                  (f) => f.id === _news.id && f.body_text !== _news.body_text
                );
                if (found) {
                  updatedNotifications.push(found);
                }
              });

              const addedNotifications = this.props.displayNotifications.filter(
                (item) => {
                  return (
                    // 遷移前に表示していた通知バーに無い通知が取得された場合、通知を表示するようにする
                    findIndex(prevProps.displayNotifications, item) === -1
                  );
                }
              );
              if (addedNotifications.length) {
                // 取得した通知を表示する対象の配列にpushする
                addedNotifications.forEach((addedNotification) => {
                  updatedNotifications.push(addedNotification);
                });
              }

              //  更新後の通知メッセージと表示中の通知バーのメッセージが異なるものを取り出す
              const beforeNotifications = [];
              this.props.displayNotifications.forEach((_news) => {
                const found = prevProps.displayNotifications.find(
                  (f) => f.id === _news.id && f.body_text !== _news.body_text
                );
                if (found) {
                  beforeNotifications.push(found);
                }
              });

              // 更新されている通知バーのindexを取得する
              const targetIndex = [];
              beforeNotifications.forEach((_bNotification) => {
                $('.a__notification').each((i, element) => {
                  if (_bNotification.body_text === $(element).text()) {
                    targetIndex.push(i);
                    // 取得したindexと一致する通知バーを閉じる
                    $('.rc-notification-notice-close-x').each(() => {
                      targetIndex.forEach((_target) => {
                        $('.rc-notification-notice-close-x')
                          .eq(_target)
                          .trigger('click');
                      });
                    });
                  }
                });
              });

              // 通知内容の重複を削除
              const _updatedNotifications = updatedNotifications.filter(
                (_notification, index, self) => {
                  return self.indexOf(_notification) === index;
                }
              );

              // 更新後の通知バーを表示
              _updatedNotifications.forEach((_updatedNotification) => {
                notify(
                  _updatedNotification.body_text,
                  'info',
                  true,
                  0,
                  true,
                  _updatedNotification,
                  notification.readNews
                );
              });
            }
          } catch (error) {
            throw new Error(error);
          }
        }
      }
    },
  })
);

export default enhance;
