import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

async function putUserShop(userId, oauth_token) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/users/${userId}/shop`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          oauth_token,
        }),
      }
    );

    if (isHttpStatusOK(response.status)) {
      return response.text();
    }

    const responseJson = await response.json();
    throw new Error(parseFailed(responseJson.errors[0]));
  } catch (error) {
    return error;
  }
}

export default putUserShop;
