import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { UserProfile } from '@/components/Pages';
import enhance from './enhancer';

function UserProfileRoutes() {
  return <Route strict path={paths.users.profile} component={UserProfile} />;
}

export default enhance((props) => <UserProfileRoutes {...props} />);
