/** ユーザーの住所情報が有効か確認する */
export const isValidShippingData = (shippingData) => {
  if (!shippingData) {
    return false;
  }

  if (!Array.isArray(shippingData) || !shippingData.length) {
    return false;
  }

  const postNumbers = shippingData.filter(({ post_number }) => {
    return post_number !== '';
  });

  if (postNumbers.length) {
    return true;
  }
  return false;
};
