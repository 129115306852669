import { connect } from 'react-redux';
import { compose, setDisplayName, withState, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import notify from '@/migration/notification';
import { leave as validate } from '@/validates';
import { leaveSendMail, deleteUser } from '../../../../apis';
import { push } from 'connected-react-router';
import { paths } from '@/config';

import store from 'store';

const mapStateToProps = (state) => ({
  users: state.users.toJS(),
  form: state.form.toJS(),
  router: state.router,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, notify }, dispatch);

const enhance = compose(
  setDisplayName('Leave'),
  reduxForm({
    form: 'leave',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'updateIsLoading', false),
  withState('showModal', 'updateShowModal', false),
  withHandlers({
    openModal: (props) => () => {
      props.updateShowModal(true);
    },
    leaveAccount: (props) => async (immutableValues) => {
      const values = immutableValues.toJS();
      const { updateShowModal, updateIsLoading, users, push } = props;

      try {
        const params = {
          id: users.mydata.id,
          display_name: users.mydata.displayName,
          reason: values.reason,
          content: values.detail,
        };
        updateIsLoading(true);

        await deleteUser(params.id);
        await leaveSendMail(params);

        notify(
          '退会しました。Meat Saleをご利用頂き、誠にありがとうございました。',
          'info',
          undefined,
          5
        );
        store.clearAll();
        window.isAuthed = false;

        push(paths.landing);

        updateShowModal(false);
      } catch (error) {
        const errorMessage = error.response.data.errors[0].message;
        switch (errorMessage) {
          case 'user is treading':
          case '14 days have not passed auction ended':
            notify(
              '誠に申し訳ございません。取引中または落札後2週間は退会できません。',
              'error',
              undefined,
              5
            );
            break;

          default:
            notify(
              '退会処理が完了できませんでした。しばらく時間をおいて再度お試しください。',
              'error',
              undefined,
              5
            );
            break;
        }

        updateShowModal(false);
      }

      updateIsLoading(false);
    },
  })
);

export default enhance;
