import React from 'react';

import enhance from './enhancer';
import { FishTab, TitleBar } from '../../Common';
import FishesPagination from '../../Common/FishesPagination';
import Content from './Content';
import SearchBtn from '../../Layouts/SearchBtn';
import { FishSideNav } from '@/components/Common';
import FishSort from '@/components/Common/FishSort';

/** 商品一覧 業者 */
function Fishes(props) {
  const {
    sales: {
      companyFishes: { data, totalPages, current },
    },
    history,
    isLoading,
    handleChangePage,
    handleToggle,
    handleSearch,
    isOpen,
    location,
    handleSort,
  } = props;

  return (
    <div className="a__fishes-company">
      <div className="b__sidenav-layout">
        <FishSideNav
          handleSearch={handleSearch}
          handleToggle={handleToggle}
          isOpen={isOpen}
          isCompany
          location={location}
        />

        <div className="a__fishes-company__main">
          <TitleBar title="出品商品一覧" />

          <FishTab history={history} isLoading={isLoading} activeTab={1} />

          <FishSort isCompany location={location} handleSort={handleSort} />

          <div className="sales_list flex">
            <Content isLoading={isLoading} data={data} />
            <FishesPagination
              currentPage={current}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
            />
          </div>

          <SearchBtn handleToggle={handleToggle} />
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <Fishes {...props} />);
