export default function parseCard({
  brand,
  country,
  funding,
  last4,
  exp_month,
  exp_year,
}) {
  return Object.assign(
    {},
    {
      brand,
      country,
      funding,
      last4,
      expMonth: exp_month,
      expYear: exp_year,
    }
  );
}
