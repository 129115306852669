import React, { useMemo } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { paths } from '../../../../config';
import enhance from './enhancer';

function FixModal(props) {
  const {
    modalProps: { handleConfirmModal, handleSubmit, placeBids, isBidPopup },
    redListCheck,
    rareSpeciesCheck,
    toggleRedList,
    toggleRareSpecies,
  } = props;

  const submitDisabled = useMemo(() => {
    if (isBidPopup) return false;

    if (!redListCheck || !rareSpeciesCheck) return true;
  }, [redListCheck, rareSpeciesCheck, isBidPopup]);

  return (
    <div id="sale_fix_modal">
      <div className="w__fix_message">
        <p>即決価格に達します。</p>
        <p>このまま落札しますか？</p>
        <p className="caution_text">※「落札する」を押すと決済を行います。</p>
      </div>

      {!isBidPopup && (
        <div className="compliance_wrap">
          <div className="compliance_red_list_wrap">
            <Checkbox
              onClick={toggleRedList}
              className="compliance_checkbox"
              id="fix_modal_red_list_checkbox"
            />
            <div>
              <label
                htmlFor="fix_modal_red_list_checkbox"
                className="compliance_label"
              >
                落札する商品は絶滅危惧種に該当しないですか？
              </label>
              <br />
              <label
                htmlFor="fix_modal_red_list_checkbox"
                className="compliance_label"
              >
                (絶滅危惧種リストは
                <a
                  href="http://www.env.go.jp/press/files/jp/114457.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                )
              </label>
            </div>
          </div>
          <div className="compliance_rare_species_wrap">
            <Checkbox
              onClick={toggleRareSpecies}
              className="compliance_checkbox"
              id="fix_modal_rare_species_checkbox"
            />
            <div>
              <label
                htmlFor="fix_modal_rare_species_checkbox"
                className="compliance_label"
              >
                落札する商品は国内希少種に該当しないですか？
              </label>
              <br />
              <label
                htmlFor="fix_modal_rare_species_checkbox"
                className="compliance_label"
              >
                (国内希少種リストは
                <a
                  href="https://www.env.go.jp/nature/kisho/domestic/list.html#gyorui"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                )
              </label>
            </div>
          </div>
        </div>
      )}

      <div className="sale_bid_modal_button">
        <Button
          negative
          onClick={handleConfirmModal}
          className="negative_button"
        >
          キャンセル
        </Button>
        <Button
          positive
          onClick={handleSubmit(placeBids)}
          content="落札する"
          className="positive_button"
          disabled={submitDisabled}
        />
      </div>
    </div>
  );
}

export default enhance((props) => <FixModal {...props} />);
