import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { Sale } from '@/components/Pages';
import enhance from './enhancer';

function SaleRoutes() {
  return <Route strict path={paths.fishes.saleItem} component={Sale} />;
}

export default enhance((props) => <SaleRoutes {...props} />);
