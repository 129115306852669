import React from 'react';
import { Button } from 'semantic-ui-react';
import { TitleBar } from '@/components/Common';
import { getEnv } from '@/utils';
import enhance from './enhance';

const Admin = () => {
  const { REDIRECT_URL_SIGN_IN_VENDOR } = getEnv();

  return (
    <div>
      <div className="pc__only">
        <TitleBar title="管理画面" />
        <section className="app__bank-account">
          <span className="payments flex">
            <section className="payments_container">
              <div className="sell_fish">
                <div className="payment_wrapper">
                  <div className="already_bank">
                    <p>管理画面 (PCのみ)は下記のボタンからご利用頂けます</p>
                    <div className="already_bank_link">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={REDIRECT_URL_SIGN_IN_VENDOR}
                      >
                        <Button className="payment_button">
                          <span>管理画面を別タブでひらく</span>
                          <span />
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </span>
        </section>
      </div>
      <div className="mobile__header mobile__only">
        <h3>マイページ</h3>
      </div>
    </div>
  );
};

export default enhance((props) => <Admin {...props} />);
