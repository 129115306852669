import { Auth, base, getEnv } from '@/utils';

async function getNotReviewedItem() {
  try {
    const { SITE_TYPE } = getEnv();
    const token = await Auth.getIdToken();
    const response = await base.get(`/v1/${SITE_TYPE}/unreviewed`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = response.data;
    return result;
  } catch (error) {
    throw new Error(error);
  }
}

export default getNotReviewedItem;
