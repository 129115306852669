const pesticidesAdditionalInfo = [
  {
    key: '1',
    value: '1',
    name: 'pesticidesAdditionalInfo1',
    text: '栽培期間中不使用',
  },
  {
    key: '2',
    value: '2',
    name: 'pesticidesAdditionalInfo2',
    text: '全農薬、節減対象農薬のみ',
  },
  {
    key: '3',
    value: '3',
    name: 'pesticidesAdditionalInfo3',
    text: '飛散農薬（有）',
  },
  {
    key: '4',
    value: '4',
    name: 'pesticidesAdditionalInfo3',
    text: '飛散農薬（無）',
  },
  {
    key: '5',
    value: '5',
    name: 'pesticidesAdditionalInfo4',
    text: '残留農薬検査結果ゼロ',
  },
];

export const pesticidesAdditionalInfoNames = pesticidesAdditionalInfo.map(
  ({ name }) => name
);

export default pesticidesAdditionalInfo;
