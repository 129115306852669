import { saleDetailType } from '@/types';
import { getEnv } from '@/utils';
import axios from 'axios';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

export const getSalesDetail = async (id: number) => {
  try {
    const response = await axios.get<saleDetailType>(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/sales/detail/${id}`
    );

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
