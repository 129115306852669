import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import notify from '@/migration/notification';

import { putSingleImage, getSaleImages } from '../../../../../apis/sales';

import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...usersAction,
    },
    dispatch
  );

const enhance = compose(
  setDisplayName('UploadModal'),
  reduxForm({
    form: 'UploadModalForm',
    initialValues: {
      image: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    submit: (props) => async () => {
      const {
        id,
        form: { UploadModalForm },
        setIsLoading,
        handleCloseModal,
        setImages,
      } = props;

      const { image, comment } = UploadModalForm.values;
      const body = {
        image,
        comment,
      };

      try {
        setIsLoading(true);
        await putSingleImage(id, body);
        notify('画像の追加に成功しました。', 'success', undefined, 5);
        const addImages = await getSaleImages(id);
        setImages(addImages);
      } catch (error) {
        notify('画像の追加に失敗しました。', 'error', undefined, 5);
      } finally {
        setIsLoading(false);
        handleCloseModal();
      }
    },
  })
);

export default enhance;
