import { connect } from 'react-redux';
import { compose, setDisplayName, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

const mapStateToProps = (state) => ({ users: state.users.toJS() });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('UserFollowItem'),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleUpdateFollow: (props) => async () => {
      const {
        users: { mydata },
        followUserId,
        postFollow,
      } = props;
      await postFollow(mydata.id, followUserId);
    },
    handleDeleteFollow: (props) => async () => {
      const {
        users: { mydata },
        followUserId,
        deleteFollow,
      } = props;

      await deleteFollow(mydata.id, followUserId);
    },
  })
);

export default enhance;
