import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import enhance from './enhance';
import { NotificationRedirect } from '@/components/Pages';

function MyPageRoutes() {
  return (
    <Route
      exact
      path={paths.notificationRedirect}
      component={NotificationRedirect}
    />
  );
}

export default enhance((props) => <MyPageRoutes {...props} />);
