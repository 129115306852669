import { getSaleId } from '@/utils';
import notify from '@/migration/notification';

export default function submitComment(props) {
  return async (immutableValues) => {
    const values = immutableValues.toJS();
    const {
      updateCommentErrorMessage,
      getSaleCommentsWithPagination,
      postSaleComment,
      sales: {
        comments: { current },
      },
      reset,
      change,
      takeTemporaryComment,
    } = props;

    updateCommentErrorMessage(false);
    if (!values.message) {
      updateCommentErrorMessage(true);
      return;
    }
    try {
      const saleId = getSaleId();

      const result = await postSaleComment(saleId, values.message);
      // eslint-disable-next-line
      if (!result.value.hasOwnProperty('id')) {
        throw new Error(result.value.message);
      }
      reset();
      await getSaleCommentsWithPagination(saleId, {
        limit: 5,
        page: current,
      });
      change('message', '');
      change('applyAddress', false);
      takeTemporaryComment('');
    } catch (error) {
      switch (error.message) {
        case 'contain ban words':
          notify('不適切な表現が含まれています。', 'error', undefined, 5);
          break;

        default:
          notify(
            '書き込みに失敗しました。しばらく時間をおいて再度お試しください。',
            'error',
            undefined,
            5
          );
          break;
      }
    }
  };
}
