import { connect } from 'react-redux';
import {
  compose,
  setDisplayName,
  withHandlers,
  withState,
  lifecycle,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { usersAction } from '@/actions';

import { constants } from '../../../../constants';

const REVIEW_PAGE_PARAMS = {
  PAGE: 'page',
};

const mapStateToProps = (state) => ({ users: state.users.toJS() });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...usersAction }, dispatch);

const enhance = compose(
  setDisplayName('Review'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),
  withState('isLoading', 'updateLoading', false),
  withHandlers({
    // ページ切り替え
    onChangePage: (props) => async (current) => {
      const {
        updateReviewPagesActive,
        updatePaginationDisabled,
        history,
      } = props;

      updatePaginationDisabled(true);
      updateReviewPagesActive(current);

      if (current === constants.PAGINATION.INITIAL) {
        history.push(`/mypage/rerview`);
      } else {
        history.push(`/mypage/rerview?page=${current}`);
      }

      updatePaginationDisabled(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getUserReviewPagination,
        updateReviewPagesActive,
        getMe,
        updateLoading,
      } = this.props;

      try {
        updateLoading(true);
        await getMe();
        //getMeでクエリに込める{id}を取得
        const id = this.props.users.mydata.id;

        // ページ番号を取得する
        const searchParams = new URLSearchParams(window.location.search);
        // ページ番号がない場合は、1
        const page =
          searchParams.get(REVIEW_PAGE_PARAMS.PAGE) ||
          constants.PAGINATION.INITIAL;

        const numberPage = Number(page);

        await getUserReviewPagination({ limit: 5, current: page, id }, [
          '-created_at',
        ]);

        updateReviewPagesActive(numberPage);
      } catch {
        // 0件メッセージを表示するため、何もしない
      } finally {
        updateLoading(false);
      }

      window.setTimeout(() => {
        // ハッシュがある時は、指定の位置まで移動する
        const hash = window.location.hash;
        if (hash) {
          // eslint-disable-next-line
          window.location.href = window.location.href;
        }
      }, 500);
    },

    async componentDidUpdate(prevProps) {
      const {
        location,
        updateLoading,
        getUserReviewPagination,
        updateReviewPagesActive,
        users,
      } = this.props;
      const { location: prevLocation } = prevProps;

      // URLパラメータでページ番号を管理、前回と同じ場合(変化がない場合)は,return
      if (location.search === prevLocation.search) return;

      const searchParams = new URLSearchParams(window.location.search);

      const page =
        searchParams.get(REVIEW_PAGE_PARAMS.PAGE) ||
        constants.PAGINATION.INITIAL;

      const numberPage = Number(page);

      try {
        const id = users.mydata.id;
        updateLoading(true);

        await getUserReviewPagination({ limit: 5, current: page, id }, [
          '-created_at',
        ]);

        updateReviewPagesActive(numberPage);

        window.scrollTo({ left: 0, top: 75, behavior: 'smooth' });
      } catch {
        // 0件メッセージを表示するため、何もしない
      } finally {
        updateLoading(false);
      }
    },
  })
);

export default enhance;
