import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { paths } from '@/config';
import enhance from './enhance';
import { MyProfileHeader } from '@/components/Common';

function NavBar(props) {
  const {
    sales: { ownExhibits },
    users,
  } = props;

  return (
    <nav className="b__nav_bar">
      <MyProfileHeader
        selledCount={ownExhibits.totalCount}
        userName={users.mydata.displayName}
        userThumbnail={users.mydata.thumnailImageUrl}
        className="navProfile"
        averagePoint={users.mydata.averagePoint}
        totalEvaluationCount={users.mydata.totalEvaluationCount}
      />
      <div className="a__newfish-sidemenu">
        <div className="a__newfish-sidemenu__accordion">
          <input type="checkbox" id="sell" className="hidden-checkbox" />
          <label htmlFor="sell" className="navOption title">
            出品
          </label>
          <div className="accordion-content">
            <div className="only_pc">
              <NavLink
                to={paths.mypage.admin}
                onClick={(event) => {
                  if (props.sales.isFetching) {
                    event.preventDefault();
                    event.stopPropagation();
                  }
                }}
              >
                <span>管理画面(PCのみ)</span>
                <Icon name="right chevron" />
              </NavLink>
            </div>
            <NavLink
              to={paths.mypage.all}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>全て</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.exhibit_of_exhibitnow}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>未入札</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.exhibit_of_serinow}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>オークション中</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.exhibit_of_soldout}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>落札済み</span>
              <Icon name="right chevron" />
            </NavLink>
          </div>
        </div>
        <div className="a__newfish-sidemenu__accordion">
          <input type="checkbox" id="bid" className="hidden-checkbox" />
          <label htmlFor="bid" className="navOption title">
            入札
          </label>
          <div className="accordion-content">
            <NavLink
              to={paths.mypage.bid_of_bidnow}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>オークション中</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.bid_of_soldout}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>落札済み</span>
              <Icon name="right chevron" />
            </NavLink>
          </div>
        </div>
        <div className="a__newfish-sidemenu__accordion">
          <input type="checkbox" id="rate" className="hidden-checkbox" />
          <label htmlFor="rate" className="navOption title">
            評価
          </label>
          <div className="accordion-content">
            <NavLink
              to={paths.mypage.review}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>評価済み</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.unrated}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>未評価</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.follow}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>フォロー</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.follower}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>フォロワー</span>
              <Icon name="right chevron" />
            </NavLink>
          </div>
        </div>
        <div className="a__newfish-sidemenu__accordion">
          <input type="checkbox" id="setting" className="hidden-checkbox" />
          <label htmlFor="setting" className="navOption title">
            設定
          </label>
          <div className="accordion-content">
            <NavLink
              to={paths.mypage.edit}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>プロフィール編集</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.bank_account}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>売上げ受取方法</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.credit}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>
                支払い方法変更
                <br />
                (カード登録)
              </span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.contact}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>お問い合わせ</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.leave}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>退会</span>
              <Icon name="right chevron" />
            </NavLink>
            <NavLink
              to={paths.mypage.logout}
              onClick={(event) => {
                if (props.sales.isFetching) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            >
              <span>ログアウト</span>
              <Icon name="right chevron" />
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default enhance((props) => <NavBar {...props} />);
