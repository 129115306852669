import axios from 'axios';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();
const resouces = 'maintenance';

const MAINTENANCE_REDIRECT_KEY = 'x-redirect-url';

export const getMentenance = async () => {
  try {
    const { headers } = await axios.get(
      `${GO_ENDPOINT}/v1/${SITE_TYPE}/${resouces}`
    );

    if (headers?.[MAINTENANCE_REDIRECT_KEY])
      window.location.replace(headers[MAINTENANCE_REDIRECT_KEY]);
  } catch (error) {
    // eslint-disable-next-line
  }
};
