import * as React from 'react';
import { Route } from 'react-router-dom';
import { paths } from '@/config';
import { ConfirmSignUp } from '@/components/Pages';
import enhance from './enhancer';

function ConfirmSignUpRoutes() {
  return (
    <Route
      strict
      path={paths.before.signup_confirm}
      component={ConfirmSignUp}
    />
  );
}

export default enhance((props) => <ConfirmSignUpRoutes {...props} />);
