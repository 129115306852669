import React from 'react';

import { Loader } from '@/components/Common';
import { paths } from '@/config';
import { Link } from 'react-router-dom';
import { useHowToSalesAccountHooks } from '../../hooks';
import './style.scss';

const core = () => {
  const {
    data,
    isLoading,
    handleWebSiteCopy,
    website,
    clipboard,
    isError,
  } = useHowToSalesAccountHooks();

  return (
    <div className="a__introduction_howto_sales_account">
      <div className="w__introduction_howto_sales_account">
        <header>Stripeの登録方法について</header>
        <div className="introduction_howto_sales_account-warning">
          <p>
            正しく項目が登録ができていないことが原因で、Stripeの審査がとおらず、売上金が入金されない事例が多発しています。
            <br />
            必ず、下記の手順と入力項目をご確認の上、登録してください。
          </p>
        </div>
        <div className="introduction_howto_sales_account-warning">
          <p>
            審査が通らず追加資料を求められた場合、
            <Link to={paths.introduction.faq}>こちら</Link>
            のページをご確認ください
          </p>
        </div>
        <div className="explain">
          MeatSale以外でStripeを活用しない場合には、設問に対して次のとおり回答してください。
          <br />
          MeatSale以外でもご利用される場合には、設問に従いご回答ください。
        </div>
        <div className="answer_example">
          <p className="introduction_howto_sales_account--step">STEP1</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_1.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_1.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP2</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_2.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_2.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP3</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_3.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_3.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP4</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_4.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_4.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP5</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_5.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_5.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP6</p>
          <p>
            「ビジネスのWEBサイト」にはユーザーページを下記よりコピーし入力してください。
          </p>
          {window.isAuthed && !isLoading && data && (
            <div className="introduction_howto_sales_account--copy">
              <label>
                <span className="textbox-label">ビジネスのWebサイト</span>
                <input
                  type="text"
                  className="textbox"
                  value={website}
                  onClick={handleWebSiteCopy}
                  readOnly
                />
              </label>
              <p className="description">クリックするとコピーされます。</p>
              {clipboard.copied && <p className="balloon">コピーしました</p>}
            </div>
          )}
          {isLoading && window.isAuthed && (
            <div className="introduction_howto_sales_account--loader">
              <Loader />
            </div>
          )}
          {!window.isAuthed && (
            <p className="introduction_howto_sales_account-warning">
              ユーザーページを取得するために、
              <a href={paths.before.signin}>ログイン</a>してください
            </p>
          )}
          {window.isAuthed && isError && (
            <p className="introduction_howto_sales_account-warning">
              読み込みに失敗しました。ページを再度読み込んでください。
            </p>
          )}
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_6.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_6.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP7</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_7.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_7.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP8</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_8.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_8.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <p className="introduction_howto_sales_account--step">STEP9</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_9.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_9.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          <div className="introduction_howto_sales_account--copy introduction_howto_sales_account__form-Group">
            <div>
              <label>
                <span className="textbox-label">店舗名・サービス名</span>
                <input
                  type="text"
                  className="textbox"
                  value={'MeatSale'}
                  readOnly
                />
              </label>
              <label>
                <input
                  type="text"
                  className="textbox"
                  value={'ミートセール'}
                  readOnly
                />
              </label>
              <label>
                <input
                  type="text"
                  className="textbox"
                  value={'MEATSALE.JP'}
                  readOnly
                />
              </label>
            </div>
            <div>
              <label>
                <span className="textbox-label">短い表記</span>
                <input
                  type="text"
                  className="textbox"
                  value={'MeatSale'}
                  readOnly
                />
              </label>
              <label>
                <input
                  type="text"
                  className="textbox"
                  value={'ミートセール'}
                  readOnly
                />
              </label>
              <label>
                <input
                  type="text"
                  className="textbox"
                  value={'MEATSALE'}
                  readOnly
                />
              </label>
            </div>
            <div>
              <label>
                <span className="textbox-label">店舗名・サービス名</span>
                <input
                  type="text"
                  className="textbox"
                  value={'05053576320'}
                  readOnly
                />
              </label>
            </div>
          </div>

          <p className="introduction_howto_sales_account--step">STEP10</p>
          <a
            href={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_10.png`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://meatsale.jp/assets/stripe-create-account/stripe-create-account_10.png`}
              loading="lazy"
              decoding="async"
            />
          </a>
          {/* <img
            src="https://fishsale.jp/assets/stripe-create-account.png"
            loading="lazy"
            decoding="async"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default core;
