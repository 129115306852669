import React from 'react';
import enhance from './enhancer';

import { Loader } from 'semantic-ui-react';

import './style.scss';

const NotificationRedirect = () => {
  return (
    <div className="notification-redirect">
      <Loader active inline="centered" />
    </div>
  );
};

export default enhance((props) => <NotificationRedirect {...props} />);
