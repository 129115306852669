import { getMe } from '@/apis';
import { paths } from '@/config';
import { useClipboard } from '@/utils/clipboard';
import { useEffect, useState } from 'react';

interface MeProps {
  id: number;
}

export const useHowToSalesAccountHooks = () => {
  const [data, setData] = useState<MeProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const clipboard = useClipboard();

  useEffect(() => {
    (async () => {
      if (!window.isAuthed) return;
      try {
        setIsLoading(true);
        setIsError(false);
        const res = await getMe();
        setData(res);
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const website = `${window.location.origin}${paths.users.profile.replace(
    ':user_id',
    (data?.id ?? 0).toString()
  )}`;

  const handleWebSiteCopy = () => {
    clipboard.copy(website);
  };

  return { data, isLoading, clipboard, handleWebSiteCopy, website, isError };
};
