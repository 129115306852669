import * as React from 'react';

import { MediaPublication } from '@/components/Common';

function Intro() {
  return (
    <>
      <div className="mainvisual">
        <div className="landing-background">
          <div className="mainvisual-inner">
            <div className="content-inner">
              <div className="balloon">
                <span className="bg-balloon">世界初!?</span>
              </div>
              <div className="section-inner">
                <div className="content-width">
                  <div className="sub-txt">
                    <span className="slanting-line more_than_md">\ </span>
                    <span>
                      簡単に
                      <span className="less_than_sm">
                        <br />
                      </span>
                      <span className="slanting-line less_than_sm">\ </span>
                      売ったり買ったりを楽しめる
                    </span>
                    <span className="slanting-line"> /</span>
                  </div>
                  <h2 className="catch-txt">
                    肉オークション
                    <span className="less_than_sm">
                      <br />
                    </span>
                    スタート！！
                  </h2>
                  <div className="mv-merit-list-floatlist">
                    <div className="floatlist-pig">
                      <span className="floatlist-text">安い</span>
                    </div>
                    <div className="floatlist-good">
                      <span className="floatlist-text">簡単</span>
                    </div>
                    <div className="floatlist-heart">
                      <span className="floatlist-text">安心</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MediaPublication />

      <div className="sec-trouble">
        <div className="section-inner">
          <div className="content-width">
            <div className="first-content">
              <div className="sec-ttl">
                こんな
                <div className="bg-circle">
                  <div className="bg-circle-inner">お悩み</div>
                </div>
                <span className="more_than_md">ございませんか？</span>
              </div>
              <div className="less_than_sm">ございませんか？</div>
            </div>

            <div className="sec-content">
              <h3 className="content-ttl">
                専門店、畜産農家から 飲食店やスーパーの方まで
              </h3>
              <div className="trouble-list">
                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://meatsale.jp/assets/trouble_list_img_01.jpg"
                      alt="精肉店のYさん"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">精肉店のYさん</h4>
                    <p className="txt">
                      スーパーなどでお肉を購入される方が多く、せっかくいいお肉を仕入れても、そもそもお客様にご来店いただけないことがよくあります。おすすめのお肉をもっと食べて欲しい。
                    </p>
                  </div>
                </div>

                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://meatsale.jp/assets/trouble_list_img_02.jpg"
                      alt="畜産農家のOさん"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">畜産農家のOさん</h4>
                    <p className="txt">
                      畜産業ってすごくコストがかかるんです。おいしいお肉を届けるために手間をかければかけるだけ、お店に並んだ時の価格も高くなってしまいます。
                      <br />
                      手間暇かけて育てた肉畜の美味しさを低価格で多くの方に届けたいですね。
                    </p>
                  </div>
                </div>

                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://meatsale.jp/assets/trouble_list_img_03.jpg"
                      alt="飲食店を営むIさん"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">飲食店を営むIさん</h4>
                    <p className="txt">
                      どの料理も大切ですが、お肉料理はメインになるのでやっぱり特に手は抜けません。
                      <br />
                      物価の上昇など飲食店には厳しい時期が続いていますが、クオリティを落とすことなく一人でも多くのお客様にご来店いただきたいです。
                    </p>
                  </div>
                </div>

                <div className="trouble-card">
                  <div className="img-box">
                    <img
                      src="https://meatsale.jp/assets/trouble_list_img_04.jpg"
                      alt="お肉好きの主婦Fさん"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="txt-box">
                    <h4 className="ttl">お肉好きの主婦Fさん</h4>
                    <p className="txt">
                      家族揃ってお肉料理が大好き！
                      <br />
                      ただ、食べ盛りの子供がいるので結構食費がかかります…。
                      <br />
                      費用を抑えて、いろいろな種類や部位のお肉をお手軽に買えると嬉しいです。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-inner">
        <div className="ttl-container">
          <div className="content-width-title">
            <div className="ttl-box">
              <div className="ttl-subtxt">
                <div className="content-titile">
                  <span className="slanting-line">\ </span>
                  そんな要望を叶えるのが世界初！？
                  <span className="slanting-line"> /</span>
                </div>
              </div>
              <div className="ttl">
                肉のオークションサイト
                <span className="less_than_sm">
                  <br />
                </span>
                <span className="emphasis-text">”Meat Sale”</span>
                です。
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="content-width-intro">
            <div className="sec-ttl">
              Meat Saleは、肉を
              <span className="under-line">その場で出品</span>して、
              <span className="more_than_md">
                <br />
              </span>
              欲しい時に<span className="under-line">欲しい人に届ける</span>
              <span className="less_than_md">
                <br />
              </span>
              肉専門のオークションサイトです。
            </div>
            <div className="common-box-container">
              <div className="exhibitor-merit-box">
                <div className="header-yellow">出品者のメリット</div>
                <div className="lower">
                  <div className="merit-list">
                    <div className="merit-first">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          手早く
                          <br />
                          簡単に
                          <br />
                          出品可能！
                        </span>
                      </div>
                      <p className="txt">
                        お肉の写真を撮ります。
                        <br />
                        種類や内容量などを入力します。
                        <br />
                        それだけで出品できます。
                      </p>
                    </div>
                    <p className="txt-sm">
                      お肉の写真を撮ります。
                      <br />
                      種類や内容量などを入力します。
                      <br />
                      それだけで出品できます。
                    </p>
                    <div className="merit-second">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          出品は
                          <br />
                          もちろん
                          <br />
                          無料！
                        </span>
                      </div>
                      <p className="txt">
                        会員登録料・
                        <br />
                        月会費・カード手数料は
                        <br />
                        一切かかりません。
                      </p>
                    </div>
                    <p className="txt-sm second">
                      会員登録料・
                      <br />
                      月会費・カード手数料は
                      <br />
                      一切かかりません。
                    </p>
                  </div>
                </div>
              </div>
              <div className="bidder-merit-common-box">
                <div className="header-orange">落札者のメリット</div>
                <div className="lower">
                  <div className="bidder-merit-list">
                    <div className="merit-first">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          新鮮な
                          <br />
                          お肉が
                          <br />
                          いっぱい
                        </span>
                      </div>
                      <p className="txt">
                        出品されるお肉は切りたてなので
                        <br />
                        鮮度バツグンです。
                        <br />
                        また珍しいお肉が出品されることもあります。
                      </p>
                    </div>
                    <p className="txt-sm">
                      出品されるお肉は切りたてなので
                      <br />
                      鮮度バツグンです。
                      <br />
                      また珍しいお肉が出品されることもあります。
                    </p>
                    <div className="merit-second">
                      <div className="catch-circle">
                        <span className="cell-intro">
                          いつでも
                          <br />
                          商品が届く
                        </span>
                      </div>
                      <p className="txt">
                        思うように肉を仕入れることが
                        <br />
                        できなかったお店でも新鮮な
                        <br />
                        お肉をいつでも仕入れることが可能です。
                      </p>
                    </div>
                    <p className="txt-sm second">
                      思うように肉を仕入れることが
                      <br />
                      できなかったお店でも新鮮な
                      <br />
                      お肉をいつでも仕入れることが可能です。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sec-steps">
        <div className="section-inner">
          <div className="content-width">
            <div className="sec-ttl">
              オークション出品は、
              <span className="less_than_sm">
                <br />
              </span>
              かんたん<span className="number">４</span>
              ステップ！
            </div>
            <div className="common-box-container">
              <div className="exhibitor-steps">
                <div className="header-yellow">出品者</div>
                <div className="lower">
                  <div className="steps-card">
                    <div className="steps-list-first">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_exhibitor_list_img_01.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">出品する</h3>
                      <p className="txt">
                        <span>
                          売りたい商品の情報を
                          <br />
                          入力して、かんたん出品！
                        </span>
                      </p>
                    </div>

                    <div className="steps-list">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_exhibitor_list_img_02.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">入札を待つ</h3>
                      <p className="txt">
                        <span>
                          入札があります。
                          <br />
                          終了するまで待ちます。
                        </span>
                      </p>
                    </div>
                    <div className="steps-list">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_exhibitor_list_img_03.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">落札される</h3>
                      <p className="txt">
                        <span>
                          落札されたら、
                          <br />
                          落札者の支払いを待ちます。
                        </span>
                      </p>
                    </div>
                    <div className="steps-list last">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_exhibitor_list_img_04.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">商品を発送する</h3>
                      <p className="txt">
                        <span>商品を梱包して、発送しましょう。</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="common-box-bidder-steps">
                <div className="header-orange">落札者</div>
                <div className="lower">
                  <div className="steps-card">
                    <div className="bidder-steps-list-first">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_bidder_list_img_01.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">商品を探す</h3>
                      <p className="txt">
                        <span>
                          産地やサイズをみて
                          <br />
                          欲しい商品を探します。
                        </span>
                      </p>
                    </div>

                    <div className="bidder-steps-list">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_bidder_list_img_02.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">入札・落札</h3>
                      <p className="txt">
                        <span>
                          希望金額で入札をします。
                          <br />
                          オークション終了時に、
                          <br />
                          あなたの入力した金額が
                          <br />
                          一番高ければ、あなたが
                          <br />
                          落札者となります。
                        </span>
                      </p>
                    </div>

                    <div className="bidder-steps-list">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_bidder_list_img_03.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">代金を支払う</h3>
                      <p className="txt">
                        <span>
                          落札したら、出品者へ代金を
                          <br />
                          お支払いします。
                        </span>
                      </p>
                    </div>

                    <div className="bidder-steps-list last">
                      <div className="img-box">
                        <img
                          src="https://meatsale.jp/assets/steps_bidder_list_img_04.png"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <h3 className="ttl">商品が届く</h3>
                      <p className="txt">
                        <span>新鮮な商品をめしあがれ！！</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;
