import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form/immutable';
import app from './app';
import sales from './sales';
import users from './users';

const rootReducer = combineReducers({
  form,
  app,
  sales,
  users,
});

export default rootReducer;
