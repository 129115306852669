import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Header, Image, Icon } from 'semantic-ui-react';
import { paths } from '@/config';
import enhance from './enhancer';
import Rating from 'react-rating';
import { replaceTofu } from '@/utils/string';

function MyProfileHeader(props) {
  const {
    userName,
    selledCount,
    userThumbnail,
    isShowEditButton,
    averagePoint,
    totalEvaluationCount,
    users: {
      mydata: { isCompany, company, id },
      myFollowCounts,
      myFollowerCounts,
    },
  } = props;

  return (
    <div className="app_profile_header">
      <div className="main">
        <div className="f__userThumbnail">
          <div className="w__circle">
            <Image src={userThumbnail} fluid />
            <Link
              to={paths.mypage.edit}
              className={classNames({ edit: isShowEditButton })}
            >
              編集する
            </Link>
          </div>
        </div>
        <div className="w__description">
          <Header>
            <Link
              to={`${paths.users.profile.replace(`:user_id`, id)}`}
              style={{ paddingLeft: 0, height: 'auto' }}
            >
              {isCompany ? company : replaceTofu(userName)}
            </Link>
          </Header>
          <span className="accessories">
            出品数 {selledCount}
            <span className="new-line">
              <br />
            </span>
            <span className="profile_reputation">
              {averagePoint}
              <Rating
                initialRating={averagePoint}
                emptySymbol={<Icon name="star" color="grey" />}
                fullSymbol={<Icon name="star" color="yellow" />}
                readonly={true}
              />
              <Link to={paths.mypage.review}>
                ({totalEvaluationCount}件の評価)
              </Link>
            </span>
            <span className="new-line">
              <br />
            </span>
            <span className="profile-follow">
              <span className="follow-num">
                <Link to={paths.mypage.follow}>{myFollowCounts}フォロー</Link>
              </span>
              <span className="follower-num">
                <Link to={paths.mypage.follower}>
                  {myFollowerCounts}フォロワー
                </Link>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <MyProfileHeader {...props} />);
