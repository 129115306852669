import axios from 'axios';

import { getEnv } from '@/utils';

const { GO_ENDPOINT, SITE_TYPE } = getEnv();

import { Auth } from '@/utils';

const resouces = 'rates';

export const replyComment = async (rateId, body) => {
  const token = await Auth.getIdToken();

  try {
    const { data } = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .put(`${GO_ENDPOINT}/v1/${SITE_TYPE}/${resouces}/${rateId}/reply`, body);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
