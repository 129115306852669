import * as React from 'react';
import enhance from './enhancer';
import { UserFollowList, TitleBar } from '@/components/Common';
import { Loader } from 'semantic-ui-react';
import Pagination from '@/migration/pagination';

function UserFollow(props) {
  const {
    isLoading,
    users: {
      follow: { pages, current, totalCount },
      myFollowId,
    },
    onChangePage,
    isPaginationDisabled,
  } = props;

  return (
    <React.Fragment>
      <TitleBar title="フォロー 一覧" />
      {pages.active.length === 0 || isLoading ? (
        <div className="user-follow-loader">
          <Loader active={isLoading} inline="centered" />
          {!isLoading ? (
            <span>まだフォローしているユーザーはいません</span>
          ) : null}
        </div>
      ) : (
        <div className="user-follow">
          <UserFollowList data={pages.active} followUsers={myFollowId} />
          <Pagination
            totalCount={totalCount}
            current={current}
            pageSize={5}
            changePage={onChangePage}
            disabled={isPaginationDisabled}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default enhance((props) => <UserFollow {...props} />);
