import * as React from 'react';
import { Image } from 'semantic-ui-react';

function SliderArrowButton(props) {
  const { className, onClick, image } = props;
  return (
    <div className="a__slider-arrow-button">
      <div className={className} onClick={onClick} role="button" tabIndex={0}>
        <Image src={image} />
      </div>
    </div>
  );
}

export default SliderArrowButton;
