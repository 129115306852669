import { StripeFaqTemplate } from '@/modules/static-page';
import React from 'react';

const StripeFaq = () => {
  return (
    <>
      <StripeFaqTemplate />
    </>
  );
};

export default StripeFaq;
