import React from 'react';
import { useDropzone } from 'react-dropzone';
import enhance from './enhance';

const Dropzone = (props) => {
  const { drop } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: drop,
  });

  return (
    <div className="a__upload-modal__dropzone">
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div className="a__upload-modal__dragzone">
          <p className="a__upload-modal__paragraph">
            ドラッグ＆ドロップ
            <br />
            またはクリックで画像を追加
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default enhance((props) => <Dropzone {...props} />);
