import * as React from 'react';
import { FormLabelIcon } from '@/components/Common';
import base64 from 'base64-arraybuffer';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { Image } from 'semantic-ui-react';
import { getEnv, Auth } from '@/utils';

const { UPLOADER_ENDPOINT } = getEnv();

function FieldDropzone(props) {
  const { meta, users } = props;
  return (
    <>
      <div className="field_wrapper_image_group">
        <label className="flex">
          出品画像
          <FormLabelIcon value="必須" />
        </label>
        <Dropzone
          className={classNames({
            dropzone: true,
            flex: true,
            field_wrapper: true,
            display_hidden: !users.mydata.isSeller,
          })}
          accept="image/jpeg, image/png"
          onDrop={async (acceptFiles) => {
            const fileToUpload = acceptFiles[0];
            const reader = new FileReader();
            const session = await Auth.getIdToken();

            reader.onload = () => {
              const img = base64.encode(reader.result);
              const url = `${UPLOADER_ENDPOINT}/sale`;

              props.uploadViewSaleImage(fileToUpload.preview);
              props.uploadIsImageLoading(true);

              fetch(url, {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${session}`,
                },
                body: JSON.stringify({
                  mime_type: fileToUpload.type,
                  content: img,
                }),
              }).then(async (res) => {
                const resJson = await res.json();
                const imageUrl = resJson.url;
                props.uploadPreSaleImage(imageUrl);
                props.uploadIsImageLoading(false);
                props.change('imageUrl', imageUrl);
              });
            };

            reader.readAsArrayBuffer(fileToUpload);
          }}
        >
          {props.viewSaleImage ? (
            <Image src={props.viewSaleImage} size="medium" className="sell" />
          ) : (
            <>
              <span className="dropzone_pc">
                ドラッグ&ドロップまたは
                <br />
                クリックして画像をアップロード
                <br />
                <span style={{ fontSize: '13px' }}>
                  (画像を正方形に調整してアップロード
                  <br />
                  すると、きれいに表示されます)
                </span>
              </span>
              <span className="dropzone_mobile">
                <span>画像をアップロード</span>
                <br />
                <br />
                <span style={{ fontSize: '13px' }}>
                  (画像を正方形に調整してアップロード
                  <br />
                  すると、きれいに表示されます)
                </span>
              </span>
            </>
          )}
        </Dropzone>
      </div>
      {meta.touched && meta.error && (
        <span className="redux_form error">{meta.error}</span>
      )}
    </>
  );
}

export default FieldDropzone;
